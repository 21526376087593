import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import CreateWebsiteRouteLevelComponent from "./createWebsiteLevels/CreateWebsiteRouteLevel";
import CreateWebsiteDeceasedInfoLevelComponent from "./createWebsiteLevels/CreateWebsiteDeceasedInfoLevel";
import CreateWebsiteStoryInfoLevelComponent from "./createWebsiteLevels/CreateWebsiteStoryInfoLevel";
import { createWebsiteLevels } from "../../../pages/dashboard/createMemotialWebsite/CreateMemorialWebsitePage";
import CreateWebsiteHomeGalleryLevelComponent from "./createWebsiteLevels/CreateWebsiteHomeGalleryLevel";
import CreateWebsiteSubscriptionLevelComponent from "./createWebsiteLevels/CreateWebsitePackageLevel";
import CreateWebsiteDeceasedGalleryLevelComponent from "./createWebsiteLevels/CreateWebsiteDeceasedGalleryLevel";
import CreateWebsiteGraveInfoLevelComponent from "./createWebsiteLevels/CreateWebsiteGraveInfoLevel";
import CreateWebsitePlaylistLevelComponent from "./createWebsiteLevels/CreateWebsitePlaylistLevel";
import { Link } from "react-router-dom";

export const CreateMemorialWebsiteProcessFormComponent = ({
  loggedInUser,
  createWebsiteLevel,
  websiteInfo,
  handleInputChange,
  handleSubmit,
  errorMessage,
  handleSetGraveLocation,
  playlistSongInfo,
  ...props
}) => {
  const ref = useRef(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetHeight);
  }, []);

  return (
    <div className="form_main_box" dir="rtl" ref={ref}>
      <Link to={"/dashboard"} className="backtodahboard">
        Back to dashboard <i className="fa-solid fa-left"></i>
      </Link>
      <form id="profile_page_form" className="form_profile_box">
        <a href="#" className="form_main_logo">
          <img src="./assets/images/logo-right.svg" alt="" />
        </a>
        {createWebsiteLevel === createWebsiteLevels.route && (
          <CreateWebsiteRouteLevelComponent
            handleInputChange={handleInputChange} websiteInfo={websiteInfo}
          />
        )}
        {createWebsiteLevel === createWebsiteLevels.deceasedInfo && (
          <CreateWebsiteDeceasedInfoLevelComponent
            handleInputChange={handleInputChange}
            deceasedInfo={websiteInfo.deceasedInfo}
          />
        )}
        {createWebsiteLevel === createWebsiteLevels.storyInfo && (
          <CreateWebsiteStoryInfoLevelComponent
            handleInputChange={handleInputChange}
            deceasedStory={websiteInfo.deceasedInfo.deceasedStory}
          />
        )}
        {createWebsiteLevel === createWebsiteLevels.homePageGallery && (
          <CreateWebsiteHomeGalleryLevelComponent
            handleInputChange={handleInputChange}
          />
        )}
        {createWebsiteLevel === createWebsiteLevels.websiteSubscription && (
          <CreateWebsiteSubscriptionLevelComponent
            handleInputChange={handleInputChange}
          />
        )}
        {createWebsiteLevel === createWebsiteLevels.deceasedGallery && (
          <CreateWebsiteDeceasedGalleryLevelComponent
            handleInputChange={handleInputChange}
          />
        )}
        {createWebsiteLevel === createWebsiteLevels.graveInfo && (
          <CreateWebsiteGraveInfoLevelComponent
            handleInputChange={handleInputChange}
            burialLocation={websiteInfo.deceasedInfo.burialLocation}
            graveImage={websiteInfo.deceasedInfo.graveImage}
            handleSetGraveLocation={handleSetGraveLocation}
          />
        )}
        {createWebsiteLevel === createWebsiteLevels.playlistSongs && (
          <CreateWebsitePlaylistLevelComponent
            handleInputChange={handleInputChange}
            playlistSongInfo={playlistSongInfo}
          />
        )}

        <p id="error_form">{errorMessage}</p>
        <div className="create-website-form-button">
          <button type="button" id="nextbtn" onClick={handleSubmit}>
            שמור והמשך
          </button>
          {/* <button type="submit" id="submitbtn" style="display: none;">שלח</button> */}
        </div>
      </form>
    </div>
  );
};

export default CreateMemorialWebsiteProcessFormComponent;
