import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import websiteActions from "../../store/actions/website.action";
import DashboardWebsiteListConponent from "../../component/dashboard/dashboardPage/DashboardWebsiteList";
import DashboardUpdatedScreenComponent from "../../component/dashboard/dashboardPage/DashboardUpdatedScreen";
import { websiteErrorEnum } from "../../enums/errorsEnums/websiteErrorEnum";
import { ALERT_ICON, alertService } from "../../services/general/alert.service";
import LoaderComponent from "../../component/LoaderComponent";

export const DashboardPage = ({ loggedInUser, websiteReducer, websiteActions, setShowHeader, setShowFooter, ...props }) => {
  const navigate = useNavigate();
  const [websiteList, setWebsiteList] = useState([]);
  const [sumWebsitesUpdates, setSumWebsitesUpdates] = useState(0);
  const [selectedWebsiteUpdates, setSelectedWebsiteUpdates] = useState();
  const [actionType, setActionType] = useState();

  useEffect(() => {
    if (!loggedInUser) {
      navigate("/");
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (loggedInUser) {
      websiteActions.getManagerWebsiteList(loggedInUser.id);
      setActionType("getManagerWebsiteList");
    }

    setShowFooter(false);
    setShowHeader(false);

    return () => {
      setShowFooter(true);
      setShowHeader(true);
    };
  }, []);

  useEffect(() => {
    setWebsiteList(websiteReducer.websiteListInfo);
  }, [websiteReducer.websiteListInfo]);

  useEffect(() => {
    if (websiteList) {
      let sumOfWaitingListSharedMemorials = websiteList.reduce((sum, record) => {
        return sum + (record.waitingListSharedMemrials ? record.waitingListSharedMemrials.length : 0);
      }, 0);
      setSumWebsitesUpdates(sumOfWaitingListSharedMemorials);

      if (selectedWebsiteUpdates) {
        const website = websiteList.find((website) => website.id === selectedWebsiteUpdates.websiteId);
        if (website) {
          showWebsiteUpdatesClicked(website.id, website.waitingListSharedMemrials);
        }
      }
      // getWebsitesStorageData();
    }
  }, [websiteList]);

  useEffect(() => {
    if (!websiteReducer.stateInfo.isLoading && websiteReducer.stateInfo.isSuccess) {
      handleRegisterWebsiteSubscriptionSeccess();
      setActionType(null);
    }

    if (!websiteReducer.stateInfo.isLoading && websiteReducer.stateInfo.isError) {
      handleActionFailed(websiteReducer.stateInfo.error);
    }
  }, [websiteReducer.stateInfo]);

  const handleRegisterWebsiteSubscriptionSeccess = () => {
    if (websiteReducer.websiteInfo?.websiteLicensePayment && websiteReducer.websiteInfo?.websiteLicensePayment.url) {
      window.location.href = websiteReducer.websiteInfo.websiteLicensePayment.url;
    }
  };

  const handleActionFailed = (error) => {
    switch (error) {
      case websiteErrorEnum.DiscountCouponNotFound:
        alertService.showMessage(ALERT_ICON.error, "הקופון שהוזן לא נמצא");
        break;
      case websiteErrorEnum.ExpiredDiscountCoupon:
        alertService.showMessage(ALERT_ICON.error, "קופון פג תוקף");
        break;
      case websiteErrorEnum.DismatchPlanCoupon:
        alertService.showMessage(ALERT_ICON.error, "קוד קופן לא תואם לסוג החבילה שנבחרה");
        break;
      default:
        break;
    }
  };

  const handleAddMemorialWebsiteClicked = (event) => {
    event.preventDefault();
    navigate("/dashboard/create");
  };

  const showWebsiteUpdatesClicked = (websiteId, sharedMemorials) => {
    if (sharedMemorials && sharedMemorials.length > 0) {
      const websiteUpdates = {
        websiteId,
        sharedMemorials,
      };
      setSelectedWebsiteUpdates(websiteUpdates);
    } else {
      setSelectedWebsiteUpdates(null);
    }
  };

  const resetSelectedWebsiteUpdates = () => {
    setSelectedWebsiteUpdates(null);
  };

  const handleShowWebsiteClicked = (website) => {
    websiteActions.setWebsiteInfo(website);
  };

  const getDashboardElement = () => {
    return (
      <section className="dashboard_main_create_website_main_container" dir="rtl">
        <a className="logout_btn" href="#">
          <i className="fa-solid fa-power-off"></i>
        </a>
        <div className="container">
          <div className="dashboard_main_create_website_main_box">
            <div className="dashboard_main_create_website_content_box">
              <div className="dashboard_main_create__content_box">
                <a href="#" className="dashboard_main_logo">
                  <img src="./assets/images/logo.png" alt="" />
                </a>
                <div className="dashboard_main_create_box_content_box">
                  <h2 className="dashboard_main_create__content_box_heading">יצירת אתר הנצחה</h2>
                  <button onClick={handleAddMemorialWebsiteClicked} className="create_a_website_cta_btn">
                    צור אתר חדש <i className="fa-solid fa-plus"></i>
                  </button>
                  <h3 className="webiste_card_heading">האתרים שלי</h3>
                  <DashboardWebsiteListConponent websiteList={websiteList} showWebsiteUpdatesClicked={showWebsiteUpdatesClicked} handleShowWebsiteClicked={handleShowWebsiteClicked} />
                </div>
              </div>
              <div className="dashboard_main_create_heading_content_box">
                {!selectedWebsiteUpdates && (
                  <div className="dashboard_main_create_heading__box">
                    <h2 className="dashboard_main_create_heading_content_box_heading">
                      ברוכים הבאים לזיכרונות חיים<span></span>
                    </h2>
                    <div className="dash_board_cta_btn_box">
                      <a href="#">
                        יש לך <span className="updates_status">{sumWebsitesUpdates}</span> עידכונים בכל האתרים
                      </a>
                      <br />
                      {/* <a href="#">Things to do</a> */}
                    </div>
                  </div>
                )}
                {selectedWebsiteUpdates && <DashboardUpdatedScreenComponent selectedWebsiteUpdates={selectedWebsiteUpdates} resetSelectedWebsiteUpdates={resetSelectedWebsiteUpdates} />}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const isAppLoading = () => {
    return actionType === "getManagerWebsiteList" && websiteReducer.stateInfo.isLoading;
  };

  return isAppLoading() ? <LoaderComponent /> : getDashboardElement();
};

const mapStateToProps = (state, ownProps) => ({
  websiteReducer: state.websiteReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    websiteActions: bindActionCreators(websiteActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
