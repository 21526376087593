import React, { useEffect, useState } from "react";

export const CreateWebsitePlaylistLevelComponent = ({
  handleInputChange,
  playlistSongInfo,
  ...props
}) => {

  return (
    <div className="form_step_box">

      <div className="form_input_main_box">
        <div className="form_input_box">
          <label htmlFor="author">מחבר</label>
          <input
            type="text"
            name="author"
            placeholder="מחבר"
            id="author"
            required
            value={playlistSongInfo?.author}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="form_input_main_box">
        <div className="form_input_box">
          <label htmlFor="name">שם השיר</label>
          <input
            type="text"
            name="name"
            placeholder="שם השיר"
            id="name"
            required
            value={playlistSongInfo?.name}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="form_input_main_box">
        <div className="form_input_box">
          <label htmlFor="youtubeUrl">קישור יוטיוב</label>
          <input
            type="text"
            name="youtubeUrl"
            placeholder="קישור יוטיוב"
            id="youtubeUrl"
            required
            value={playlistSongInfo?.youtubeUrl}
            onChange={handleInputChange}
          />
        </div>
      </div>

    </div>
  );
};

export default CreateWebsitePlaylistLevelComponent;
