import { Dialog } from "@mui/material";
import popuImage from "../../assets/Images/popup/activePopup.png";

export const SalesPopupDialogComponent = ({ open, handleClose, ...props }) => {
  const closeDialog = () => {
    handleClose();
  };

  return (
    <Dialog open={open} onClose={closeDialog}>
      <div className="popup-container">
        <img src={popuImage} alt="דיאלוג מבצע" />
        <span className="close-button" onClick={closeDialog}><i className="fa-solid fa-rectangle-xmark"></i></span>
      </div>
    </Dialog>
  );
};

export default SalesPopupDialogComponent;
