export const websiteActionTypes = {
  RESET_WEBSITE_INFO: "RESET_WEBSITE_INFO",
  SET_WEBSITE_INFO: "SET_WEBSITE_INFO",

  START_REGISTER_WEBSITE: "START_REGISTER_WEBSITE",
  REGISTER_WEBSITE_SUCCESS: "REGISTER_WEBSITE_SUCCESS",
  REGISTER_WEBSITE_FAILURE: "REGISTER_WEBSITE_FAILURE",

  START_REGISTER_WEBSITE_SUBSCRIPTION: "START_REGISTER_WEBSITE_SUBSCRIPTION",
  REGISTER_WEBSITE_SUBSCRIPTION_SUCCESS: "REGISTER_WEBSITE_SUBSCRIPTION_SUCCESS",
  REGISTER_WEBSITE_SUBSCRIPTION_FAILURE: "REGISTER_WEBSITE_SUBSCRIPTION_FAILURE",

  START_GET_WEBSITE: "START_GET_WEBSITE",
  GET_WEBSITE_SUCCESS: "GET_WEBSITE_SUCCESS",
  GET_WEBSITE_FAILURE: "GET_WEBSITE_FAILURE",

  START_GET_WEBSITE_BY_ROUTE: "START_GET_WEBSITE_BY_ROUTE",
  GET_WEBSITE_BY_ROUTE_SUCCESS: "GET_WEBSITE_BY_ROUTE_SUCCESS",
  GET_WEBSITE_BY_ROUTE_FAILURE: "GET_WEBSITE_BY_ROUTE_FAILURE",

  START_GET_MANAGER_WEBSITE_LIST: "START_GET_MANAGER_WEBSITE_LIST",
  GET_MANAGER_WEBSITE_LIST_SUCCESS: "GET_MANAGER_WEBSITE_LIST_SUCCESS",
  GET_MANAGER_WEBSITE_LIST_FAILURE: "GET_MANAGER_WEBSITE_LIST_FAILURE",

  START_GET_WEBSITE_STORAGE: "START_GET_WEBSITE_STORAGE",
  GET_WEBSITE_STORAGE_SUCCESS: "GET_WEBSITE_STORAGE_SUCCESS",
  GET_WEBSITE_STORAGE_FAILURE: "GET_WEBSITE_STORAGE_FAILURE",

  // ******************* Deceased Info  *******************
  START_ADD_OR_UPDATE_DECEASED_INFO: "START_ADD_OR_UPDATE_DECEASED_INFO",
  ADD_OR_UPDATE_DECEASED_INFO_SUCCESS: "ADD_OR_UPDATE_DECEASED_INFO_SUCCESS",
  ADD_OR_UPDATE_DECEASED_INFO_FAILURE: "ADD_OR_UPDATE_DECEASED_INFO_FAILURE",

  START_ADD_DECEASED_FILE: "START_ADD_DECEASED_FILE",
  ADD_DECEASED_FILE_SUCCESS: "ADD_DECEASED_FILE_SUCCESS",
  ADD_DECEASED_FILE_FAILURE: "ADD_DECEASED_FILE_FAILURE",

  START_ADD_DECEASED_FILES: "START_ADD_DECEASED_FILES",
  ADD_DECEASED_FILES_SUCCESS: "ADD_DECEASED_FILES_SUCCESS",
  ADD_DECEASED_FILES_FAILURE: "ADD_DECEASED_FILES_FAILURE",

  START_ADD_DECEASED_STORY: "START_ADD_DECEASED_STORY",
  ADD_DECEASED_STORY_SUCCESS: "ADD_DECEASED_STORY_SUCCESS",
  ADD_DECEASED_STORY_FAILURE: "ADD_DECEASED_STORY_FAILURE",

  START_ADD_DECEASED_BURIAL_LOCATION: "START_ADD_DECEASED_BURIAL_LOCATION",
  ADD_DECEASED_BURIAL_LOCATION_SUCCESS: "ADD_DECEASED_BURIAL_LOCATION_SUCCESS",
  ADD_DECEASED_BURIAL_LOCATION_FAILURE: "ADD_DECEASED_BURIAL_LOCATION_FAILURE",

  START_GET_DECEASED_GALLERY_FILES: "START_GET_DECEASED_GALLERY_FILES",
  GET_DECEASED_GALLERY_FILES_SUCCESS: "GET_DECEASED_GALLERY_FILES_SUCCESS",
  GET_DECEASED_GALLERY_FILES_FAILURE: "GET_DECEASED_GALLERY_FILES_FAILURE",
  // ******************* *******************  *******************

  START_REGISTER_WEBSITE_PAYMENT: "START_REGISTER_WEBSITE_PAYMENT",
  REGISTER_WEBSITE_PAYMENT_SUCCESS: "REGISTER_WEBSITE_PAYMENT_SUCCESS",
  REGISTER_WEBSITE_PAYMENT_FAILURE: "REGISTER_WEBSITE_PAYMENT_FAILURE",

  // ******************* Memorial Candle  *******************

  START_GET_MEMORIAL_CANDLE: "START_GET_MEMORIAL_CANDLE",
  GET_MEMORIAL_CANDLE_SUCCESS: "GET_MEMORIAL_CANDLE_SUCCESS",
  GET_MEMORIAL_CANDLE_FAILURE: "GET_MEMORIAL_CANDLE_FAILURE",

  START_ADD_MEMORIAL_CANDLE: "START_ADD_MEMORIAL_CANDLE",
  ADD_MEMORIAL_CANDLE_SUCCESS: "ADD_MEMORIAL_CANDLE_SUCCESS",
  ADD_MEMORIAL_CANDLE_FAILURE: "ADD_MEMORIAL_CANDLE_FAILURE",

  // ******************* *******************  *******************

  // ******************* Shared Memorial  *******************

  START_GET_SHARED_MEMORIALS: "START_GET_SHARED_MEMORIALS",
  GET_SHARED_MEMORIALS_SUCCESS: "GET_SHARED_MEMORIALS_SUCCESS",
  GET_SHARED_MEMORIALS_FAILURE: "GET_SHARED_MEMORIALS_FAILURE",

  START_ADD_SHARED_MEMORIAL: "START_ADD_SHARED_MEMORIAL",
  ADD_SHARED_MEMORIAL_SUCCESS: "ADD_SHARED_MEMORIAL_SUCCESS",
  ADD_SHARED_MEMORIAL_FAILURE: "ADD_SHARED_MEMORIAL_FAILURE",

  START_APPROVE_SHARED_MEMORIAL: "START_APPROVE_SHARED_MEMORIAL",
  APPROVE_SHARED_MEMORIAL_SUCCESS: "APPROVE_SHARED_MEMORIAL_SUCCESS",
  APPROVE_SHARED_MEMORIAL_FAILURE: "APPROVE_SHARED_MEMORIAL_FAILURE",

  // ******************* *******************  *******************

  // ******************* Playlist  *******************
  START_ADD_PLAYLIST_SONG: "START_ADD_PLAYLIST_SONG",
  ADD_PLAYLIST_SONG_SUCCESS: "ADD_PLAYLIST_SONG_SUCCESS",
  ADD_PLAYLIST_SONG_FAILURE: "ADD_PLAYLIST_SONG_FAILURE",

  START_DELETE_PLAYLIST_SONG: "START_DELETE_PLAYLIST_SONG",
  DELETE_PLAYLIST_SONG_SUCCESS: "DELETE_PLAYLIST_SONG_SUCCESS",
  DELETE_PLAYLIST_SONG_FAILURE: "DELETE_PLAYLIST_SONG_FAILURE",

  START_GET_PLAYLIST_SONGS: "START_GET_PLAYLIST_SONGS",
  GET_PLAYLIST_SONGS_SUCCESS: "GET_PLAYLIST_SONGS_SUCCESS",
  GET_PLAYLIST_SONGS_FAILURE: "GET_PLAYLIST_SONGS_FAILURE",
  // ******************* *******************  *******************

  // ******************* Prayer  *******************
  START_GET_DECEASED_PRAYER: "START_GET_DECEASED_PRAYER",
  GET_DECEASED_PRAYER_SUCCESS: "GET_DECEASED_PRAYER_SUCCESS",
  GET_DECEASED_PRAYER_FAILURE: "GET_DECEASED_PRAYER_FAILURE",
  // ******************* *******************  *******************

  // ******************* Deceased Files  *******************
  START_DELETE_DECEASED_FILE: "START_DELETE_DECEASED_FILE",
  DELETE_DECEASED_FILE_SUCCESS: "DELETE_DECEASED_FILE_SUCCESS",
  DELETE_DECEASED_FILE_FAILURE: "DELETE_DECEASED_FILE_FAILURE",
  // ******************* *******************  *******************

  START_UPDATE_WEBSITE_STORAGE_SIZE: "START_UPDATE_WEBSITE_STORAGE_SIZE",
  UPDATE_WEBSITE_STORAGE_SIZE_SUCCESS: "UPDATE_WEBSITE_STORAGE_SIZE_SUCCESS",
  UPDATE_WEBSITE_STORAGE_SIZE_FAILURE: "UPDATE_WEBSITE_STORAGE_SIZE_FAILURE",
};
