import React, { useEffect, useState } from "react";
import utilService from "../../../../services/general/util.service";

export const CreateWebsiteDeceasedInfoLevelComponent = ({ handleInputChange, deceasedInfo, ...props }) => {
  return (
    <div className="form_step_box">
      <h3>פרטי הנפטר</h3>
      <div className="form_input_main_box">
        <div className="form_input_box">
          <label htmlFor="name">שם פרטי</label>
          <input type="text" name="firstName" placeholder="שם פרטי" id="name" required value={deceasedInfo?.firstName} onChange={handleInputChange} />
        </div>
      </div>
      <div className="form_input_main_box">
        <div className="form_input_box">
          <label htmlFor="name">שם משפחה</label>
          <input type="text" name="lastName" placeholder="שם משפחה" id="name" required value={deceasedInfo?.lastName} onChange={handleInputChange} />
        </div>
      </div>
      <div className="form_input_main_box">
        <div className="form_input_box">
          <label htmlFor="name">תעודת זהות</label>
          <input type="text" name="deceasedId" placeholder="תעודת זהות" id="name" required value={deceasedInfo?.deceasedId} onChange={handleInputChange} />
        </div>
      </div>
      <div className="form_input_main_box">
        <div className="form_input_box">
          <label htmlFor="yearPicker1">תאריך לידה</label>
          <input type="date" className="yearInput" name="birth" id="yearPicker1" required value={utilService.formatDateForInput(deceasedInfo?.birth)} onChange={handleInputChange} />
        </div>
      </div>
      <div className="form_input_main_box">
        <div className="form_input_box">
          <label htmlFor="yearPicker2">תאריך פטירה</label>
          <input type="date" className="yearInput" name="deathDate" id="yearPicker2" required value={utilService.formatDateForInput(deceasedInfo?.deathDate)} onChange={handleInputChange} />
        </div>
      </div>
      <div className="form_input_main_box">
        <div className="form_input_box">
          <label htmlFor="gender">מגדר</label>
          <select name="gender" id="gender" required value={deceasedInfo?.gender} onChange={handleInputChange}>
            <option value="">בחר מגדר</option>
            <option value="male">זכר</option>
            <option value="female">נקבה</option>
          </select>
        </div>
      </div>
      <div className="form_input_main_box">
        <div className="form_input_box">
          <label htmlFor="main_image_banner">תמונת פרופיל</label>
          <input type="file" name="mainImage" id="main_image_banner" required onChange={handleInputChange} />
        </div>
      </div>
    </div>
  );
};

export default CreateWebsiteDeceasedInfoLevelComponent;
