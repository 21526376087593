export const userActionTypes = {
    START_LOGIN : 'START_LOGIN',
    LOGIN_SUCCESS : 'LOGIN_SUCCESS',
    LOGIN_FAILURE : 'LOGIN_FAILURE',
    
    START_REGISTER_USER : 'START_REGISTER_USER',
    REGISTER_USER_SUCCESS : 'REGISTER_USER_SUCCESS',
    REGISTER_USER_FAILURE : 'REGISTER_USER_FAILURE',

    LOGOUT : 'LOGOUT',
}