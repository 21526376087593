import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import websiteActions from "../../../store/actions/website.action";
import { ALERT_ICON, alertService } from "../../../services/general/alert.service";

const defaultSharedMemorial = {
  fullName: "",
  relationship: "",
  content: "",
  memorialDate: "",
  file: null,
  type: null,
  postUrl: "",
};

export const AddSharedMemorialDialogComponent = ({ websiteActions, websiteReducer, open, handleClose, ...props }) => {
  const [actionType, setActionType] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [sharedMemorial, setSharedMemorial] = useState(defaultSharedMemorial);

  useEffect(() => {
    return () => {
      setActionType(null);
      setErrorMessage(null);
    };
  }, []);

  useEffect(() => {
    if (!websiteReducer?.stateInfo?.isLoading && websiteReducer?.stateInfo?.isSuccess) {
      if (actionType === "addSharedMemorial") {
        alertService.showMessage(ALERT_ICON.success, "זיכרון משותף נוסף בהצלחה ונשלח לאישור מנהל האתר");
        closeDialog();
      }
    }

    if (!websiteReducer?.stateInfo?.isLoading && websiteReducer?.stateInfo?.isError) {
      if (actionType === "addSharedMemorial") {
        setErrorMessage("שגיאה בהוספת זיכרון משותף");
        setActionType(null);
      }
    }
  }, [websiteReducer?.stateInfo]);

  const handleInputChanged = async (event) => {
    const { name, value, files } = event.target;

    if (name === "file") {
      const fileDetails = await getFileDetails(files[0]);
      if (fileDetails) {
        setSharedMemorial({
          ...sharedMemorial,
          file: fileDetails.file,
          type: fileDetails.fileType,
        });
      }
    } else if (name === "relationship") {
      const selectedOption = event.target.options[event.target.selectedIndex];
      const key = selectedOption.getAttribute("data-key");
      setSharedMemorial({ ...sharedMemorial, [name]: key });
    } else {
      setSharedMemorial({ ...sharedMemorial, [name]: value });
    }
  };

  const getFileDetails = (file) => {
    return new Promise((resolve, reject) => {
      if (file) {
        const fileType = file.type.split("/")[0]; // This will give 'image', 'audio', or 'video'

        const reader = new FileReader();
        reader.onload = (e) => {
          const imageUrl = e.target.result;
          resolve({ file, url: imageUrl, fileType }); // Include fileType in the resolved object
        };
        reader.onerror = () => reject(reader.error);
        reader.readAsDataURL(file);
      } else {
        reject("No file provided");
      }
    });
  };

  const handleAddSharedMemorialClicked = (event) => {
    event.preventDefault();

    const errorMessage = validateSharedMemorial();
    if (!errorMessage) {
      const formData = new FormData();
      formData.append("WebsiteId", websiteReducer?.websiteInfo?.id);
      formData.append("FullName", sharedMemorial.fullName);
      formData.append("Relationship", sharedMemorial.relationship);
      formData.append("MemorialDate", sharedMemorial.memorialDate);
      formData.append("Content", sharedMemorial.content);
      formData.append("PostUrl", sharedMemorial.postUrl);
      if (sharedMemorial.file) {
        formData.append("File", sharedMemorial.file);
        formData.append("Type", sharedMemorial.type);
      }

      setActionType("addSharedMemorial");
      websiteActions.addSharedMemorial(formData);
    } else {
      setErrorMessage(errorMessage);
    }
  };

  const validateSharedMemorial = () => {
    let errorMessage = "";

    if (!sharedMemorial.content) {
      errorMessage = "יש להכניס את פרטי הזיכרון";
    } else if (sharedMemorial.relationship !== "relationship_99" && !sharedMemorial.memorialDate) {
      errorMessage = "יש להוסיף את  תאריך הזיכרון";
    }
    return errorMessage;
  };

  const showLinkInput = () => {
    if (sharedMemorial.relationship === "relationship_96" || sharedMemorial.relationship === "relationship_97" || sharedMemorial.relationship === "relationship_98") {
      return true;
    }
    return false;
  };

  const hideMemorialDateInput = () => {
    if (sharedMemorial.relationship === "relationship_99") return true;
    else return false;
  };

  const closeDialog = () => {
    setErrorMessage(null);
    setActionType(null);
    setSharedMemorial(defaultSharedMemorial);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={closeDialog}>
      <div className="popup_candle_container" id="add_memories_popup_model">
        <div className="bg_layer_popup_candle_container"></div>
        <div className="popup_candle_box">
          <span className="popup_candle_close" onClick={closeDialog}>
            <i className="fa-solid fa-xmark"></i>
          </span>
          <h2>הוספת זיכרון</h2>
          <form id="form_candle">
            <div className="popup_candle_input_main_box" style={{ display: "flex", flexDirection: "column" }}>
              <div className="popup_candle_input_box">
                <label htmlFor="fullName">שם מלא</label>
                <input type="text" name="fullName" id="fullName" placeholder="שם מלא" value={sharedMemorial.fullName} onChange={handleInputChanged} />
              </div>
              <div className="popup_candle_input_box">
                <label htmlFor="relationship">קרבה/סוג זיכרון</label>

                <select
                  name="relationship"
                  id="relationship"
                  required
                  // value={deceasedInfo?.gender}
                  onChange={handleInputChanged}
                >
                  <option value="">בחר קרבה/סוג זיכרון</option>
                  {Object.entries(relationships_json).map(([key, value]) => (
                    <option key={key} value={value} data-key={key}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
              {showLinkInput() && (
                <div className="popup_candle_input_box">
                  <label htmlFor="postUrl">{`קישור ל${relationships_json[sharedMemorial.relationship]}`}</label>
                  <input type="text" name="postUrl" id="postUrl" placeholder={`קישור ל${relationships_json[sharedMemorial.relationship]}`} value={sharedMemorial.postUrl} onChange={handleInputChanged} />
                </div>
              )}
            </div>
            <div className="popup_candle_input_main_box" style={{ display: "flex", flexDirection: "column" }}>
              {!hideMemorialDateInput() && (
                <div className="popup_candle_input_box">
                  <label htmlFor="memorialDate">תאריך הזיכרון</label>
                  <input type="date" name="memorialDate" id="memorialDate" value={sharedMemorial.memorialDate} onChange={handleInputChanged} />
                </div>
              )}
              <div className="popup_candle_input_box">
                <label htmlFor="content">פרטי הזיכרון</label>
                <textarea name="content" id="content" placeholder="הקלד כאן את הזיכרון המשותף..." value={sharedMemorial.content} onChange={handleInputChanged}></textarea>
              </div>
            </div>

            <div className="popup_candle_input_main_box">
              <div className="popup_candle_input_box">
                <label htmlFor="file">הוסף תמונה/וידאו/שמע לזיכרון</label>
                <input type="file" name="file" id="file" accept="image/*,audio/*,video/*" onChange={handleInputChanged} />
              </div>
            </div>
            <p className="error_msg">{errorMessage}</p>
            <div className="popup_candle_button_box">
              <button type="submit" onClick={handleAddSharedMemorialClicked}>
                הוספת זיכרון
              </button>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state, ownProps) => ({
  websiteReducer: state.websiteReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    websiteActions: bindActionCreators(websiteActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSharedMemorialDialogComponent);

export const relationships_json = {
  relationship_1: "אמא",
  relationship_2: "אבא",
  relationship_3_male: "בן",
  relationship_3_female: "בת",
  relationship_4: "סבא",
  relationship_5: "סבתא",
  relationship_6: "דוד",
  relationship_7: "דודה",
  relationship_8: "אח",
  relationship_9: "אחות",
  relationship_10_male: "בן זוג",
  relationship_10_female: "בת זוג",
  relationship_11_male: "אחיין",
  relationship_11_female: "אחיינית",
  relationship_12_male: "חבר",
  relationship_12_female: "חברה",
  relationship_13_male: "קולגה",
  relationship_13_female: "עמית לעבודה",
  relationship_14_male: "מנהל",
  relationship_14_female: "מנהלת",
  relationship_15_male: "חניך",
  relationship_15_female: "חניכה",
  relationship_17_male: "נכד",
  relationship_17_female: "נכדה",
  relationship_18_male: "נין",
  relationship_18_female: "נינה",
  relationship_19_male: "שכן",
  relationship_19_female: "שכנה",
  relationship_20_male: "גיס",
  relationship_20_female: "גיסה",
  relationship_21_male: "דודן",
  relationship_21_female: "דודנית",
  relationship_22_male: "תלמיד",
  relationship_22_female: "תלמידה",
  relationship_23_male: "מדריך",
  relationship_23_female: "מדריכה",
  relationship_96: "כתבה",
  relationship_97: "פוסט",
  relationship_98: "מאמר",
  relationship_99: "מתכון",
  relationship_100: "אחר",
};
