import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import websiteActions from "../../store/actions/website.action";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import utilService from "../../services/general/util.service";
import FileVeiwerComponent from "../../component/FileVeiwerComponent";
import userIcon from "../../assets/Images/user-profile-icon.svg";
import { relationships_json } from "../../component/memorialWebsite/dialogs/AddSharedMemorialDialog";

const memorialsTypes = { sharedMemorials: "SharedMemorials", recipes: "Recipes" };

export const SharedMemorialPage = ({ websiteActions, websiteReducer, isMobileScreen, ...props }) => {
  const [memorials, setMemorials] = useState();
  const [memorialType, setMemorialType] = useState(memorialsTypes.sharedMemorials);
  const location = useLocation();

  useEffect(() => {
    if (websiteReducer?.websiteInfo) {
      filterMemorials();
    }
  }, [location, websiteReducer.websiteInfo]);

  const filterMemorials = () => {
    const { approvedSharedMemorials } = websiteReducer.websiteInfo;
    if (approvedSharedMemorials) {
      if (location.pathname.includes("Recipes")) {
        const recipes = approvedSharedMemorials.filter((memorial) => memorial.relationship === "relationship_99");
        setMemorials(recipes);
        setMemorialType(memorialsTypes.recipes);
      } else {
        const sharedMemorials = approvedSharedMemorials.filter((memorial) => memorial.relationship !== "relationship_99");
        setMemorials(sharedMemorials);
      }
    }
  };

  const getPageTitleByMemorialType = () => {
    if (memorialType === memorialsTypes.recipes) {
      return (
        <h2 className="shared_memories_main_box_main_heading">
          <span>מתכונים</span>
        </h2>
      );
    } else {
      return (
        <h2 className="shared_memories_main_box_main_heading">
          זכרונות <span>משותפים</span>
        </h2>
      );
    }
  };

  return (
    <section className="shared_memories_main_container">
      <div className="container">
        <div className="shared_memories_main_box">
          {getPageTitleByMemorialType()}
          <div className="shared_memories_card_main_box">
            {memorials?.map((sharedMemorial, index) => (
              <div className={`shared_memories_card_box ${index % 2 === 0 ? "reverse" : ""}`} key={sharedMemorial.id}>
                {sharedMemorial.sharedMemorialFile && (
                  <div className="shared_memories_card_media_box">
                    <FileVeiwerComponent fileType={sharedMemorial.sharedMemorialFile.type} url={sharedMemorial.sharedMemorialFile.url} />
                  </div>
                )}
                <div className="shared_memories_card_content_box">
                  <img src={userIcon} alt="" className="user-profile-icon-img" />
                  <div className="shared_memories_card_content_card">
                    <h2 className="shared_memories_card_content_card_heading">{sharedMemorial.fullName}</h2>
                    <h5 className="shared_memories_card_content_card_sub_heading">{relationships_json[sharedMemorial.relationship]}</h5>
                    <p className="shared_memories_card_content_card_decr">{sharedMemorial.content}</p>
                    {sharedMemorial.postUrl && (
                      <a className="shared-memories-link" href={sharedMemorial.postUrl} target="_blank" rel="noopener noreferrer">
                        לחץ כאן לצפיה
                      </a>
                    )}
                    {sharedMemorial.relationship !== "relationship_99" && <span className="shared_memories_card_content_card_date">{utilService.formateDate(sharedMemorial.memorialDate)}</span>}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state, ownProps) => ({
  websiteReducer: state.websiteReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    websiteActions: bindActionCreators(websiteActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SharedMemorialPage);
