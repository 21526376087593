import http from "./general/axios.service";

export const login = (data) => {
  return http.get(`User/Login/${data.email}/${data.password}`);
};

export const registerUser = (data) => {
  return http.post("User/Register", data);
};

const userService = {
  login,
  registerUser,
};

export default userService;
