import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import websiteActions from "../../store/actions/website.action";
import MemorialCandleCardComponent from "../../component/memorialWebsite/MemorialCandleCard";
import BannerImageComponent from "../../component/elements/BannerImageComponent";
import LoaderComponent from "../../component/LoaderComponent";

export const MemorialCandlePage = ({ websiteActions, websiteReducer, isMobileScreen, ...props }) => {

  const [websiteInfo, setWebsiteInfo] = useState();

  useEffect(() => {
    if (websiteReducer?.websiteInfo) {
      setWebsiteInfo(websiteReducer.websiteInfo);
    }
  }, [websiteReducer.websiteInfo]);

  return websiteInfo ? (
    <div className="memeorial-home-page-container">
      <section className="candle_main_content_container" dir="rtl">
        <div className="container">
          <div className="candle_main_content_box">
            <div className="candle_main_content_box_details">
              <BannerImageComponent url={websiteInfo?.deceasedInfo?.mainImage?.url} />
              <div className="main_content_box">
                <span>הכאב</span> הוא לא ביום שאנחנו מתגעגעים ליקירונו שהיו ואינם.
                <br />
                הכאב האמיתי הוא לחיות <span>בלעדיהם</span>, כשהנוכחות שלהם מתקיימת רק בראש
                {websiteInfo.memorialCandles && websiteInfo.memorialCandles.length > 0 && (
                  <p>
                    עד כה הודלקו <span>{websiteInfo.memorialCandles.length}</span> נרות זיכרון {`${websiteInfo.deceasedInfo.gender === "male" ? "לזכרו" : "לזכרה"}`} של {`${websiteInfo.deceasedInfo.firstName} ${websiteInfo.deceasedInfo.lastName}`}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="card_main_box_container" dir="rtl">
        <div className="container">
          <div className="card_main_box_box">
            <div className="card_main_inner_box_box">
              {websiteInfo.memorialCandles.map((memorialCandleInfo, index) => (
                <MemorialCandleCardComponent key={index} memorialCandleInfo={memorialCandleInfo} gender={websiteInfo.deceasedInfo.gender} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  ) : (
    <LoaderComponent isMobileScreen={isMobileScreen} />
  );
};

const mapStateToProps = (state, ownProps) => ({
  websiteReducer: state.websiteReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    websiteActions: bindActionCreators(websiteActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemorialCandlePage);
