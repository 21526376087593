import { BrowserRouter as Router, Route, Routes, useParams, useLocation } from "react-router-dom"; // Change the import to Routes
import { useEffect, useLayoutEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "swiper/components/pagination/pagination.min.css";

import userActions from "./store/actions/user.actions";

// Pages and Components
import HeaderComponent from "./component/Header";
import FooterComponent from "./component/Footer";

import AddSharedMemorialDialogComponent from "./component/memorialWebsite/dialogs/AddSharedMemorialDialog";
import LightCandleDialogComponent from "./component/memorialWebsite/dialogs/LightCandleDialog";
import websiteActions from "./store/actions/website.action";
import RouteHandler from "./routerHandler";
import AppRoutes from "./appRoutes";
import connection from "./services/singalR/signalR.service";
import signalRService from "./services/singalR/signalR.service";
import { signalRKeysEnum } from "./enums/signalR/signalRKeysEnum";

const App = ({ userReducer, userActions, websiteReducer, websiteActions }) => {
  const dispatch = useDispatch();

  const mobileMaxWidth = 756;
  const [windowSize, setWindowSize] = useState();
  const [isMobileScreen, setIsMobileScreen] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState();
  const [showHeader, setShowHeader] = useState(true);
  const [showFooter, setShowFooter] = useState(true);

  const [websiteInfo, setWebsiteInfo] = useState();

  // dialogs
  const [lightCandleDialogOpen, setLightCandleDialogOpen] = useState(false);
  const [addSharedMemorialDialogOpen, setAddSharedMemorialDialogOpen] = useState(false);

  const userToken = sessionStorage.getItem("token");

  useLayoutEffect(() => {
    function updateSize() {
      setWindowSize({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      });
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (windowSize && windowSize.innerWidth <= mobileMaxWidth) setIsMobileScreen(true);
    else setIsMobileScreen(false);
  }, [windowSize]);

  // ************************************

  useEffect(() => {
    const userJson = sessionStorage.getItem("LoggedInUser");
    if (userJson) {
      userActions.setUser(JSON.parse(userJson));
    }
  }, []);

  useEffect(() => {
    const connectSignalR = async () => {
      const connection = signalRService.createConnection(userToken);

      if (connection) {
        connection.on(signalRKeysEnum.websiteStorageSize, (message) => {
          signalRService.handleMessage(dispatch, signalRKeysEnum.websiteStorageSize, message);
        });

        try {
          await connection.start();
          console.log("SignalR Connected.");
        } catch (error) {
          console.log("SignalR Connection Error: ", error);
        }

        return () => {
          connection.stop();
        };
      }
    };

    if (userToken && !signalRService.getConnection()) {
      connectSignalR();
    }
  }, [userToken, dispatch]);

  useEffect(() => {
    setLoggedInUser(userReducer.userInfo);
  }, [userReducer?.userInfo]);

  useEffect(() => {
    if (websiteReducer?.websiteInfo) {
      setWebsiteInfo(websiteReducer.websiteInfo);
    }
  }, [websiteReducer.websiteInfo]);

  const handleOpenLightCandleDialogClicked = (event) => {
    event.preventDefault();
    setLightCandleDialogOpen(true);
  };

  const handleOpenAddSharedMemorialDialogClicked = (event) => {
    event.preventDefault();
    setAddSharedMemorialDialogOpen(true);
  };

  const handleCloseLightCandleDialogClicked = (event) => {
    setLightCandleDialogOpen(false);
  };

  const handleCloseAddSharedMemorialDialogClicked = (event) => {
    setAddSharedMemorialDialogOpen(false);
  };

  const toggleOpenNavMenu = (open) => {
    setIsMenuOpen(open);
  };

  return (
    <div className="app-container">
      {isMenuOpen && (
        <div
          className="main-screen"
          onClick={() => {
            toggleOpenNavMenu(!isMenuOpen);
          }}
        ></div>
      )}
      <Router>
        <RouteHandler setWebsiteInfo={setWebsiteInfo} websiteReducer={websiteReducer} websiteActions={websiteActions} />

        {showHeader && <HeaderComponent isMobileScreen={isMobileScreen} websiteInfo={websiteInfo} loggedInUser={loggedInUser} isMenuOpen={isMenuOpen} toggleOpenNavMenu={toggleOpenNavMenu} />}

        <AppRoutes loggedInUser={loggedInUser} isMobileScreen={isMobileScreen} setShowFooter={setShowFooter} setShowHeader={setShowHeader} />

        {showFooter && <FooterComponent websiteInfo={websiteInfo} lightCandleCliked={handleOpenLightCandleDialogClicked} addSahredMemorialCliked={handleOpenAddSharedMemorialDialogClicked} />}
      </Router>

      <LightCandleDialogComponent open={lightCandleDialogOpen} handleClose={handleCloseLightCandleDialogClicked} websiteInfo={websiteInfo} />

      <AddSharedMemorialDialogComponent open={addSharedMemorialDialogOpen} handleClose={handleCloseAddSharedMemorialDialogClicked} />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  userReducer: state.userReducer,
  websiteReducer: state.websiteReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    websiteActions: bindActionCreators(websiteActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
