import { Link } from "react-router-dom";
import utilService from "../../../services/general/util.service";
import GalleryImageComponent from "../../elements/GalleryImageComponent";
import MemorialHomePgaeModuleHeaderComponent from "./MemorialHomePgaeModuleHeader";
import { useState } from "react";

export const MemorialHomeGalleryComponent = ({ websiteInfo, isMobileScreen, ...props }) => {
  const { homeGalleryImages } = websiteInfo.deceasedInfo;
  const [selectedImage, setSelectedImage] = useState(null);

  const openFullScreen = (image) => {
    if (image.type === "Image" || (image.type === "Video" && !isMobileScreen)) {
      setSelectedImage(image);
    }
  };

  const closeFullScreen = () => {
    setSelectedImage(null);
  };

  return (
    <section className="gallery_main_content_container">
      <div className="container">
        <div className="home-gallery-container">
          <MemorialHomePgaeModuleHeaderComponent baseRoute={websiteInfo.baseRoute} title="לראות" boldTitle="ולהיזכר" route="gallery" linkTitle="כל התמונות" />

          <div className="gallery_main_content_box">
            <div className="gallery_main_box_left">{homeGalleryImages?.length > 0 ? homeGalleryImages.map((imageDetails) => <GalleryImageComponent key={utilService.makeId()} imageDetails={imageDetails} handleClick={openFullScreen} />) : [...Array(3)].map((_) => <GalleryImageComponent key={utilService.makeId()} />)}</div>
          </div>
        </div>
      </div>

      {selectedImage && selectedImage.type === "Image" && (
        <div className="full-screen-modal" onClick={closeFullScreen}>
          <img src={selectedImage.url} alt="Full Screen Image" />
        </div>
      )}
    </section>
  );
};

export default MemorialHomeGalleryComponent;
