import { paymentActionTypes } from "../actionTypes/paymentActionTypes";
import paymentService from '../../services/payment.service'

export const createPaymentPage = (data) => async (dispatch) => {
  try {
    dispatch({ type: paymentActionTypes.START_CREATE_PAYMENT_PAGE });

    const response = await paymentService.createPaymentPage(data);

    dispatch({
      type: paymentActionTypes.CREATE_PAYMENT_PAGE_SUCCESS,
      data: response.data.payload,
    });
  } catch (ex) {
    console.log("createPaymentPage error: " + ex.message);
    dispatch({ type: paymentActionTypes.CREATE_PAYMENT_PAGE_FAILURE });
  }
};

export const getPaymentTokenResult = (data) => async (dispatch) => {
  try {
    dispatch({ type: paymentActionTypes.START_GET_PAYMENT_TOKEN });

    const response = await paymentService.getPaymentTokenResult(data);

    dispatch({
      type: paymentActionTypes.GET_PAYMENT_TOKEN_SUCCESS,
    });
  } catch (ex) {
    console.log("getPaymentTokenResult error: " + ex.message);
    dispatch({ type: paymentActionTypes.GET_PAYMENT_TOKEN_FAILURE });
  }
};

const paymentActions = {
  createPaymentPage,
  getPaymentTokenResult,
};

export default paymentActions;
