import { Link } from 'react-router-dom'
import ProgressBarComponent from '../../elements/ProgressBarComponent'
import WebsiteStorageUsageComponent from '../WebsiteStorageUsageComponent'
import websiteActions from '../../../store/actions/website.action'
import paymentActions from '../../../store/actions/payment.action'
import { useEffect, useState } from 'react'
import WesiteLicenseDialogComponent from '../dialogs/WesiteLicenseDialog'
import { connect } from 'react-redux'
import {
  ALERT_ICON,
  alertService,
} from '../../../services/general/alert.service'
import { websiteErrorEnum } from '../../../enums/errorsEnums/websiteErrorEnum'

export const DashboardCardConponent = ({
  // websiteActions,
  websiteDetails,
  showWebsiteUpdatesClicked,
  handleShowWebsiteClicked,
  websiteReducer,
  ...props
}) => {
  const [websiteLicenseDialogOpen, setWebsiteLicenseDialogOpen] = useState(
    false,
  )
  const [actionType, setActionType] = useState()

  useEffect(() => {
    if (
      !websiteReducer.stateInfo.isLoading &&
      websiteReducer.stateInfo.isSuccess
    ) {
      handleActionSuccess(websiteReducer.stateInfo.error)
      // handleRegisterWebsiteSubscriptionSeccess();
    }

    if (
      !websiteReducer.stateInfo.isLoading &&
      websiteReducer.stateInfo.isError
    ) {
      // handleActionFailed(websiteReducer.stateInfo.error);
    }
  }, [websiteReducer.stateInfo])

  const handleActionSuccess = (error) => {
    switch (error) {
      case websiteErrorEnum.RegisterFreeMemorialWebsiteLicanseSuccessfully:
        alertService.showMessage(ALERT_ICON.success, 'רשיון נוצר בהצלחה')
        break
      default:
        break
    }
  }

  const handleActivateLicenseClicked = () => {
    setWebsiteLicenseDialogOpen(true)
  }

  const handleCloseWebsiteLicenseDialog = () => {
    setWebsiteLicenseDialogOpen(false)
  }

  const validateWebsiteSubscription = () => {
    let isValidSubscription = false
    if (
      websiteDetails.websiteLicense &&
      websiteDetails.websiteLicense.isActive
    ) {
      isValidSubscription = true
    }
    return isValidSubscription
  }

  return (
    <div className="Websites_updated_card">
      {!validateWebsiteSubscription() && (
        <button
          className="activate-subscription-button"
          onClick={handleActivateLicenseClicked}
        >
          הפעל מנוי
        </button>
      )}
      <h3>
        {websiteDetails.deceasedInfo
          ? `${websiteDetails.deceasedInfo.firstName} ${websiteDetails.deceasedInfo.lastName}`
          : `ניתוב: ${websiteDetails.baseRoute}`}
      </h3>
      {websiteDetails.deceasedInfo && (
        <h4>{`ניתוב: ${websiteDetails.baseRoute}`}</h4>
      )}
      {validateWebsiteSubscription() && (
        <h4>סוג מנוי: {websiteDetails.websiteLicense.subscriptionType}</h4>
      )}
      <div className="website_action_btn_box">
        {websiteDetails.websiteLicense &&
          websiteDetails.websiteLicense.isActive && (
            <Link
              to={`/memorial/${websiteDetails.baseRoute}`}
              onClick={() => {
                handleShowWebsiteClicked(websiteDetails)
              }}
            >
              צפייה
            </Link>
          )}
        <Link to={`/dashboard/update/${websiteDetails.id}`}>עריכה</Link>
        <a
          href="#"
          className="update_box"
          onClick={() => {
            showWebsiteUpdatesClicked(
              websiteDetails.id,
              websiteDetails.waitingListSharedMemrials,
            )
          }}
        >
          עידכונים{' '}
          <span className="website_update_num">
            {websiteDetails.waitingListSharedMemrials
              ? websiteDetails.waitingListSharedMemrials.length
              : 0}
          </span>
        </a>
      </div>
      <WebsiteStorageUsageComponent
        storageSize={websiteDetails.websiteStrageSize}
      />

      <WesiteLicenseDialogComponent
        open={websiteLicenseDialogOpen}
        handleClose={handleCloseWebsiteLicenseDialog}
        websiteId={websiteDetails.id}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  websiteReducer: state.websiteReducer,
})

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardCardConponent)
// export default DashboardCardConponent
