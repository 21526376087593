export const MemorialHomeStoryComponsnt = ({ websiteInfo, ...props }) => {
  const { deceasedInfo } = websiteInfo;
  const { deceasedStory } = deceasedInfo;
  return (
    <section className="client_website_home_story_profile_data_details_container">
      <div className="client_website_home_story_container">
        <div className="client_website_home_story_profile_data_details_box">
          <div className="client_website_home_story_profile_data_details_left">{deceasedStory?.storyImage?.url ? <img src={deceasedStory?.storyImage?.url} alt="" /> : <span className="dummy_image_card">תמונה</span>}</div>
          <div className="client_website_home_story_profile_data_details_right" dir="rtl">
            <h2 className="client_website_home_story_profile_data_details_heading">סיפור {`${deceasedInfo.gender === "male" ? "חייו" : "חייה"}`}</h2>
            {deceasedStory.title && <h2 className="client_website_home_story_profile_data_details_subheading">{deceasedStory.title}</h2>}
            <pre className="client_website_home_story_profile_data_details_p">{deceasedStory.content}</pre>
            {deceasedStory.subTitle && <h2 className="client_website_home_story_profile_data_tag_line">{deceasedStory.subTitle}</h2>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MemorialHomeStoryComponsnt;
