import { HubConnectionBuilder } from "@microsoft/signalr";
import { getServerBaseUrl } from "../general/axios.service";
import { signalRKeysEnum } from "../../enums/signalR/signalRKeysEnum";
import websiteActions from "../../store/actions/website.action";

let connection;

export const createConnection = (token) => {
  if (token) {
    connection = new HubConnectionBuilder()
      .withUrl(`${getServerBaseUrl()}/notificationhub`, {
        accessTokenFactory: () => token,
        withCredentials: true,
      })
      .withAutomaticReconnect()
      .build();
  }
  return connection;
};

export const handleMessage = (dispatch, key, message) => {
  switch (key) {
    case signalRKeysEnum.websiteStorageSize:
      dispatch(websiteActions.updateWebsiteStorageSize(JSON.parse(message)));
      break;
    default:
      break;
  }
};

export const getConnection = () => connection;

export default {
  createConnection,
  getConnection,
  handleMessage,
};
