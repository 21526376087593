import http from "./general/axios.service";

export const getPlaylistSongs = (data) => {
    return http.get(`Playlist/${data}`);
};

export const deletePlaylistSong = (data) => {
    return http.delete(`Playlist/${data}`);
};

export const addPlaylistSong = (data) => {
    return http.post("Playlist/Add", data);
};

const playlistService = {
    getPlaylistSongs,
    addPlaylistSong,
    deletePlaylistSong,
};

export default playlistService;