export const ProgressBarComponent = ({ percentage, ...props }) => {
  return (
    <div className="storage-bar-container">
      <div className="storage-bar" style={{ width: `${percentage}%` }}>
        {percentage}%
      </div>
    </div>
  );
};

export default ProgressBarComponent;
