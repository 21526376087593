import bannerBlackLeaf from "../../assets/Images/CreateWebsiteFiles/black-leaves.svg";
import bannerPinkLeaf from "../../assets/Images/CreateWebsiteFiles/banner_pink_leaf.svg";
import bannerOrangeLeaf from "../../assets/Images/CreateWebsiteFiles/banner_orange_light_leaf.svg";

export const BannerImageComponent = ({ url }) => {
  return (
    <div className="client_website_main_image_profile_right">
      <div className="client_website_main_profile_page_image_box">
        <div className="client_website_black_leaf_box">
          <img src={bannerBlackLeaf} alt="" />
        </div>
        <div className="client_website_pink_leaf_box">
          <img src={bannerPinkLeaf} alt="" />
        </div>
        <div className="client_website_orange_leaf_box">
          <img src={bannerOrangeLeaf} alt="" />
        </div>
          <div className="client_website_main_profile_image_card">
            {url ? <img src={url} alt="" /> : <span className="dummy_image_card">תמונה</span>}
          </div>
      </div>
    </div>
  );
};

export default BannerImageComponent;
