import { Link } from "react-router-dom";

export const MemorialWebsiteNavMenuComponent = ({ websiteInfo, handleCloseMenu, loggedInUser, handleLogoutUserClicked, ...props }) => {
  const showHidePlaylistMenu = () => {
    let showMenu = false;
    if (websiteInfo?.websiteLicense?.subscriptionType === "Advance" || websiteInfo?.websiteLicense?.subscriptionType === "Premium") {
      if (websiteInfo.playlistSongs && websiteInfo.playlistSongs.length > 0) {
        showMenu = true;
      }
    }
    return showMenu;
  };

  const showHideSharedMemorialMenu = () => {
    let showMenu = false;
    if (websiteInfo?.websiteLicense?.subscriptionType === "Advance" || websiteInfo?.websiteLicense?.subscriptionType === "Premium") {
      if (websiteInfo.approvedSharedMemorials && websiteInfo.approvedSharedMemorials.length > 0) {
        showMenu = true;
      }
    }
    return showMenu;
  };

  const showHideSharedMemorialRecipesMenu = () => {
    let showMenu = false;
    if (websiteInfo?.websiteLicense?.subscriptionType === "Advance" || websiteInfo?.websiteLicense?.subscriptionType === "Premium") {
      if (websiteInfo.approvedSharedMemorials && websiteInfo.approvedSharedMemorials.length > 0) {
        if (websiteInfo.approvedSharedMemorials.some((memorial) => memorial.relationship === "relationship_99")) {
          showMenu = true;
        }
      }
    }
    return showMenu;
  };

  const showHideMemorialCandlesMenu = () => {
    let showMenu = false;
    if (websiteInfo.memorialCandles && websiteInfo.memorialCandles.length > 0) {
      showMenu = true;
    }
    return showMenu;
  };

  const showHideDeceasedGalleryMenu = () => {
    let showMenu = false;
    if (websiteInfo.deceasedInfo?.galleryImages && websiteInfo.deceasedInfo?.galleryImages?.length > 0) {
      showMenu = true;
    }
    return showMenu;
  };

  const showHideBurialLocationMenu = () => {
    let showMenu = false;
    if (websiteInfo.deceasedInfo?.burialLocation && websiteInfo.deceasedInfo?.burialLocation?.city) {
      showMenu = true;
    }
    return showMenu;
  };

  return (
    <ul>
      <span className="cross_icon" onClick={handleCloseMenu}>
        <i className="fa-solid fa-xmark"></i>
      </span>

      {showHideBurialLocationMenu() && (
        <Link to={`/memorial/${websiteInfo.baseRoute}/graveInfo`} className="link" onClick={handleCloseMenu}>
          פרטי קבורה
        </Link>
      )}
      <Link to={`/memorial/${websiteInfo.baseRoute}/prayer`} className="link" onClick={handleCloseMenu}>
        תפילה
      </Link>
      {showHidePlaylistMenu() && (
        <Link to={`/memorial/${websiteInfo.baseRoute}/Playlist`} className="link" onClick={handleCloseMenu}>
          שירים {`${websiteInfo.deceasedInfo.gender === "male" ? "שאהב" : "שאהבה"}`}
        </Link>
      )}
      {showHideSharedMemorialMenu() && (
        <Link to={`/memorial/${websiteInfo.baseRoute}/SharedMemorial`} className="link" onClick={handleCloseMenu}>
          זיכרונות משותפים
        </Link>
      )}
      {showHideSharedMemorialRecipesMenu() && (
        <Link to={`/memorial/${websiteInfo.baseRoute}/SharedMemorial/Recipes`} className="link" onClick={handleCloseMenu}>
          מתכונים
        </Link>
      )}
      {showHideMemorialCandlesMenu() && (
        <Link to={`/memorial/${websiteInfo.baseRoute}/memorialCandles`} className="link" onClick={handleCloseMenu}>
          נרות {`${websiteInfo.deceasedInfo.gender === "male" ? "לזכרו" : "לזכרה"}`}
        </Link>
      )}
      {showHideDeceasedGalleryMenu() && (
        <Link to={`/memorial/${websiteInfo.baseRoute}/gallery`} className="link" onClick={handleCloseMenu}>
          זיכרונות בתמונות
        </Link>
      )}
      {!loggedInUser && (
        <Link to="/login" className="link" onClick={handleCloseMenu}>
          התחבר
        </Link>
      )}
      {loggedInUser && loggedInUser.userType === "WebsiteManager" && (
        <Link to="/dashboard" className="link" onClick={handleCloseMenu}>
          איזור ניהול
        </Link>
      )}
      {loggedInUser && (
        <Link to="/" className="link" onClick={handleLogoutUserClicked}>
          התנתק
        </Link>
      )}
    </ul>
  );
};

export default MemorialWebsiteNavMenuComponent;
