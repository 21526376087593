import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog } from "@mui/material";
import { useState } from "react";
import websiteActions from "../../../store/actions/website.action";
import { packageDetails } from "../../salesWebsite/homePage/SalesOurPackages";
import { ALERT_ICON, alertService } from "../../../services/general/alert.service";

const default_subscription = {
  subscriptionType: "Basic",
  copunCode: "",
};

export const WesiteLicenseDialogComponent = ({ websiteActions, websiteReducer, userReducer, websiteId, open, handleClose, ...props }) => {
  const [websiteSubscription, setWebsiteSubscription] = useState(default_subscription);
  const [openSubscriptionInfo, setOpenSubscriptionInfo] = useState();

  const handleSubmitWebsiteSubscriptionInfo = (event) => {
    event.preventDefault();
    const request = {
      subscriptionType: websiteSubscription.subscriptionType,
      websiteId,
      userId: userReducer.userInfo.id,
      copunCode: websiteSubscription.copunCode,
    };

    if (request.subscriptionType === "Free") {
      websiteActions.registerFreeWebsiteSubscription(request);
    } else {
      websiteActions.registerWebsiteSubscription(request);
    }
  };

  const handleSelectSubscriptionType = (subscriptionType) => {
    setWebsiteSubscription({
      ...websiteSubscription,
      subscriptionType: subscriptionType,
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setWebsiteSubscription({
      ...websiteSubscription,
      [name]: value,
    });
  };

  const handleToggleSubscriptionInfo = (type) => {
    if (type !== openSubscriptionInfo) {
      setOpenSubscriptionInfo(type);
    } else {
      setOpenSubscriptionInfo(null);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <section className="popup_payment_status_container" id="plan_popup_model" dir="rtl">
        <div className="popup_payment_status_box">
          <button className="close-dialog-button" onClick={handleClose}>
            <i className="fa-solid fa-circle-xmark"></i>
          </button>
          <span className="popup_cross_btn">&#10006;</span>
          <h2 className="popup_heading">סוגי חבילות</h2>
          <div className="popup_card_plans_main_box">
            {packageDetails.map((details, index) => (
              <div key={index} className={`popup_card_plans__box ${websiteSubscription.subscriptionType === details.type ? "active" : ""}`}>
                <span className="plan_name">
                  <span
                    className="info_this_plan"
                    onClick={() => {
                      handleToggleSubscriptionInfo(details.type);
                    }}
                  >
                    <i className="fa-solid fa-circle-info"></i>
                  </span>
                  {details.name}
                </span>
                <span className="plan_price">₪ {details.price} לשנה</span>

                <ul className={openSubscriptionInfo === details.type ? "subscription-details-container" : "hide"}>
                  {details?.description?.map((description, index) => (
                    <li key={index}>
                      <i className="fa-solid fa-circle-check"></i> {description}
                    </li>
                  ))}
                </ul>

                {details.active && (
                  <button
                    className="popup_plan_buy_now_btn"
                    onClick={() => {
                      handleSelectSubscriptionType(details.type);
                    }}
                  >
                    בחר
                  </button>
                )}
              </div>
            ))}
          </div>
          <div className="form_input_main_box">
            <div className="form_input_box">
              <label htmlFor="copunCode">קוד קופון</label>
              <input className="light-orange-backgound" type="text" name="copunCode" id="copunCode" required placeholder="הכנס קוד קופון" onChange={handleInputChange} />
            </div>
          </div>
          <div className="create-website-form-button">
            <button
              onClick={(event) => {
                event.stopPropagation();
                handleSubmitWebsiteSubscriptionInfo(event);
              }}
            >
              רכוש חבילה
            </button>
            {/* <button type="submit" id="submitbtn" style="display: none;">שלח</button> */}
          </div>
        </div>
        {/* </div> */}
      </section>
    </Dialog>
  );
};

const mapStateToProps = (state, ownProps) => ({
  websiteReducer: state.websiteReducer,
  userReducer: state.userReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    websiteActions: bindActionCreators(websiteActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WesiteLicenseDialogComponent);
