import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import MemorialMainBannerComponent from "../../component/memorialWebsite/homePage/MemorialMainBanner";
import websiteActions from "../../store/actions/website.action";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import MemorialHomeStoryComponsnt from "../../component/memorialWebsite/homePage/MemorialHomeStory";
import MemorialHomeGalleryComponent from "../../component/memorialWebsite/homePage/MemorialHomeGallery";
import MemorialHomeCandleGalleryComponent from "../../component/memorialWebsite/homePage/MemorialHomeCandleGallery";
import LoaderComponent from "../../component/LoaderComponent";

export const MemorialHomePage = ({ websiteActions, websiteReducer, isMobileScreen, editableWebsiteInfo, ...props }) => {
  const [websiteInfo, setWebsiteInfo] = useState();

  const checkIfCreateWebsiteProcess = () => {
    const currentURL = window.location.href;
    if (currentURL.includes("/dashboard/create")) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!editableWebsiteInfo && websiteReducer?.websiteInfo) {
      setWebsiteInfo(websiteReducer.websiteInfo);
    }
  }, [websiteReducer.websiteInfo]);

  useEffect(() => {
    if (editableWebsiteInfo) {
      setWebsiteInfo(editableWebsiteInfo);
    }
  }, [editableWebsiteInfo]);

  return websiteInfo ? (
    <div className="memeorial-home-page-container" style={{ display: "flex", flexDirection: "column", gap: "50px" }}>
      <MemorialMainBannerComponent websiteInfo={websiteInfo} />
      <MemorialHomeStoryComponsnt websiteInfo={websiteInfo} />
      <MemorialHomeGalleryComponent websiteInfo={websiteInfo} isMobileScreen={isMobileScreen} />
      {websiteInfo?.websiteLicense?.subscriptionType !== "Free" && <MemorialHomeCandleGalleryComponent websiteInfo={websiteInfo} isMobileScreen={isMobileScreen} />}
    </div>
  ) : (
    <LoaderComponent isMobileScreen={isMobileScreen} />
  );
};

const mapStateToProps = (state, ownProps) => ({
  websiteReducer: state.websiteReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    websiteActions: bindActionCreators(websiteActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemorialHomePage);
