import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import websiteActions from "../../store/actions/website.action";
import BannerImageComponent from "../../component/elements/BannerImageComponent";
import { ALERT_ICON, alertService } from "../../services/general/alert.service";

export const MemorialGalleryPage = ({ websiteActions, websiteReducer, isMobileScreen, editableWebsiteInfo, ...props }) => {
  const { baseRoute } = useParams();

  const [websiteInfo, setWebsiteInfo] = useState();
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (!editableWebsiteInfo && websiteReducer?.websiteInfo) {
      setWebsiteInfo(websiteReducer.websiteInfo);
    }
  }, [websiteReducer.websiteInfo]);

  useEffect(() => {
    if (editableWebsiteInfo) {
      setWebsiteInfo(editableWebsiteInfo);
    }
  }, [editableWebsiteInfo]);

  const openFullScreen = (image) => {
    if (image.type === "Image" || (image.type === "Video" && !isMobileScreen)) {
      setSelectedImage(image);
    }
  };

  const closeFullScreen = () => {
    setSelectedImage(null);
  };

  const handleDeleteImage = async (event, fileId) => {
    event.preventDefault();
    const message = "האם אתה בטוח שברצונך למחוק תמונה זו?";
    const userChoice = await alertService.confirmDialog(ALERT_ICON.question, message);
    if (userChoice) {
      const response = await websiteActions.deleteDeceasedFile(fileId);
      if (response && response.status === 200) {
        websiteActions.getDeceasedGalleryFiles(websiteInfo.deceasedInfo.id);
        alertService.showMessage(ALERT_ICON.success, "תמונה נמחקה בהצלחה");
      } else {
        alertService.showMessage(ALERT_ICON.error, "שגיאה במחיקת תמונה");
      }
    }
  };

  return websiteInfo ? (
    <section className="memories_pictures_main_content_container">
      <div className="container memorial-gallery-container">
        <div className="gallery-banner-container">
          <BannerImageComponent url={websiteInfo?.deceasedInfo?.mainImage?.url} />
          <div className="gallery_heading_box">
            <h2 className="gallery_heading_box_heading">
              אפשר לעצום את העיניים למציאות, אבל לא לזכרונות. כי בסוף,
              <span> רק הזיכרון נשאר.</span>
            </h2>
          </div>
        </div>
        <div className="gallery-container">
          {websiteInfo.deceasedInfo?.galleryImages?.map((galleryImage) => (
            <div className="gallery-image-container" key={galleryImage.id}>
              {galleryImage.type === "Image" && (
                <div className="image-container" onClick={() => openFullScreen(galleryImage)}>
                  <img src={galleryImage.url} alt={`Gallery Image ${galleryImage.id}`} />
                </div>
              )}

              {galleryImage.type === "Video" && (
                <div className="image-container video-container" onClick={() => openFullScreen(galleryImage)}>
                  {!isMobileScreen && (
                    <div className="play-button-container">
                      <i class="fa-solid fa-play"></i>
                    </div>
                  )}
                  <video src={galleryImage.url} alt={`Gallery Image ${galleryImage.id}`} controls={isMobileScreen ? true : false} />
                </div>
              )}

              {editableWebsiteInfo && (
                <div className="delete-button-container">
                  <button
                    onClick={(event) => {
                      handleDeleteImage(event, galleryImage.id);
                    }}
                  >
                    X
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {selectedImage && selectedImage.type === "Image" && (
        <div className="full-screen-modal" onClick={closeFullScreen}>
          <img src={selectedImage.url} alt="Full Screen Image" />
        </div>
      )}

      {selectedImage && selectedImage.type === "Video" && (
        <div className={!isMobileScreen ? "full-screen-modal" : ""} onClick={closeFullScreen}>
          <video src={selectedImage.url} alt={`Gallery Image ${selectedImage.id}`} controls autoPlay />
        </div>
      )}
    </section>
  ) : (
    <div>Loading...</div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  websiteReducer: state.websiteReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    websiteActions: bindActionCreators(websiteActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemorialGalleryPage);
