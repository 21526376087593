import http from "./general/axios.service";

export const getMemorialCandles = (data) => {
  return http.get(`MemorialCandle/${data}`, data);
};

export const addMemorialCandle = (data) => {
  return http.post("MemorialCandle/Add", data);
};

const memorialCandleService = {
  getMemorialCandles,
  addMemorialCandle,
};

export default memorialCandleService;
