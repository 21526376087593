import SalesHomePageBannerComponent from "../../component/salesWebsite/homePage/SalesHomePageBanner";
import SalesOurClientsComponent from "../../component/salesWebsite/homePage/SalesOurClients";
import SalesOurPackagesComponent from "../../component/salesWebsite/homePage/SalesOurPackages";
import SalesOurStoryComponent from "../../component/salesWebsite/homePage/SalesOurStory";
import SalesContactUsFormComponent from "../../component/salesWebsite/homePage/SalesContactUsForm";
import SalesOurServicesComponent from "../../component/salesWebsite/homePage/SalesOurServices";
import SocialFloatButtonsComponent from "../../component/salesWebsite/SocialFloatButtons";
import SalesPopupDialogComponent from "../../component/salesWebsite/SalesPopupDialog";
import { useEffect, useState } from "react";

import videoBanner from "../../assets/files/CreateWebsiteTetorial.mp4";

export const SalesHomePage = ({ ...props }) => {
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPopupOpen(true);
    }, 3000); // Sets popupOpen to true after 5 seconds

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
  }, []);

  const handleClosePopup = (event) => {
    setPopupOpen(false);
  };

  return (
    <div className="home-page-container">
      <SalesHomePageBannerComponent />
      <SalesOurStoryComponent />

      <div className="video-banner">
        <video src={videoBanner} controls autoPlay loop style={{ maxWidth: "100%", height: "auto" }}>
          Your browser does not support the video tag.
        </video>
      </div>

      <SalesOurServicesComponent />
      <SalesOurClientsComponent />
      <SalesOurPackagesComponent />
      <SalesContactUsFormComponent />

      <SocialFloatButtonsComponent />

      <SalesPopupDialogComponent open={popupOpen} handleClose={handleClosePopup} />
    </div>
  );
};

export default SalesHomePage;
