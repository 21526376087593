import http from "./general/axios.service";

export const addOrUpdateDeceasedInfo = (data) => {
  return http.post("Deceased/AddOrUpdateDeceasedinfo", data);
};

export const addDeceasedFile = (data) => {
  return http.post("Deceased/AddDeceasedFile", data);
};

export const addDeceasedFiles = (data) => {
  return http.post("Deceased/AddDeceasedFiles", data);
};

export const addOrUpdateDeceasedStory = (data) => {
  return http.post("Deceased/AddOrUpdateDeceasedStory", data);
};

export const addOrUpdateBurialLocation = (data) => {
  return http.post("Deceased/AddOrUpdateBurialLocation", data);
};

export const getDeceasedGalleryFiles = (data) => {
  return http.get(`Deceased/GetDeceasedGalleryFiles/${data}`);
};

export const deleteDeceasedFile = (data) => {
  return http.delete(`Deceased/DeleteDeceasedFile/${data}`);
};

const deceasedService = {
  addOrUpdateDeceasedInfo,
  addDeceasedFile,
  addDeceasedFiles,
  addOrUpdateDeceasedStory,
  addOrUpdateBurialLocation,
  getDeceasedGalleryFiles,
  deleteDeceasedFile,
};

export default deceasedService;
