export const SocialFloatButtonsComponent = ({ ...props }) => {
  const facebookUrl = "https://www.facebook.com/profile.php?id=61552965197119"; // Replace with your Facebook page URL
  const instagramUrl = "https://www.instagram.com/izcor_livingMemories/?fbclid=IwAR0PzxB7iK4TnfjsGM7YoJC2SK_sNm0BDzBoIQ2thrlTvv2gHgQQfofxSTc"; // Replace with your Instagram profile URL
  const whatsappNumber = "+972559738701"; // Replace with your phone number
  const whatsappMessage = encodeURIComponent("שלום, אשמח לקבל פרטים נוספים על זיכרונות חיים. "); // Default message
  
  return (
    <div className="contact-float-button">
      <a href={facebookUrl} target="_blank" rel="noopener noreferrer">
        <button>
          <i className="fa-brands fa-facebook"></i>
        </button>
      </a>
      <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
        <button>
          <i className="fa-brands fa-instagram"></i>
        </button>
      </a>
      <a href={`https://wa.me/${whatsappNumber}?text=${whatsappMessage}`} target="_blank" rel="noopener noreferrer">
        <button>
          <i className="fa-brands fa-whatsapp"></i>
        </button>
      </a>
    </div>
  );
};

export default SocialFloatButtonsComponent;
