import TermsAndPrivacy from "../assets/files/TermsAndPrivacy.pdf";
import AccesabilityTerms from "../assets/files/AccesabilityTerms.pdf";

export const TermsAndPrivacyPage = ({ ...props }) => {
  return (
    <div className="terms-privacy-container">
      <iframe
        src={TermsAndPrivacy}
        style={{
          border: "none", // Optional, for removing frame border
          width: "90%",
          height: "100%",
          position: "absolute",
          top: 100,
        }}
      ></iframe>
    </div>
  );
};

export default TermsAndPrivacyPage;
