import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog } from "@mui/material";
import websiteActions from "../../../store/actions/website.action";
import { useEffect, useState } from "react";
import { ALERT_ICON, alertService } from "../../../services/general/alert.service";

export const LightCandleDialogComponent = ({ websiteActions, websiteReducer, open, handleClose, ...props }) => {
  const [name, setName] = useState();
  const [actionType, setActionType] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [memorialCandles, setMemorialCandles] = useState([]);

  const handleInputChanged = (event) => {
    const { value } = event.target;
    setName(value);
  };

  useEffect(() => {
    if (websiteReducer.websiteInfo) {
      setMemorialCandles(websiteReducer.websiteInfo.memorialCandles);
    }

    return () => {
      setActionType(null);
      setName(null);
      setErrorMessage(null);
    };
  }, []);

  useEffect(() => {
    if (websiteReducer.websiteInfo) {
      setMemorialCandles(websiteReducer.websiteInfo.memorialCandles);
    }
  }, [websiteReducer.websiteInfo]);

  useEffect(() => {
    if (!websiteReducer?.stateInfo?.isLoading && websiteReducer?.stateInfo?.isSuccess) {
      if (actionType === "lightCandle") {
        alertService.showMessage(ALERT_ICON.success, "נר זיכרון נדלק בהצלחה");
        closeDialog();
      }
    }

    if (!websiteReducer?.stateInfo?.isLoading && websiteReducer?.stateInfo?.isError) {
      if (actionType === "lightCandle") {
        setErrorMessage("שגיאה בהדלקת נר זיכרון");
        setActionType(null);
      }
    }
  }, [websiteReducer?.stateInfo]);

  const handleLightCandleClicked = (event) => {
    event.preventDefault();

    if (name) {
      const request = { name, websiteId: websiteReducer?.websiteInfo?.id };
      setActionType("lightCandle");
      websiteActions.addMemorialCandle(request);
    } else {
      setErrorMessage("יש למלא שם מלא");
    }
  };

  const closeDialog = () => {
    setName(null);
    setErrorMessage(null);
    setActionType(null);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={closeDialog}>
      <div className="popup_candle_container" id="candle_popup_model">
        <div className="bg_layer_popup_candle_container"></div>
        <div className="popup_candle_box">
          <span className="popup_candle_close" onClick={closeDialog}>
            <i className="fa-solid fa-xmark"></i>
          </span>
          <h2>הדלקת נר</h2>
          {websiteReducer?.websiteInfo?.deceasedInfo && (
            <h4>
              הדלק את הנר ה- <span>{memorialCandles?.length + 1}</span> {`${websiteReducer.websiteInfo.deceasedInfo.gender === "male" ? "לזכרו" : "לזכרה"}`}
            </h4>
          )}
          <form id="form_candle">
            <div className="popup_candle_input_main_box">
              <div className="popup_candle_input_box">
                <label htmlFor="name">שם מלא</label>
                <input type="text" name="name" id="name" value={name} placeholder="שם מלא" onChange={handleInputChanged} />
              </div>
            </div>
            <p className="error_msg">{errorMessage}</p>
            <div className="popup_candle_button_box">
              <button onClick={handleLightCandleClicked}>הדלקת נר</button>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state, ownProps) => ({
  websiteReducer: state.websiteReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    websiteActions: bindActionCreators(websiteActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LightCandleDialogComponent);
