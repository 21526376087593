import React, { useEffect, useState } from "react";

const SUBSCRIPTION_OPTIONS = {
  Normal: "Normal",
  Advance: "Advance",
  Premium: "Premium",
};

export const CreateWebsiteSubscriptionLevelComponent = ({
  handleInputChange,
  ...props
}) => {
  return (
    <div className="form_step_box">
      <div className="form_input_main_box">
        <div className="form_input_box">
          <label htmlFor="websiteLicenseType"> מנוי אתר</label>
          <select
            name="websiteLicenseType"
            id="websiteLicenseType"
            required
            onChange={handleInputChange}
          >
            <option value="">בחר סוג מנוי אתר</option>
            <option value="Basic">BASIC</option>
            <option value="Advance">ADVANCE</option>
            <option value="Premium">PREMIUM</option>
          </select>
        </div>

        <div className="form_input_main_box">
          <div className="form_input_box">
            <label htmlFor="copunCode">קוד קופון</label>
            <input
              type="text"
              name="copunCode"
              id="copunCode"
              required
              placeholder="הכנס קוד קופון"
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateWebsiteSubscriptionLevelComponent;
