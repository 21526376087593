import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import websiteActions from "../../../store/actions/website.action";
import paymentActions from "../../../store/actions/payment.action";
import CreateMemorialWebsiteLiveShowComponent from "../../../component/dashboard/createMemotialWebsite/CreateMemorialWebsiteLiveShow";
import CreateMemorialWebsiteProcessFormComponent from "../../../component/dashboard/createMemotialWebsite/CreateMemorialWebsiteProcessForm";
import { ALERT_ICON, alertService } from "../../../services/general/alert.service";
import LoaderComponent from "../../../component/LoaderComponent";
import { websiteErrorEnum } from "../../../enums/errorsEnums/websiteErrorEnum";
import { deceasedInfoErrorEnum } from "../../../enums/errorsEnums/deceasedInfoErrorEnum";

export const createWebsiteLevels = {
  route: 1,
  deceasedInfo: 2,
  storyInfo: 3,
  homePageGallery: 4,
  websiteSubscription: 5,
  deceasedGallery: 6,
  graveInfo: 7,
  playlistSongs: 8,
};

export const CreateMemorialWebsitePage = ({ loggedInUser, isMobileScreen, websiteActions, websiteReducer, paymentActions, paymentReducer, userReducer, setShowHeader, setShowFooter, ...props }) => {
  const navigate = useNavigate();
  const { websiteId } = useParams();
  const location = useLocation();

  const [createWebsiteLevel, setCreateWebsiteLevel] = useState(createWebsiteLevels.route);
  const [websiteInfo, setWebsiteInfo] = useState(defaultWebsiteInfo);
  const [actionType, setActionType] = useState();
  const [isUpdateAction, setIsUpdateAction] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [playlistSongInfo, setPlaylistSongInfo] = useState(defaultPlaylistSong);

  useEffect(() => {
    if (!loggedInUser) {
      navigate("/");
    }
  }, [loggedInUser]);

  useEffect(() => {
    resetErroMessage();
  }, [createWebsiteLevel]);

  useEffect(() => {
    const websiteDetails = websiteReducer.websiteListInfo.find((website) => website.id === websiteId);
    if (websiteDetails && websiteDetails.baseRoute) {
      websiteActions.setWebsiteInfo(websiteDetails);
      setCreateWebsiteLevel(createWebsiteLevels.deceasedInfo);
    }
  }, [websiteId]);

  useEffect(() => {
    setShowFooter(false);
    setShowHeader(false);

    const { pathname } = location;
    if (pathname.includes("/update/") && websiteId) {
      setIsUpdateAction(true);
    }
    return () => {
      setShowFooter(true);
      setShowHeader(true);
      setWebsiteInfo(null);
      // websiteActions.setWebsiteInfo(null);
    };
  }, []);

  useEffect(() => {
    if (!websiteReducer.stateInfo.isLoading && websiteReducer.stateInfo.isSuccess) {
      handleActionSuccess();
    }

    if (!websiteReducer.stateInfo.isLoading && websiteReducer.stateInfo.isError) {
      handleActionFailed(websiteReducer.stateInfo.error);
    }
  }, [websiteReducer.stateInfo]);

  useEffect(() => {
    handleWebsiteInfoChange(websiteReducer.websiteInfo);
  }, [websiteReducer.websiteInfo]);

  const handleWebsiteInfoChange = (websiteInformation) => {
    if (websiteInformation) {
      let websiteInfoDetails = { ...websiteInformation };

      const { deceasedInfo } = websiteInfoDetails;

      if (!deceasedInfo) {
        websiteInfoDetails = {
          ...websiteInfoDetails,
          deceasedInfo: defaultWebsiteInfo.deceasedInfo,
        };
      }
      const { deceasedStory } = websiteInfoDetails.deceasedInfo;
      if (!deceasedStory) {
        websiteInfoDetails = {
          ...websiteInfoDetails,
          deceasedInfo: {
            ...websiteInfoDetails.deceasedInfo,
            deceasedStory: defaultWebsiteInfo.deceasedInfo.deceasedStory,
          },
        };
      }
      setWebsiteInfo(websiteInfoDetails);
    }
  };
  // ************ handle seccess/fail methods ************

  const handleActionSuccess = () => {
    switch (actionType) {
      case "registerWebsite":
        setCreateWebsiteLevel(createWebsiteLevels.deceasedInfo);
        break;
      case "addOrUpdateDeceasedInfo":
        setCreateWebsiteLevel(createWebsiteLevels.storyInfo);
        break;
      case "addOrUpdateDeceasedStory":
        setCreateWebsiteLevel(createWebsiteLevels.homePageGallery);
        break;
      case "addOrUpdateHomePageGallery":
        navigate("/dashboard");
        break;
      case "registerWebsiteSubscription":
        handleRegisterWebsiteSubscriptionSeccess();
        break;
      case "addPlaylistSong":
        setPlaylistSongInfo(defaultPlaylistSong);
        break;
      case "addOrUpdateBurialLocation":
        alertService.showMessage(ALERT_ICON.success, "פרטי קבורה עודכנו בהצלחה");
        break;
      case "addDeceasedGalleryImages":
        alertService.showMessage(ALERT_ICON.success, "תמונות נוספו בהצלחה");
        break;
      default:
        break;
    }

    setActionType(null);
  };

  const handleRegisterWebsiteSubscriptionSeccess = () => {
    if (websiteReducer.websiteInfo.websiteLicensePayment && websiteReducer.websiteInfo.websiteLicensePayment.url) {
      window.location.href = websiteReducer.websiteInfo.websiteLicensePayment.url;
    }
  };

  const handleActionFailed = (error) => {
    switch (error) {
      case websiteErrorEnum.GeneralFailedRegisterMemorialWebsite:
        setErrorMessage("שגיאה ביצירת אתר הנצחה");
        break;
      case websiteErrorEnum.WebsiteRouteAlreadyExsits:
        setErrorMessage("כתובת אתר הנצחה כבר קיימת");
        break;
      case websiteErrorEnum.GeneralFailedRegisterMemorialWebsite:
        setErrorMessage("שגיאה ביצירת/עדכון אתר הנצחה");
        break;
      // *** Deceased Info ***
      case deceasedInfoErrorEnum.GeneralFailedAddOrUpdateDeceasedInfo:
        setErrorMessage("שגיאה בשמירת פרטי הנפטר");
        break;
      case deceasedInfoErrorEnum.FailedSaveDeceasedMainImage:
        setErrorMessage("שגיאה בשמירת תמונה ראשית");
        break;
      // *** Deceased Info ***
      case deceasedInfoErrorEnum.FailedAddOrUpdateDeceasedStory:
        setErrorMessage("שגיאה בשמירת סיפור חיים");
        break;
      case deceasedInfoErrorEnum.FailedSaveDeceasedStoryImage:
        setErrorMessage("שגיאה בשמירת תמונת סיפור חיים");
        break;
      // *** Deceased Files ***
      case deceasedInfoErrorEnum.FailedSaveDeceasedFiles:
        setErrorMessage("שגיאה בשמירת תמונת סיפור חיים");
        break;
      default:
        setErrorMessage("שגיאה בשמירת תמונות");
        break;
    }
  };

  // ************ ************ ************

  // ************ handle submit methods ************

  const handleSubmit = (event) => {
    event.preventDefault();

    switch (createWebsiteLevel) {
      case createWebsiteLevels.route:
        handleSubmitRouteInfo();
        break;
      case createWebsiteLevels.deceasedInfo:
        handleSubmitDeceasedInfo();
        break;
      case createWebsiteLevels.storyInfo:
        handleSubmitDeceasedStory();
        break;
      case createWebsiteLevels.homePageGallery:
        handleSubmitHomeGallery();
        break;
      case createWebsiteLevels.websiteSubscription:
        handleSubmitWebsiteSubscriptionInfo();
        break;
      case createWebsiteLevels.deceasedGallery:
        handleSubmitDeceasedGallery();
        break;
      case createWebsiteLevels.graveInfo:
        handleSubmitGraveInfo();
        break;
      case createWebsiteLevels.playlistSongs:
        handleSubmitPlaylistSong();
        break;
      default:
        break;
    }
  };

  const handleSubmitRouteInfo = () => {
    const errorMessage = validateSubmitRouteInfo();
    if (!errorMessage) {
      resetErroMessage();
      const request = {
        managerUserId: loggedInUser?.id,
        baseRoute: websiteInfo.baseRoute,
      };
      setActionType("registerWebsite");
      websiteActions.registerWebsite(request);
    } else {
      setErrorMessage(errorMessage);
    }
  };

  const handleSubmitDeceasedInfo = () => {
    const { deceasedInfo } = websiteInfo;
    const errorMessage = validateSubmitDeceasedInfo(deceasedInfo);

    if (!errorMessage) {
      resetErroMessage();
      const formData = new FormData();
      formData.append("Id", deceasedInfo.id);
      formData.append("FirstName", deceasedInfo.firstName);
      formData.append("LastName", deceasedInfo.lastName);
      formData.append("DeceasedId", deceasedInfo.deceasedId);
      formData.append("Birth", deceasedInfo.birth);
      formData.append("DeathDate", deceasedInfo.deathDate);
      formData.append("Gender", deceasedInfo.gender);
      formData.append("WebsiteId", websiteInfo.id);

      if (deceasedInfo.mainImage && deceasedInfo.mainImage.file) {
        formData.append("File", deceasedInfo.mainImage.file);
      }

      setActionType("addOrUpdateDeceasedInfo");
      websiteActions.addOrUpdateDeceasedInfo(formData);
    } else {
      setErrorMessage(errorMessage);
    }
  };

  const handleSubmitDeceasedStory = () => {
    const { deceasedStory } = websiteInfo.deceasedInfo;
    const errorMessage = validateSubmitDeceasedStory(deceasedStory);

    if (!errorMessage) {
      resetErroMessage();
      const formData = new FormData();
      formData.append("Id", deceasedStory.id);
      formData.append("Title", deceasedStory.title);
      formData.append("SubTitle", deceasedStory.subTitle);
      formData.append("Content", deceasedStory.content);
      formData.append("DeceasedInfoId", websiteInfo.deceasedInfo.id);
      formData.append("WebsiteId", websiteInfo.id);

      if (deceasedStory.storyImage && deceasedStory.storyImage.file) {
        formData.append("File", deceasedStory.storyImage.file);
      }
      setActionType("addOrUpdateDeceasedStory");
      websiteActions.addOrUpdateDeceasedStory(formData, websiteInfo.id);
    } else {
      setErrorMessage(errorMessage);
    }
  };

  const handleSubmitHomeGallery = async () => {
    const errorMessage = validateSubmitHomeGallery(websiteInfo.deceasedInfo.homeGalleryImages);

    if (!errorMessage) {
      if (!isUpdateAction && websiteInfo.deceasedInfo.homeGalleryImages.length === 0) {
        const userChoice = await alertService.confirmDialog(ALERT_ICON.question, "לא נבחרו תמונות לגלרית מסך הבית", "האם ברצונך להמשיך?", "כן", "לא");
        if (userChoice) {
          navigate("/dashboard");
          return;
        } else {
          return;
        }
      }

      const formData = new FormData();
      formData.append("DeceasedInfoId", websiteInfo.deceasedInfo.id);
      formData.append("WebsiteId", websiteInfo.id);
      formData.append("Category", "HomeGalleryImage");
      websiteInfo.deceasedInfo.homeGalleryImages.forEach((image, index) => {
        if (image.file && image.file instanceof File) {
          formData.append(`DeceasedFilesDetails[${index}].File`, image.file);
        }
        formData.append(`DeceasedFilesDetails[${index}].Type`, image.type);
        formData.append(`DeceasedFilesDetails[${index}].Position`, image.position);
        formData.append(`DeceasedFilesDetails[${index}].Category`, image.category);
        formData.append(`DeceasedFilesDetails[${index}].DeceasedInfoId`, image.deceasedInfoId);
      });

      setActionType("addOrUpdateHomePageGallery");
      websiteActions.addDeceasedFiles(formData, websiteInfo.id);
    } else {
      setErrorMessage(errorMessage);
    }
  };

  const handleSubmitDeceasedGallery = () => {
    const errorMessage = validateSubmitGallery(websiteInfo.deceasedInfo.galleryImages);

    if (!errorMessage) {
      const formData = new FormData();
      formData.append("DeceasedInfoId", websiteInfo.deceasedInfo.id);
      formData.append("Category", "Gallery");
      formData.append("WebsiteId", websiteInfo.id);
      websiteInfo.deceasedInfo.galleryImages.forEach((image, index) => {
        if (image.file && image.file instanceof File) {
          formData.append(`DeceasedFilesDetails[${index}].File`, image.file);
        }
        formData.append(`DeceasedFilesDetails[${index}].Type`, image.type);
        formData.append(`DeceasedFilesDetails[${index}].Position`, image.position);
        formData.append(`DeceasedFilesDetails[${index}].Category`, image.category);
        formData.append(`DeceasedFilesDetails[${index}].DeceasedInfoId`, image.deceasedInfoId);
      });

      setActionType("addDeceasedGalleryImages");
      websiteActions.addDeceasedFiles(formData, websiteInfo.id);
    } else {
      setErrorMessage(errorMessage);
    }
  };

  const handleSubmitWebsiteSubscriptionInfo = () => {
    const request = {
      subscriptionType: websiteInfo.websiteLicenseType,
      websiteId: websiteInfo.id,
      userId: userReducer.userInfo.id,
      copunCode: websiteInfo.copunCode,
    };
    setActionType("registerWebsiteSubscription");
    websiteActions.registerWebsiteSubscription(request);
  };

  const handleSubmitGraveInfo = () => {
    const { deceasedInfo } = websiteInfo;

    const formData = new FormData();
    formData.append("DeceasedInfoId", deceasedInfo.id);
    formData.append("WebsiteId", websiteInfo.id);
    formData.append("BurialLocation", JSON.stringify(deceasedInfo.burialLocation, null, 2));

    if (deceasedInfo.graveImage && deceasedInfo.graveImage.file) {
      formData.append("File", deceasedInfo.graveImage.file);
    }

    setActionType("addOrUpdateBurialLocation");
    websiteActions.addOrUpdateBurialLocation(formData, websiteInfo.id);
  };

  const handleSubmitPlaylistSong = () => {
    const request = { ...playlistSongInfo, websiteId: websiteInfo.id };
    setActionType("addPlaylistSong");
    websiteActions.addPlaylistSong(request);
  };

  // ************ ************ ************

  // ************ handle change methods ************

  const validateSubmitRouteInfo = () => {
    let errorMessage = "";
    if (!websiteInfo?.baseRoute) {
      errorMessage = "יש להזין כתובת לאתר ההנצחה";
    } else if (!validateRouteString(websiteInfo?.baseRoute)) {
      errorMessage = "יש להזין אותיות באנגלית, מספרים ואת התו '-' בלבד";
    }
    return errorMessage;
  };

  const validateRouteString = (value) => {
    if (value) {
      const regex = /^[A-Za-z0-9-]+$/;
      return regex.test(value);
    }
    return false;
  };

  const validateSubmitDeceasedInfo = (deceasedInfo) => {
    let errorMessage = "";
    if (!deceasedInfo.firstName) errorMessage = "יש להזין שם פרטי";
    else if (!deceasedInfo.lastName) errorMessage = "יש להזין שם משפחה";
    // else if (!deceasedInfo.deceasedId) errorMessage = "יש להזין תעודת זהות";
    else if (!deceasedInfo.birth) errorMessage = "יש להזין תאריך לידה";
    else if (!deceasedInfo.deathDate) errorMessage = "יש להזין תאריך פטירה";
    else if (!deceasedInfo.gender) errorMessage = "יש לבחור מגדר";
    return errorMessage;
  };

  const validateSubmitDeceasedStory = (deceasedStory) => {
    let errorMessage = "";
    if (!deceasedStory.content) errorMessage = "יש להזין סיפור חיים";
    return errorMessage;
  };

  const validateSubmitGallery = (imagesList) => {
    let errorMessage = "";
    if (!imagesList || imagesList.length === 0) errorMessage = "לא נבחרו תמונות ";
    return errorMessage;
  };

  const validateSubmitHomeGallery = (imagesList) => {
    let errorMessage = "";
    if (imagesList && imagesList.length > 10) errorMessage = "ניתן לבחור עד 10 תמונות בלבד";
    return errorMessage;
  };

  // ************ ************ ************

  // ************ handle change methods ************

  const handleInputChange = async (event) => {
    const { name, value, files } = event.target;

    switch (createWebsiteLevel) {
      case createWebsiteLevels.route:
        setWebsiteInfo({ ...websiteInfo, [name]: value });
        break;
      case createWebsiteLevels.deceasedInfo:
        await handleDeceasedInfoChange(event);
        break;
      case createWebsiteLevels.storyInfo:
        await handleDeceasedStoryInfoChange(event);
        break;
      case createWebsiteLevels.homePageGallery:
        await handleHomeGalleryChange(event);
        break;
      case createWebsiteLevels.websiteSubscription:
        setWebsiteInfo({ ...websiteInfo, [name]: value });
        break;
      case createWebsiteLevels.deceasedGallery:
        await handleDeceasedGalleryChange(event);
        break;
      case createWebsiteLevels.graveInfo:
        await handleGraveInfoChange(event);
        break;
      case createWebsiteLevels.playlistSongs:
        setPlaylistSongInfo({ ...playlistSongInfo, [name]: value });
        break;
      default:
        break;
    }

    if (createWebsiteLevel === createWebsiteLevels.route) {
    } else if (createWebsiteLevel === createWebsiteLevels.deceasedInfo) {
    }
  };

  const handleDeceasedInfoChange = async (event) => {
    const { name, value, files } = event.target;

    if (name === "mainImage" && files) {
      const fileDetails = await getFileDetails(files[0]);
      if (fileDetails) {
        setWebsiteInfo({
          ...websiteInfo,
          deceasedInfo: {
            ...websiteInfo.deceasedInfo,
            mainImage: {
              url: fileDetails.url,
              file: fileDetails.file,
            },
          },
        });
      }
    } else {
      setWebsiteInfo({
        ...websiteInfo,
        deceasedInfo: { ...websiteInfo.deceasedInfo, [name]: value },
      });
    }
  };

  const handleDeceasedStoryInfoChange = async (event) => {
    const { name, value, files } = event.target;
    if (name === "storyImage" && files) {
      const fileDetails = await getFileDetails(files[0]);
      if (fileDetails) {
        setWebsiteInfo({
          ...websiteInfo,
          deceasedInfo: {
            ...websiteInfo.deceasedInfo,
            deceasedStory: {
              ...websiteInfo.deceasedInfo.deceasedStory,
              storyImage: {
                url: fileDetails.url,
                file: fileDetails.file,
              },
            },
          },
        });
      }
    } else {
      setWebsiteInfo({
        ...websiteInfo,
        deceasedInfo: {
          ...websiteInfo.deceasedInfo,
          deceasedStory: {
            ...websiteInfo.deceasedInfo.deceasedStory,
            [name]: value,
          },
        },
      });
    }
  };

  const handleHomeGalleryChange = async (event) => {
    const { files } = event.target;

    if (files.length <= 10) {
      const homeGalleryImages = [];
      for (const file of Array.from(files)) {
        const fileDetails = await getFileDetails(file);
        fileDetails.position = "CENTER";
        fileDetails.type = "Image";
        fileDetails.category = "HomeGalleryImage";
        fileDetails.deceasedInfoId = websiteInfo.deceasedInfo.id;
        homeGalleryImages.push(fileDetails);
      }
      setWebsiteInfo({
        ...websiteInfo,
        deceasedInfo: {
          ...websiteInfo.deceasedInfo,
          homeGalleryImages: [...homeGalleryImages],
        },
      });
    } else {
      setErrorMessage("ניתן לבחור עד 10 תמונות בלבד");
    }
  };

  const handleDeceasedGalleryChange = async (event) => {
    const { files } = event.target;

    const deceasedGalleryImages = [];
    for (const file of Array.from(files)) {
      const fileDetails = await getFileDetails(file);
      fileDetails.position = "CENTER";
      fileDetails.type = file.type.startsWith("image/") ? "Image" : "Video";
      fileDetails.category = "Gallery";
      fileDetails.deceasedInfoId = websiteInfo.deceasedInfo.id;
      deceasedGalleryImages.push(fileDetails);
    }
    setWebsiteInfo({
      ...websiteInfo,
      deceasedInfo: {
        ...websiteInfo.deceasedInfo,
        galleryImages: [...deceasedGalleryImages],
      },
    });
  };

  const getFileDetails = (file) => {
    return new Promise((resolve, reject) => {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const imageUrl = e.target.result;
          resolve({ file, url: imageUrl, type: file.type });
        };
        reader.onerror = () => reject(reader.error);
        reader.readAsDataURL(file);
      } else {
        reject("No file provided");
      }
    });
  };

  const handleGraveInfoChange = async (event) => {
    const { name, value, files } = event.target;

    if (name === "graveImage" && files) {
      const fileDetails = await getFileDetails(files[0]);
      if (fileDetails) {
        setWebsiteInfo({
          ...websiteInfo,
          deceasedInfo: {
            ...websiteInfo.deceasedInfo,
            graveImage: {
              url: fileDetails.url,
              file: fileDetails.file,
            },
          },
        });
      }
    } else {
      setWebsiteInfo({
        ...websiteInfo,
        deceasedInfo: {
          ...websiteInfo.deceasedInfo,
          burialLocation: {
            ...websiteInfo.deceasedInfo.burialLocation,
            [name]: value,
          },
        },
      });
    }
  };

  const handleSetGraveLocation = (event) => {
    event.preventDefault();
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setWebsiteInfo({
          ...websiteInfo,
          deceasedInfo: {
            ...websiteInfo.deceasedInfo,
            burialLocation: {
              ...websiteInfo.deceasedInfo.burialLocation,
              latitude,
              longitude,
            },
          },
        });
      });
    }
  };
  const resetErroMessage = () => {
    setErrorMessage("");
  };

  if (websiteReducer.stateInfo.isLoading) {
    return <LoaderComponent isMobileScreen={isMobileScreen} />;
  } else {
    return (
      <div className="create-website-container">
        <CreateMemorialWebsiteProcessFormComponent loggedInUser={loggedInUser} createWebsiteLevel={createWebsiteLevel} handleInputChange={handleInputChange} handleSubmit={handleSubmit} errorMessage={errorMessage} websiteInfo={websiteInfo} handleSetGraveLocation={handleSetGraveLocation} playlistSongInfo={playlistSongInfo} />

        <CreateMemorialWebsiteLiveShowComponent websiteInfo={websiteInfo} createWebsiteLevel={createWebsiteLevel} setCreateWebsiteLevel={setCreateWebsiteLevel} isUpdateAction={isUpdateAction} />
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => ({
  websiteReducer: state.websiteReducer,
  paymentReducer: state.paymentReducer,
  userReducer: state.userReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    websiteActions: bindActionCreators(websiteActions, dispatch),
    paymentActions: bindActionCreators(paymentActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateMemorialWebsitePage);

const defaultWebsiteInfo = {
  baseRoute: "",
  deceasedInfo: {
    firstName: "", //"שם פרטי",
    lastName: "", // "שם משפחה",
    deceasedId: "", //"",
    birth: "", //"",
    deathDate: "", //"",
    gender: "", //"",
    burialLocation: {
      city: "", //'קרית גת',
      block: "", //'14',
      burialPlot: "", //'2',
      row: "", //'4',
      number: "", //'6',
      latitude: "", //'31.60651',
      longitude: "", //'34.78587'
    },
    deceasedStory: {
      title: "",
      subTitle: "",
      content: "",
      storyImage: {
        name: "",
        type: "",
        url: "",
        category: "",
        position: "",
        deceasedInfoId: "",
        id: "",
      },
      id: "",
    },
    mainImage: {
      name: "",
      type: "",
      url: "",
      category: "",
      position: "",
      deceasedInfoId: "",
      id: "",
    },
    graveImage: {
      name: "",
      type: "",
      url: "",
      category: "",
      position: "",
      deceasedInfoId: "",
      id: "",
    },
    galleryImages: [],
    homeGalleryImages: [],
    id: "",
  },
  websiteLicense: {
    subscriptionType: "",
    isActive: false,
    id: "",
    couponCode: "",
  },
  memorialCandles: [],
  sharedMemorials: [],
  playlistSongs: [],
  id: "",
};

const defaultPlaylistSong = {
  author: "",
  name: "",
  youtubeUrl: "",
};
