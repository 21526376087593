import React, { useEffect, useState } from "react";

export const CreateWebsiteStoryInfoLevelComponent = ({ handleInputChange, deceasedStory, ...props }) => {
  return (
    <div className="form_step_box">
      <div className="form_input_main_box">
        <div className="form_input_box">
          <label htmlFor="aboutus_image">תמונת סיפור חיים</label>
          <input type="file" name="storyImage" id="aboutus_image" required onChange={handleInputChange} />
        </div>
      </div>
      <div className="form_input_main_box">
        <div className="form_input_box">
          <input type="text" className="quote_line" name="title" id="quote_line" placeholder="כותרת ראשית" value={deceasedStory?.title} onChange={handleInputChange} />
        </div>
      </div>
      <div className="form_input_main_box">
        <div className="form_input_box">
          <textarea name="content" id="aboutdesc" placeholder="תיאור סיפור חיים" value={deceasedStory?.content} onChange={handleInputChange}></textarea>
        </div>
      </div>
      <div className="form_input_main_box">
        <div className="form_input_box">
          <input type="text" className="quote_line" name="subTitle" id="quote_line" placeholder="משפט סיכום" value={deceasedStory?.subTitle} onChange={handleInputChange} />
        </div>
      </div>
    </div>
  );
};

export default CreateWebsiteStoryInfoLevelComponent;
