import roundLogo from "../../../assets/Images/logo/round-logo-png.png";

export const SalesOurStoryComponent = ({ ...props }) => {
  return (
    <section id="ourStory" className="how_We_start_main_container">
      <div className="container">
        <div className="how_We_start_main_box">
          <div className="how_we_start_banner_image">
            <img src={roundLogo} alt="Logo" />
          </div>
          <div className="how_we_start_content_box">
            <h2 className="how_we_start_content_heading">את זיכרונות חיים הקמנו לאחר פטירת אבי</h2>
            <pre>המטרה הייתה ברורה, לתת מקום שאפשר להמשיך ולהנציח את אבי, מקום שיאפשר למכרים נוספים להיחשף לסיפור של אבי, ולנו, המשפחה להמשיך ולספר את סיפור חייו. כך הבנו שקיים צורך עצום במערכת שמצד אחד תשמר את זכרונות חייהם של אהובינו, ומהצד השני, תעניק לנו הרגשה שזכרונות חייהם חיים עדיין בתוכנו.</pre>
            <a href="/register" className="button_box">
              <button type="button">יצירת אתר הנצחה</button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SalesOurStoryComponent;
