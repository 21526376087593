import contactService from "../../services/contact.service";
import { contactActionTypes } from "../actionTypes/contactActionTypes";

export const submitContactDetails = (data) => async (dispatch) => {
  try {
    dispatch({ type: contactActionTypes.START_SUBMIT_CONTACT });
    const response = await contactService.submitContactDetails(data);

    dispatch({
      type: contactActionTypes.SUBMIT_CONTACT_SUCCESS,
      data: response.data.payload,
    });
  } catch (ex) {
    console.log("submitContactDetails error: " + ex.message);
    dispatch({ type: contactActionTypes.SUBMIT_CONTACT_FAILURE });
  }
};

const contactActions = {
  submitContactDetails,
};

export default contactActions;
