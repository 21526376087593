export const websiteErrorEnum = {
  GeneralFailedRegisterMemorialWebsite: 'GeneralFailedRegisterMemorialWebsite',
  WebsiteRouteAlreadyExsits: 'WebsiteRouteAlreadyExsits',
  WebsiteNotFound: 'WebsiteNotFound',
  WebsitesListNotFound: 'WebsitesListNotFound',
  // website subscription
  ExpiredDiscountCoupon: 'ExpiredDiscountCoupon',
  DismatchPlanCoupon: 'DismatchPlanCoupon',
  DiscountCouponNotFound: 'DiscountCouponNotFound',
  GeneralFailedRegisterMemorialWebsiteLicanse:
    'GeneralFailedRegisterMemorialWebsiteLicanse',

  // success message
  RegisterFreeMemorialWebsiteLicanseSuccessfully:
    'RegisterFreeMemorialWebsiteLicanseSuccessfully',
}
