import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import websiteActions from "../../store/actions/website.action";
import BannerImageComponent from "../../component/elements/BannerImageComponent";
import navigateIcon from "../../assets/Images/arrow-top-fill.svg";
import LoaderComponent from "../../component/LoaderComponent";

export const MemorialGraveInfoPage = ({
  websiteActions,
  websiteReducer,
  isMobileScreen,
  editableWebsiteInfo,
  ...props
}) => {
  const { baseRoute } = useParams();
  const [websiteInfo, setWebsiteInfo] = useState();
  const [graveInfo, setGraveInfo] = useState();

  // useEffect(() => {
  //   if (websiteReducer?.websiteListInfo?.length > 0) {
  //     const website = websiteReducer.websiteListInfo.find(
  //       (info) => info.baseRoute === baseRoute
  //     );
  //     setWebsiteInfo(website);
  //   } else {
  //     getWebsiteInfoByBaseRoute();
  //   }
  // }, [baseRoute]);

  useEffect(() => {
    if (!editableWebsiteInfo && websiteReducer?.websiteInfo) {
      setWebsiteInfo(websiteReducer.websiteInfo);
    }
  }, [websiteReducer.websiteInfo]);

  useEffect(() => {
    if (editableWebsiteInfo) {
      setWebsiteInfo(editableWebsiteInfo);
    }
  }, [editableWebsiteInfo]);

  useEffect(() => {
    if (websiteInfo && websiteInfo.deceasedInfo.burialLocation) {
      setGraveInfo(websiteInfo.deceasedInfo.burialLocation);
    }
  }, [websiteInfo]);

  // const getWebsiteInfoByBaseRoute = () => {
  //   websiteActions.getWebsiteDetailsByRoute(baseRoute);
  // };

  const handleNavigateButtonClicked = () => {
    if (graveInfo && graveInfo.latitude && graveInfo.longitude) {
      window.open(
        `https://www.waze.com/ul?ll=${graveInfo.latitude},${graveInfo.longitude}&navigate=yes`
      );
    }
  };

  return websiteInfo ? (
    <section className="burial_details_container">
      <div className="container">
        <div className="burial_details_box">
          <div className="burial_banner_content_box">
            <h2 className="burial_banner_content_heading">
              {" "}
              <span>פרטי</span> קבורה
            </h2>
            <div className="burial_list_item_box">
              <ul>
                <li>
                  <span>בית עלמין</span>: <span> {graveInfo?.city}</span>
                </li>
                <li>
                  <span>גוש</span>: <span>{graveInfo?.block}</span>
                </li>
                <li>
                  <span>חלקה</span>: <span>{graveInfo?.burialPlot}</span>
                </li>
                <li>
                  <span>שורה</span>: <span>{graveInfo?.row}</span>
                </li>
                <li>
                  <span>מספר</span>: <span>{graveInfo?.number}</span>
                </li>
              </ul>
            </div>
            <a
              className="cta_btn_burial_details"
              onClick={handleNavigateButtonClicked}
            >
              <img src={navigateIcon} alt="" /> לנווט
            </a>
          </div>
          <BannerImageComponent
            url={websiteInfo.deceasedInfo.graveImage?.url}
          />
        </div>
      </div>
    </section>
  ) : (
    <LoaderComponent isMobileScreen={isMobileScreen} />
  );
};

const mapStateToProps = (state, ownProps) => ({
  websiteReducer: state.websiteReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    websiteActions: bindActionCreators(websiteActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemorialGraveInfoPage);
