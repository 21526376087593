import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import userActions from "../store/actions/user.actions";
import loginBanner from "../assets/Images/login_banner.jpeg";
import { userErrorEnum } from "../enums/errorsEnums/userErrorEnum";
import utilService from "../services/general/util.service";

const DEFAULT_USER = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  password: "",
  confirmPassword: "",
};

export const LoginPage = ({ userActions, userReducer, loggedInUser, setShowFooter, setShowHeader, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isRegiterAction, setIsRegisterAction] = useState(true);
  const [userDetails, setUserDetails] = useState(DEFAULT_USER);
  const [actionType, setActionType] = useState();
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    if (loggedInUser && loggedInUser.userType === "WebsiteManager") {
      navigate("/dashboard");
    }

    setShowFooter(false);
    setShowHeader(false);

    return () => {
      setShowFooter(true);
      setShowHeader(true);
    };
  }, []);

  useEffect(() => {
    const { pathname } = location;
    if (pathname.includes("/login")) {
      setIsRegisterAction(false);
    } else if (pathname.includes("/register")) {
      setIsRegisterAction(true);
    }
  }, [location]);

  useEffect(() => {
    if (!userReducer.stateInfo.isLoading && userReducer.stateInfo.isSuccess) {
      handleSuccess();
    }

    if (!userReducer.stateInfo.isLoading && userReducer.stateInfo.isError) {
      if (actionType === "login" || actionType === "register") {
        handleFailed(userReducer.stateInfo.error);
      }
    }
  }, [userReducer.stateInfo]);

  const handleSuccess = () => {
    if (actionType === "login" || actionType === "register") {
      setActionType(null);
      navigate("/dashboard");
    }
  };

  const handleFailed = (error) => {
    switch (error) {
      case userErrorEnum.InccorectUserPassword:
        setErrorMessage("סיסמה לא נכונה");
        break;
      case userErrorEnum.UserNotFound:
        setErrorMessage("משתמש לא נמצא");
        break;
      case userErrorEnum.GeneralFailedLoginUser:
        setErrorMessage("שגיאה בהתחברות משתמש");
        break;
      case userErrorEnum.GeneralFailedRegisterUser:
        setErrorMessage("שגיאה ברישום משתמש");
        break;
      default:
        if (actionType === "login") setErrorMessage("שגיאה בהתחברות משתמש");
        else if (actionType === "register") setErrorMessage("שגיאה ברישום משתמש");
        break;
    }
  };

  const handleInputChanged = (event) => {
    const { name, value } = event.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleLoginClicked = (event) => {
    event.preventDefault();

    const errorMessage = validateLoginReisterInputs();
    if (!errorMessage) {
      setErrorMessage("");
      if (isRegiterAction) {
        setActionType("register");
        const request = { ...userDetails, confirmPassword: userDetails.password };
        userActions.registerUser(request);
      } else {
        userActions.login(userDetails);
        setActionType("login");
      }
    } else {
      setErrorMessage(errorMessage);
    }
  };

  const validateLoginReisterInputs = () => {
    let errorMessage = "";
    if (isRegiterAction) {
      if (!userDetails.firstName) {
        errorMessage = "יש להזין שם פרטי";
      } else if (!userDetails.lastName) {
        errorMessage = "יש להזין שם משפחה";
      } else if (!utilService.validateIsraeliCellularNumber(userDetails.phoneNumber)) {
        errorMessage = " יש להזין טלפון נייד תקין (מספרים בלבד)";
      } else if (!utilService.validateAspNetIdentityPassword(userDetails.password)) {
        errorMessage = "יש להזין סיסמה בהתאם להגבלות הרשומות";
      }
    }

    if (!userDetails.email || !utilService.ValidateEmail(userDetails.email)) {
      errorMessage = "יש להזין כתובת מייל תקינה";
    } else if (!userDetails.password) {
      errorMessage = "יש להזין סיסמה";
    }

    return errorMessage;
  };

  const handleChangeScreen = () => {
    navigate(isRegiterAction ? "/login" : "/register");
  };

  return (
    <section className="login_page_stystem_container" dir="rtl">
      <div className="container">
        <div className="login_page_stystem_box">
          <div className="login_page_stystem_main_box">
            <div className="login_regi_form_main_box">
              <a href="#" className="login_regi_logo">
                <img src="./assets/images/logo.png" alt="" />
              </a>
              <form id="" className="form__box">
                <h2>{isRegiterAction ? "הרשמה לחשבון" : "כניסה לחשבון"}</h2>

                {isRegiterAction && (
                  <div className="form_input_main_box">
                    <div className="form_input_box">
                      {/* <label htmlbutton="firstName">שם פרטי</label> */}
                      <input id="firstName" type="text" name="firstName" placeholder="שם פרטי" onChange={handleInputChanged} required />
                    </div>
                  </div>
                )}

                {isRegiterAction && (
                  <div className="form_input_main_box">
                    <div className="form_input_box">
                      {/* <label htmlbutton="lastName">שם משפחה</label> */}
                      <input id="lastName" type="text" name="lastName" placeholder="שם משפחה" onChange={handleInputChanged} required />
                    </div>
                  </div>
                )}

                {isRegiterAction && (
                  <div className="form_input_main_box">
                    <div className="form_input_box">
                      {/* <label htmlbutton="phoneNumber">טלפון נייד</label> */}
                      <input id="phoneNumber" type="text" name="phoneNumber" placeholder="טלפון נייד" onChange={handleInputChanged} required />
                    </div>
                  </div>
                )}

                <div className="form_input_main_box">
                  <div className="form_input_box">
                    {/* <label htmlbutton="email">דואר אלקטרוני</label> */}
                    <input id="email" type="email" name="email" placeholder="דואר אלקטרוני" onChange={handleInputChanged} required />
                  </div>
                </div>

                <div className="form_input_main_box">
                  <div className="form_input_box">
                    {/* <label htmlbutton="password">סיסמה</label> */}
                    <input id="password" type="password" name="password" placeholder="סיסמה" onChange={handleInputChanged} required />
                  </div>
                </div>

                <div className="password-validation-container">
                  <h4>הסיסמה חייבת להכיל:</h4>
                  <label><i className="fa-solid fa-circle-check"></i> לפחות 6 תווים</label>
                  <label><i className="fa-solid fa-circle-check"></i> לפחות אות אחת גדולה</label>
                  <label><i className="fa-solid fa-circle-check"></i> לפחות אות אחת קטנה</label>
                </div>

                <label>{errorMessage}</label>

                {/* <a href="#" className="forgot_password_cta">
                  שכחת את הסיסמא ?
                </a> */}
                <div className="login-action-container">
                  <div className="form_button">
                    <button type="submit" onClick={handleLoginClicked}>
                      {isRegiterAction ? "צור חשבון" : "התחבר"}
                    </button>
                  </div>

                  <div className="form_button">
                    <div className="call_login_pages_cta">
                      <button type="button" onClick={handleChangeScreen}>
                        {isRegiterAction ? "התחברות" : "הרשמה"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="banner_main_box">
              <img src={loginBanner} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state, ownProps) => ({
  userReducer: state.userReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
