import { useLayoutEffect, useRef, useState } from "react";
import utilService from "../../../services/general/util.service";
import BannerImageComponent from "../../elements/BannerImageComponent";

export const MemorialMainBannerComponent = ({ websiteInfo, ...props }) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);

  const { deceasedInfo } = websiteInfo;

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
  }, []);

  return (
    <section ref={ref} className="memorial-home-banner">
      <div className={`${"memorial-home-banner-container"} ${width <= 1500 ? "important-flex-column" : ""}`}>
        <BannerImageComponent url={deceasedInfo?.mainImage?.url} />
        <div className="banner-content-container">
          <h2 className="banner-main-heading">{deceasedInfo?.gender === "male" ? "לזכרו והנצחתו" : "לזכרה והנצחתה"} של</h2>
          <h2 className="banner-sub-heading">{`${deceasedInfo.firstName} ${deceasedInfo.lastName}`}</h2>
          <h3 className="banner-dates-heading">{`${utilService.getYearFromDate(deceasedInfo.deathDate)} -${utilService.getYearFromDate(deceasedInfo.birth)}`}</h3>
          <p className="banner-content">
            אתר זה משמש כמחווה לזכור {deceasedInfo.gender === "male" ? " את חייו ומורשתו" : " את חייה ומורשתה"} של {`${deceasedInfo.firstName} ${deceasedInfo.lastName}`}
            .
            <br />
            {`${deceasedInfo.firstName}`} {deceasedInfo.gender === "male" ? "נגע בחייהם של רבים בטוב ליבו, חוכמתו ואהבתו." : "נגעה בחייהם של רבים בטוב ליבה, חוכמתה ואהבתה."}
            <br />
            כאן אנו מתאספים לכבד ולזכור את {deceasedInfo.gender === "male" ? "מורשתו." : "מורשתה."}
          </p>
        </div>
      </div>
    </section>
  );
};

export default MemorialMainBannerComponent;

