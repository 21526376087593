import axios from "axios";

const enviorenment = 1; // dev = 0,  prod = 1, temp = 2

export const getServerBaseUrl = () => {
  switch (enviorenment) {
    case 0:
      return "https://localhost:5001";
    case 1:
      return "https://izcor.org";
    case 2:
      return "https://temp.izcor.org";
    default:
      return "https://localhost:5001";
  }
};

export const getClientBaseUrl = () => {
  switch (enviorenment) {
    case 0:
      return "http://localhost:3000";
    case 1:
      return "https://izcor.org";
    case 2:
      return "https://temp.izcor.org";
    default:
      return "http://localhost:3000";
  }
};

const http = axios.create({
  baseURL: `${getServerBaseUrl(0)}/api/`,
  timeout: 60000, // Optional: request timeout in milliseconds
});

// Add a request interceptor to include the JWT token in the headers
http.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
