import deceasedService from "../../services/deceased.service";
import managementService from "../../services/management.service";
import memorialCandleService from "../../services/memorialCandle.service";
import playlistService from "../../services/playlist.service";
import prayerService from "../../services/prayer.service";
import sharedMemorialService from "../../services/sharedMemorial.service";

import { websiteActionTypes } from "../actionTypes/websiteActionTypes";

export const resetWebsiteInfo = () => async (dispatch) => {
  try {
    dispatch({ type: websiteActionTypes.RESET_WEBSITE_INFO, data: null });
  } catch (ex) {}
};

export const setWebsiteInfo = (websiteInfo) => async (dispatch) => {
  try {
    dispatch({ type: websiteActionTypes.SET_WEBSITE_INFO, data: websiteInfo });
  } catch (ex) {}
};

export const registerWebsite = (data) => async (dispatch) => {
  try {
    dispatch({ type: websiteActionTypes.START_REGISTER_WEBSITE });

    const response = await managementService.registerWebsite(data);

    dispatch({
      type: websiteActionTypes.REGISTER_WEBSITE_SUCCESS,
      data: response.data.payload,
    });
  } catch (ex) {
    dispatch({
      type: websiteActionTypes.REGISTER_WEBSITE_FAILURE,
      data: ex.response.data,
    });
  }
};

export const registerWebsiteSubscription = (data) => async (dispatch) => {
  try {
    dispatch({ type: websiteActionTypes.START_REGISTER_WEBSITE_SUBSCRIPTION });

    const response = await managementService.registerWebsiteSubscription(data);
    dispatch({
      type: websiteActionTypes.REGISTER_WEBSITE_SUBSCRIPTION_SUCCESS,
      data: response.data,
    });
    dispatch(getWebsiteDetails(data.websiteId));
  } catch (ex) {
    console.log("registerWebsiteSubscription error: " + ex.message);
    dispatch({
      type: websiteActionTypes.REGISTER_WEBSITE_SUBSCRIPTION_FAILURE,
      data: ex.response.data,
    });
  }
};

export const registerFreeWebsiteSubscription = (data) => async (dispatch) => {
  try {
    dispatch({ type: websiteActionTypes.START_REGISTER_WEBSITE_SUBSCRIPTION });

    const response = await managementService.registerFreeWebsiteSubscription(data);
    dispatch({
      type: websiteActionTypes.REGISTER_WEBSITE_SUBSCRIPTION_SUCCESS,
      data: response.data,
    });
    dispatch(getManagerWebsiteList(data.userId));
  } catch (ex) {
    console.log("registerFreeWebsiteSubscription error: " + ex.message);
    dispatch({
      type: websiteActionTypes.REGISTER_WEBSITE_SUBSCRIPTION_FAILURE,
      data: ex.response.data,
    });
  }
};

export const registerWebsitePayment = (data) => async (dispatch) => {
  try {
    dispatch({ type: websiteActionTypes.START_REGISTER_WEBSITE_PAYMENT });

    const response = await managementService.registerWebsitePayment(data);

    dispatch({
      type: websiteActionTypes.REGISTER_WEBSITE_PAYMENT_SUCCESS,
      data: response.data.payload,
    });
  } catch (ex) {
    console.log("registerWebsitePayment error: " + ex.message);
    dispatch({
      type: websiteActionTypes.REGISTER_WEBSITE_PAYMENT_FAILURE,
      data: ex.response.data,
    });
  }
};

export const getWebsiteDetails = (data) => async (dispatch) => {
  try {
    dispatch({ type: websiteActionTypes.START_GET_WEBSITE });

    const response = await managementService.getWebsiteDetails(data);

    dispatch({
      type: websiteActionTypes.GET_WEBSITE_SUCCESS,
      data: response.data.payload,
    });
  } catch (ex) {
    console.log("getWebsiteDetails error: " + ex.message);
    dispatch({
      type: websiteActionTypes.GET_WEBSITE_FAILURE,
      data: ex.response.data,
    });
  }
};

export const getWebsiteDetailsByRoute = (data) => async (dispatch) => {
  let responseData;
  try {
    dispatch({ type: websiteActionTypes.START_GET_WEBSITE_BY_ROUTE });

    const response = await managementService.getWebsiteDetailsByRoute(data);
    responseData = response?.data?.payload;
    dispatch({
      type: websiteActionTypes.GET_WEBSITE_BY_ROUTE_SUCCESS,
      data: response?.data?.payload,
    });
  } catch (ex) {
    console.log("getWebsiteDetailsByRoute error: " + ex.message);
    dispatch({
      type: websiteActionTypes.GET_WEBSITE_BY_ROUTE_FAILURE,
      data: ex?.response?.data,
    });
  }

  return responseData;
};

export const getManagerWebsiteList = (data) => async (dispatch) => {
  try {
    dispatch({ type: websiteActionTypes.START_GET_MANAGER_WEBSITE_LIST });

    const response = await managementService.getManagerWebsiteList(data);
    dispatch({
      type: websiteActionTypes.GET_MANAGER_WEBSITE_LIST_SUCCESS,
      data: response.data.payload,
    });
  } catch (ex) {
    console.log("getManagerWebsiteList error: " + ex.message);
    dispatch({
      type: websiteActionTypes.GET_MANAGER_WEBSITE_LIST_FAILURE,
      data: ex.response?.data,
    });
  }
};

export const getWebsiteStorageSize = (data) => async (dispatch) => {
  try {
    dispatch({ type: websiteActionTypes.START_GET_WEBSITE_STORAGE });

    const response = await managementService.getWebsiteStorageSize(data);
    console.log("response", response);
    dispatch({
      type: websiteActionTypes.GET_WEBSITE_STORAGE_SUCCESS,
      data: response.data.payload,
    });
  } catch (ex) {
    console.log("getWebsiteStorageSize error: " + ex.message);
    dispatch({
      type: websiteActionTypes.GET_WEBSITE_STORAGE_FAILURE,
      data: ex.response.data,
    });
  }
};

// ******************* DeceasedInfo  *******************

export const addOrUpdateDeceasedInfo = (data) => async (dispatch) => {
  try {
    dispatch({ type: websiteActionTypes.START_ADD_OR_UPDATE_DECEASED_INFO });

    const response = await deceasedService.addOrUpdateDeceasedInfo(data);

    dispatch({
      type: websiteActionTypes.ADD_OR_UPDATE_DECEASED_INFO_SUCCESS,
      data: response.data.payload,
    });

    dispatch(getWebsiteDetails(data.get("WebsiteId")));
  } catch (ex) {
    console.log("addOrUpdateDeceasedInfo error: " + ex.message);
    dispatch({
      type: websiteActionTypes.ADD_OR_UPDATE_DECEASED_INFO_FAILURE,
      data: ex.response.data,
    });
  }
};

export const addDeceasedFile = (data, websiteId) => async (dispatch) => {
  try {
    dispatch({ type: websiteActionTypes.START_ADD_DECEASED_FILE });

    const response = await deceasedService.addDeceasedFile(data);

    dispatch({
      type: websiteActionTypes.ADD_DECEASED_FILE_SUCCESS,
      data: response.data.payload,
    });

    dispatch(getWebsiteDetails(websiteId));
  } catch (ex) {
    console.log("addDeceasedFile error: " + ex.message);
    dispatch({
      type: websiteActionTypes.ADD_DECEASED_FILE_FAILURE,
      data: ex.response.data,
    });
  }
};

export const addDeceasedFiles = (data, websiteId) => async (dispatch) => {
  try {
    dispatch({ type: websiteActionTypes.START_ADD_DECEASED_FILES });

    const response = await deceasedService.addDeceasedFiles(data);

    dispatch({
      type: websiteActionTypes.ADD_DECEASED_FILES_SUCCESS,
      data: response.data.payload,
    });

    dispatch(getWebsiteDetails(websiteId));
  } catch (ex) {
    console.log("addDeceasedFile error: " + ex.message);
    dispatch({
      type: websiteActionTypes.ADD_DECEASED_FILES_FAILURE,
      data: ex?.response?.data,
    });
  }
};

export const addOrUpdateDeceasedStory = (data, websiteId) => async (dispatch) => {
  try {
    dispatch({ type: websiteActionTypes.START_ADD_DECEASED_STORY });

    const response = await deceasedService.addOrUpdateDeceasedStory(data);

    dispatch({
      type: websiteActionTypes.ADD_DECEASED_STORY_SUCCESS,
      data: response.data.payload,
    });

    dispatch(getWebsiteDetails(websiteId));
  } catch (ex) {
    console.log("addOrUpdateDeceasedStory error: " + ex.message);
    dispatch({
      type: websiteActionTypes.ADD_DECEASED_STORY_FAILURE,
      data: ex.response.data,
    });
  }
};

export const addOrUpdateBurialLocation = (data, websiteId) => async (dispatch) => {
  try {
    dispatch({ type: websiteActionTypes.START_ADD_DECEASED_BURIAL_LOCATION });

    const response = await deceasedService.addOrUpdateBurialLocation(data);

    dispatch({
      type: websiteActionTypes.ADD_DECEASED_BURIAL_LOCATION_SUCCESS,
    });

    dispatch(getWebsiteDetails(websiteId));
  } catch (ex) {
    console.log("addOrUpdateBurialLocation error: " + ex.message);
    dispatch({
      type: websiteActionTypes.ADD_DECEASED_BURIAL_LOCATION_FAILURE,
      data: ex.response.data,
    });
  }
};

export const getDeceasedGalleryFiles = (data) => async (dispatch) => {
  try {
    dispatch({ type: websiteActionTypes.START_GET_DECEASED_GALLERY_FILES });

    const response = await deceasedService.getDeceasedGalleryFiles(data);

    dispatch({
      type: websiteActionTypes.GET_DECEASED_GALLERY_FILES_SUCCESS,
      data: response.data.payload,
    });
  } catch (ex) {
    console.log("getDeceasedGalleryFiles error: " + ex.message);
    dispatch({
      type: websiteActionTypes.GET_DECEASED_GALLERY_FILES_FAILURE,
      data: ex.response.data,
    });
  }
};

// ******************* *******************  *******************

// ******************* Memorial Candle  *******************
export const getMemorialCandles = (data) => async (dispatch) => {
  try {
    dispatch({ type: websiteActionTypes.START_GET_MEMORIAL_CANDLE });

    const response = await memorialCandleService.getMemorialCandles(data);

    dispatch({
      type: websiteActionTypes.GET_MEMORIAL_CANDLE_SUCCESS,
      data: response.data.payload,
    });
  } catch (ex) {
    console.log("getMemorialCandles error: " + ex.message);
    dispatch({
      type: websiteActionTypes.GET_MEMORIAL_CANDLE_FAILURE,
      data: ex.response.data,
    });
  }
};

export const addMemorialCandle = (data) => async (dispatch) => {
  try {
    dispatch({ type: websiteActionTypes.START_ADD_MEMORIAL_CANDLE });

    const response = await memorialCandleService.addMemorialCandle(data);

    dispatch({
      type: websiteActionTypes.ADD_MEMORIAL_CANDLE_SUCCESS,
      data: response.data.payload,
    });

    dispatch(getMemorialCandles(data.websiteId));
  } catch (ex) {
    console.log("addMemorialCandle error: " + ex.message);
    dispatch({
      type: websiteActionTypes.ADD_MEMORIAL_CANDLE_FAILURE,
      data: ex.response.data,
    });
  }
};
// ******************* *******************  *******************

// ******************* shared memorial  *******************
export const addSharedMemorial = (data) => async (dispatch) => {
  try {
    dispatch({ type: websiteActionTypes.START_ADD_SHARED_MEMORIAL });

    const response = await sharedMemorialService.addSharedMemorial(data);

    dispatch({
      type: websiteActionTypes.ADD_SHARED_MEMORIAL_SUCCESS,
      data: response.data.payload,
    });

    // dispatch(getPlaylistSongs(data.websiteId));
  } catch (ex) {
    console.log("addSharedMemorial error: " + ex.message);
    dispatch({
      type: websiteActionTypes.ADD_SHARED_MEMORIAL_FAILURE,
      data: ex.response.data,
    });
  }
};

export const approveSahredMemorial = (data, websiteId) => async (dispatch) => {
  try {
    dispatch({ type: websiteActionTypes.START_APPROVE_SHARED_MEMORIAL });

    const response = await sharedMemorialService.approveSahredMemorial(data);

    dispatch({
      type: websiteActionTypes.APPROVE_SHARED_MEMORIAL_SUCCESS,
      data: response.data.payload,
    });

    dispatch(getWebsiteSahredMemorials(websiteId));
  } catch (ex) {
    console.log("approveSahredMemorial error: " + ex.message);
    dispatch({
      type: websiteActionTypes.APPROVE_SHARED_MEMORIAL_FAILURE,
      data: ex.response.data,
    });
  }
};

export const getWebsiteSahredMemorials = (data) => async (dispatch) => {
  try {
    dispatch({ type: websiteActionTypes.START_GET_SHARED_MEMORIALS });

    const response = await sharedMemorialService.getWebsiteSahredMemorials(data);
    dispatch({
      type: websiteActionTypes.GET_SHARED_MEMORIALS_SUCCESS,
      data: response.data.payload,
    });
  } catch (ex) {
    console.log("approveSahredMemorial error: " + ex.message);
    dispatch({
      type: websiteActionTypes.GET_SHARED_MEMORIALS_FAILURE,
      data: ex.response.data,
    });
  }
};
// ******************* *******************  *******************

// ******************* Playlist  *******************
export const getPlaylistSongs = (data) => async (dispatch) => {
  try {
    dispatch({ type: websiteActionTypes.START_GET_PLAYLIST_SONGS });

    const response = await playlistService.getPlaylistSongs(data);

    dispatch({
      type: websiteActionTypes.GET_PLAYLIST_SONGS_SUCCESS,
      data: response.data.payload,
    });
  } catch (ex) {
    console.log("getPlaylistSongs error: " + ex.message);
    dispatch({
      type: websiteActionTypes.GET_PLAYLIST_SONGS_FAILURE,
      data: ex.response.data,
    });
  }
};

export const addPlaylistSong = (data) => async (dispatch) => {
  try {
    dispatch({ type: websiteActionTypes.START_ADD_PLAYLIST_SONG });

    const response = await playlistService.addPlaylistSong(data);

    dispatch({
      type: websiteActionTypes.ADD_PLAYLIST_SONG_SUCCESS,
      data: response.data.payload,
    });

    dispatch(getPlaylistSongs(data.websiteId));
  } catch (ex) {
    console.log("addPlaylistSong error: " + ex.message);
    dispatch({
      type: websiteActionTypes.ADD_PLAYLIST_SONG_FAILURE,
      data: ex.response.data,
    });
  }
};

export const deletePlaylistSong = (data, websiteId) => async (dispatch) => {
  try {
    dispatch({ type: websiteActionTypes.START_DELETE_PLAYLIST_SONG });

    const response = await playlistService.deletePlaylistSong(data);

    dispatch({
      type: websiteActionTypes.DELETE_PLAYLIST_SONG_SUCCESS,
      data: response.data.payload,
    });

    dispatch(getPlaylistSongs(websiteId));
  } catch (ex) {
    console.log("deletePlaylistSong error: " + ex.message);
    dispatch({
      type: websiteActionTypes.DELETE_PLAYLIST_SONG_FAILURE,
      data: ex.response.data,
    });
  }
};
// ******************* *******************  *******************

// ******************* Prayer  *******************
export const getDeceasedPrayer = (data) => async (dispatch) => {
  try {
    dispatch({ type: websiteActionTypes.START_GET_DECEASED_PRAYER });

    const response = await prayerService.getDeceasedPrayer(data);
    dispatch({
      type: websiteActionTypes.GET_DECEASED_PRAYER_SUCCESS,
      data: response.data.payload,
    });
  } catch (ex) {
    console.log("getDeceasedPrayer error: " + ex.message);
    dispatch({
      type: websiteActionTypes.GET_DECEASED_PRAYER_FAILURE,
      data: ex.response.data,
    });
  }
};
// ******************* *******************  *******************

// ******************* Deceased Files  *******************
export const deleteDeceasedFile = (data) => async (dispatch) => {
  let response;
  try {
    dispatch({ type: websiteActionTypes.START_DELETE_DECEASED_FILE });

    response = await deceasedService.deleteDeceasedFile(data);
    dispatch({ type: websiteActionTypes.DELETE_DECEASED_FILE_SUCCESS });
  } catch (ex) {
    console.log("deleteDeceasedFile error: " + ex.message);
    dispatch({
      type: websiteActionTypes.DELETE_DECEASED_FILE_FAILURE,
      data: ex.response.data,
    });
  }
  return response;
};
// ******************* *******************  *******************

export const updateWebsiteStorageSize = (data) => (dispatch) => {
  console.log("data", data);
  dispatch({ type: websiteActionTypes.UPDATE_WEBSITE_STORAGE_SIZE_SUCCESS, data: data });
};

const websiteActions = {
  resetWebsiteInfo,
  setWebsiteInfo,

  registerWebsite,
  registerWebsiteSubscription,
  registerFreeWebsiteSubscription,
  registerWebsitePayment,
  getWebsiteDetails,
  getManagerWebsiteList,
  getWebsiteDetailsByRoute,
  getWebsiteStorageSize,

  addOrUpdateDeceasedInfo,
  addDeceasedFile,
  addDeceasedFiles,
  addOrUpdateDeceasedStory,
  addOrUpdateBurialLocation,
  getDeceasedGalleryFiles,

  // memorial candle
  addMemorialCandle,
  getMemorialCandles,

  // shared memorial
  addSharedMemorial,
  approveSahredMemorial,
  getWebsiteSahredMemorials,

  // playlist
  addPlaylistSong,
  deletePlaylistSong,
  getPlaylistSongs,

  // prayer
  getDeceasedPrayer,

  // deceased files
  deleteDeceasedFile,

  updateWebsiteStorageSize,
};

export default websiteActions;
