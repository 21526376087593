import { Route, Routes } from "react-router-dom";
import MemorialHomePage from "./pages/memorialWebsite/MemorialHomePage";
import MemorialGalleryPage from "./pages/memorialWebsite/MemorialGalleryPage";
import MemorialGraveInfoPage from "./pages/memorialWebsite/MemorialGraveInfoPage";
import MemorialPlaylistPage from "./pages/memorialWebsite/MemorialPlaylistPage";
import CreateMemorialWebsitePage from "./pages/dashboard/createMemotialWebsite/CreateMemorialWebsitePage";
import MemorialCandlePage from "./pages/memorialWebsite/MemorialCandlePage";
import MemorialPrayerPage from "./pages/memorialWebsite/MemorialPrayerPage";
import SharedMemorialPage from "./pages/memorialWebsite/MemorialSharedMemorialPage";
import ChargeAndCreateTokenSuccessPage from "./pages/payment/ChargeAndCreateTokenSuccessPage";
import DashboardPage from "./pages/dashboard/DashboardPage";
import LoginPage from "./pages/LoginPage";
import SalesHomePage from "./pages/salesWebsite/SalesHomePage";
import TermsAndPrivacyPage from "./pages/TermsAndPrivacyPage";
import AccessibilityStatementPage from "./pages/AccessibilityStatementPage";
import ArticlePage from "./pages/articles/ArticlePage";
import ArticlesListPage from "./pages/articles/ArticlesListPage";

const AppRoutes = ({ loggedInUser, isMobileScreen, setShowFooter, setShowHeader, ...props }) => {
  return (
    <Routes>
      {/* ******** Memorial Website Routes ******** */}
      <Route path="/memorial/:baseRoute" element={<MemorialHomePage loggedInUser={loggedInUser} isMobileScreen={isMobileScreen} />} />
      <Route path="/memorial/:baseRoute/memorialCandles" element={<MemorialCandlePage />} />
      <Route path="/memorial/:baseRoute/gallery" element={<MemorialGalleryPage isMobileScreen={isMobileScreen} />} />
      <Route path="/memorial/:baseRoute/graveInfo" element={<MemorialGraveInfoPage />} />
      <Route path="/memorial/:baseRoute/Prayer" element={<MemorialPrayerPage />} />
      <Route path="/memorial/:baseRoute/Playlist" element={<MemorialPlaylistPage />} />
      <Route path="/memorial/:baseRoute/SharedMemorial" element={<SharedMemorialPage />} />
      <Route path="/memorial/:baseRoute/SharedMemorial/Recipes" element={<SharedMemorialPage />} />
      {/* ********************* */}
      <Route path="/ChargeAndCreateTokenSuccessfully" element={<ChargeAndCreateTokenSuccessPage loggedInUser={loggedInUser} />} />
      <Route path="/dashboard" exact element={<DashboardPage loggedInUser={loggedInUser} setShowFooter={setShowFooter} setShowHeader={setShowHeader} />} />
      <Route path="/dashboard/update/:websiteId" exact element={<CreateMemorialWebsitePage loggedInUser={loggedInUser} setShowFooter={setShowFooter} setShowHeader={setShowHeader} isMobileScreen={isMobileScreen} />} />
      <Route path="/dashboard/create" exact element={<CreateMemorialWebsitePage loggedInUser={loggedInUser} setShowFooter={setShowFooter} setShowHeader={setShowHeader} />} />
      <Route path="/login" exact element={<LoginPage loggedInUser={loggedInUser} setShowFooter={setShowFooter} setShowHeader={setShowHeader} />} />
      <Route path="/register" exact element={<LoginPage loggedInUser={loggedInUser} setShowFooter={setShowFooter} setShowHeader={setShowHeader} />} />
      <Route path="/termsAndPrivacy" exact element={<TermsAndPrivacyPage />} />
      <Route path="/accessibilityStatement" exact element={<AccessibilityStatementPage />} />
      <Route path="/articles/:articleName" element={<ArticlePage />} isMobileScreen={isMobileScreen} />
      <Route path="/articles/" element={<ArticlesListPage />} isMobileScreen={isMobileScreen} />
      <Route path="/" element={<SalesHomePage />} />
    </Routes>
  );
};

export default AppRoutes;
