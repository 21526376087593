import { useEffect, useLayoutEffect, useState } from "react";
import MemorialCandleCardComponent from "../MemorialCandleCard";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from "swiper/core";
import MemorialHomePgaeModuleHeaderComponent from "./MemorialHomePgaeModuleHeader";
SwiperCore.use([Pagination, Autoplay]);

export const MemorialHomeCandleGalleryComponent = ({
  websiteInfo,
  isMobileScreen,
  ...props
}) => {
  const [memorialCandleList, setMemorialCandleList] = useState([]);

  useEffect(() => {
    setMemorialCandleList(websiteInfo.memorialCandles);
  }, [websiteInfo.memorialCandles]);

  return (
    <div className="candles-home-gallery-container">
      
      <MemorialHomePgaeModuleHeaderComponent
            baseRoute={websiteInfo.baseRoute}
            title="נרות"
            boldTitle={websiteInfo.deceasedInfo.gender === "male" ? "לזכרו" : "לזכרה"}
            route="memorialCandles"
            linkTitle="כל הנרות"
          />

      <div className="candles-home-gallery-list-container">
        
        <Swiper
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            "@1.00": {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            "@1.50": {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
          className="mySwiper"
        >
          {memorialCandleList?.map((memorialCandleInfo, index) => (
            <SwiperSlide key={index}>
              <MemorialCandleCardComponent
                memorialCandleInfo={memorialCandleInfo}
                gender={websiteInfo.deceasedInfo.gender}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        
      </div>
    </div>
  );
};

export default MemorialHomeCandleGalleryComponent;
