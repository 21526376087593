import Swal from "sweetalert2";

const showMessage = (icon, message) => {
  Swal.fire({
    icon,
    text: message,
  });
};

const confirmDialog = async (icon, title = "", text = "", confirmButtonText = "", denyButtonText = "") => {
  let userChoice = false;

  await Swal.fire({
    icon,
    title,
    text,
    showDenyButton: false,
    showCancelButton: true,
    confirmButtonText: confirmButtonText ? confirmButtonText : "אשר",
    cancelButtonText: denyButtonText ? denyButtonText : "בטל",
  }).then((result) => {
    if (result.isConfirmed) {
      userChoice = true;
    } else {
      userChoice = false;
    }
  });

  return userChoice;
};

const showHtmlPopup = (html) => {
  Swal.fire({
    // title: "<strong>HTML <u>example</u></strong>",
    icon: "info",
    html,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    // confirmButtonText: `
    //   <i className="fa fa-thumbs-up"></i> Great!
    // `,
    // confirmButtonAriaLabel: "Thumbs up, great!",
    // cancelButtonText: `
    //   <i className="fa fa-thumbs-down"></i>
    // `,
    // cancelButtonAriaLabel: "Thumbs down",
  });
};

export const alertService = {
  showMessage,
  confirmDialog,
  showHtmlPopup
};

export const ALERT_ICON = {
  success: "success",
  error: "error",
  warning: "warning",
  info: "info",
  question: "question",
};
