import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import websiteActions from "../../store/actions/website.action";
import { getClientBaseUrl } from "../../services/general/axios.service";
import { useNavigate } from "react-router-dom";

const ChargeAndCreateTokenSuccessPage = ({ paymentReducer, websiteActions, websiteReducer }) => {
  const [actionType, setActionType] = useState();
  const navigation = useNavigate();

  useEffect(() => {
    getQueryParams();
  }, []);

  useEffect(() => {
    if (actionType === "registerWebsitePayment") {
      if (!websiteReducer.stateInfo.isLoading && websiteReducer.stateInfo.isSuccess) {
        const { websiteInfo } = websiteReducer;
        if (websiteInfo && websiteInfo.baseRoute) {
          const newTab = window.open(`${getClientBaseUrl()}/memorial/${websiteInfo?.baseRoute}`, "_blank");

          // Close the current window after a delay (e.g., 2 seconds)
          setTimeout(() => {
            window.close();
          }, 2000);
        }
      }
    }
  }, [websiteReducer.stateInfo]);

  const getQueryParams = () => {
    const queryParams = new URLSearchParams(window.location.search);

    // Access individual query parameters
    const terminalNumber = queryParams.get("terminalnumber");
    const lowProfileCode = queryParams.get("OG-ExternalIdentifier");
    const responseCode = queryParams.get("ResponseCode");

    const request = {
      paymentPageId: lowProfileCode,
    };
    setActionType("registerWebsitePayment");
    websiteActions.registerWebsitePayment(request);
  };

  const handleMoveToDashboardPage = () => {
    navigation('/dashboard')
  };

  return (
    <div className="success-payment-page-container">
      <h1 className="heading1">
        תודה שבחרת להנציח את יקירך במערכת <br />
        <span>"זיכרונות חיים"</span>
      </h1>
      <h1>מיד תועבר לעמוד ההנצחה שיצרת.</h1>
      <h4 className="heading4">
        למעבר לאיזור ניהול אישי <button onClick={handleMoveToDashboardPage}>לחץ כאן</button>
      </h4>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  paymentReducer: state.paymentReducer,
  websiteReducer: state.websiteReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    websiteActions: bindActionCreators(websiteActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChargeAndCreateTokenSuccessPage);
