import osherVakninImg from "../../../assets/Images/clients/osher-vaknin.jpeg";
import refaelTamirImg from "../../../assets/Images/clients/refael-tamir.jpg";
import shakedHabaniImg from "../../../assets/Images/clients/shaked-habani.jpeg";
import roeiWolfImg from "../../../assets/Images/clients/roei-wolf.jpg";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from "swiper/core";
SwiperCore.use([Pagination, Autoplay]);

export const SalesOurClientsComponent = ({ ...props }) => {
  const navigateToWebsiteClicked = (url) => {
    window.open(url);
  };

  return (
    <div className="our-clients-container">
      <h1 className="heading">אתרי הנצחה לדוגמא</h1>
      <div className="clients-list-container">
        {clientsList.map((clientDetails) => (
          <div className="client-card-container" key={clientDetails.name}>
            <div className="client-img-container">
              <img src={clientDetails.imageUrl} />
            </div>
            <div className="client-details-contianer">
              <h1>{clientDetails.name}</h1>
              <h2>{clientDetails.dates}</h2>
              <button
                onClick={() => {
                  navigateToWebsiteClicked(clientDetails.websiteUrl);
                }}
              >
                כניסה לאתר
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SalesOurClientsComponent;

const clientsList = [
  { name: "אושר וקנין", dates: "1968-2022", imageUrl: osherVakninImg, websiteUrl: "https://izcor.org/memorial/osher" },
  { name: "שקד חבני", dates: "2001-2023", imageUrl: shakedHabaniImg, websiteUrl: "https://izcor.org/memorial/shaked-habani" },
  { name: "רועי וולף", dates: "2002-2023", imageUrl: roeiWolfImg, websiteUrl: "https://izcor.org/memorial/roei-wolf" },
  { name: "רפאל תמיר", dates: "1947-2016", imageUrl: refaelTamirImg, websiteUrl: "https://izcor.org/memorial/refael-tamir" },
];
