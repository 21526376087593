import React from "react";
import rightLogo from "../assets/Images/logo/icon-right-logo-350-png.png";
import menuIcon from "../assets/Images/Menu-4.svg";
import { Link, NavLink } from "react-router-dom";
import SalesWebsiteNavMenuComponent from "./SalesWebsiteNavMenu";
import MemorialWebsiteNavMenuComponent from "./MemorialWebsiteNavMenu";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import userActions from "../store/actions/user.actions";

export const HeaderComponent = ({ userActions, isMobileScreen, websiteInfo, loggedInUser, isMenuOpen, toggleOpenNavMenu, ...props }) => {
  const checkIfMemorialWebite = () => {
    const currentURL = window.location.href;
    if (currentURL.includes("/memorial/")) {
      return true;
    }
    return false;
  };

  const handleCloseMenu = () => {
    toggleOpenNavMenu(false);
  };

  const handleLogoutUserClicked = () => {
    userActions.logout();
    handleCloseMenu();
  };

  return (
    <header>
      <div className="container">
        <div className="header_box">
          <Link to={`${websiteInfo && checkIfMemorialWebite() ? `/memorial/${websiteInfo.baseRoute}` : "/"}`} className="logo_header">
            <img src={rightLogo} alt="Logo" />
          </Link>
          <nav className={isMenuOpen ? "menu-open" : ""}>{websiteInfo && checkIfMemorialWebite() ? <MemorialWebsiteNavMenuComponent websiteInfo={websiteInfo} handleCloseMenu={handleCloseMenu} loggedInUser={loggedInUser} handleLogoutUserClicked={handleLogoutUserClicked} /> : <SalesWebsiteNavMenuComponent handleCloseMenu={handleCloseMenu} loggedInUser={loggedInUser} handleLogoutUserClicked={handleLogoutUserClicked} />}</nav>
          <div
            className="header_right_part"
            onClick={() => {
              toggleOpenNavMenu(!isMenuOpen);
            }}
          >
            <img src={menuIcon} alt="" className="menu-icon" />
          </div>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state, ownProps) => ({
  userReducer: state.userReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
