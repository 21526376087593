import React from "react";

import packageArrow from "../../../assets/Images/package-arrow.svg";
import { Link } from "react-router-dom";
import utilService from "../../../services/general/util.service";

export const SalesOurPackagesComponent = ({ ...props }) => {
  return (
    <section id="ourPackages" className="package_main_container">
      <div className="container">
        <div className="package_main_box_header">
          <h2 className="package_main_heading">יצירת אתר הנצחה</h2>
          <p className="package_main_content">הנגשנו עבורכם את תהליך הקמת האתר והפכנו את התהליך לפשוט ונוח</p>
          <p>הירשמו עכשיו לפלטפורמת זיכרונות חיים, לחצו על כפתור "יצירת אתר הנצחה", הכניסו מספר פרטים על יקירכם ורכשו את חבילת הכל כלול שלנו</p>
          <p>בסיום, תקבלו גישה לאתר ההנצחה שיצרתם ותוכלו לשתף אותו ללא הגבלה</p>
        </div>

        <div className="package_main_card_box">
          {packageDetails.map((packageDetails) => (
            <div key={utilService.makeId()} className={`package_main_card ${packageDetails.style !== "base" ? "package_main_card_main" : ""}`}>
              <h2>{packageDetails.name}</h2>
              <div className="package_divider">
                <img src={packageArrow} />
              </div>
              {(packageDetails.active && packageDetails.price) > 0 && (
                <div className="package_price_box">
                  {packageDetails.price}₪ <span>/שנה</span>
                </div>
              )}
              {!packageDetails.active && <div className="package_price_box">בקרוב</div>}
              <ul>
                {packageDetails.description.map((description) => (
                  <li key={utilService.makeId()}>
                    <i className="fa-solid fa-circle-check"></i> {description}
                  </li>
                ))}
              </ul>
              {packageDetails.active && (
                <div className="button_box">
                  <Link to={"/register"}>יצירת אתר הנצחה </Link>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="base-package-details-container">
          <p> המחירים כוללים:</p>
          <p>דומיין ושרת איחסון לאתר ההנצחה</p>
          <p>1 ג'יגה בייט אחסון עבור התמונות והסרטונים באתר</p>
          <p>ניתן להרחיב את גודל אחסון המדיה בכל עת בתוספת תשלום</p>
        </div>
      </div>
    </section>
  );
};

export default SalesOurPackagesComponent;

export const packageDetails = [
  // {
  //   name: "ללא עלות",
  //   price: 0,
  //   description: ["זיכרונות בתמונות", "סיפור חייו", "תפילה"],
  //   style: "base",
  //   type: "Free",
  //   active: true,
  // },
  // {
  //   name: "בסיס",
  //   price: 360,
  //   description: ["זיכרונות בתמונות", "סיפור חייו", "תפילה", "נר לזכרו/ה", "פרטי קבורה"],
  //   style: "base",
  //   type: "Basic",
  //   active: true,
  // },
  {
    name: "הכל כלול",
    price: 180,
    description: ["זיכרונות בתמונות", "סיפור חייו", "תפילה", "נר לזכרו/ה", "פרטי קבורה", "פלייליסט שירים שאהב/ה", "זיכרונות משותפים", "ציר זמן אינטרקטיבי - בקרוב"],
    style: "advance",
    type: "Advance",
    active: true,
  },
  // {
  //   name: "פרימיום",
  //   price: 600,
  //   description: ["זיכרונות בתמונות", "סיפור חייו", "תפילה", "נר לזכרו/ה", "פרטי קבורה", "פלייליסט שירים שאהב/ה", "זיכרונות משותפים", "ציר זמן אינטרקטיבי", "ניהול אירועי הנצחה", "רשימת דיוור", "פרי עטו"],
  //   style: "base",
  //   type: "Premium",
  //   active: false,
  // },
];
