import { userActionTypes } from "../actionTypes/userActionTypes"

const initialState = {
    stateInfo: {
      isLoading: false,
      isSuccess: false,
      isError: false,
      error: null,
    },
    userInfo: null,
  }

  
const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case userActionTypes.START_LOGIN:
      case userActionTypes.START_REGISTER_USER:
        return setStateLoading(state, action)
      case userActionTypes.LOGIN_SUCCESS:
      case userActionTypes.REGISTER_USER_SUCCESS:
        return setUser(state, action)
      case userActionTypes.LOGIN_FAILURE:
      case userActionTypes.REGISTER_USER_FAILURE:
        return setStateFailure(state, action)
      case userActionTypes.LOGOUT:
        return logout(state, action)
  
      default:
        return state
    }
  }
  
  const setStateLoading = (state, action) => {
    return {
      ...state,
      stateInfo: {
        isLoading: true,
        isSuccess: false,
        isError: false,
        error: null,
      },
    }
  }
  
  const setStateFailure = (state, action) => {
    return {
      ...state,
      stateInfo: {
        isLoading: false,
        isSuccess: false,
        isError: true,
        error: action.data.error,
      },
    }
  }
  
  const setUser = (state, action) => {
    return {
      ...state,
      stateInfo: {
        isLoading: false,
        isSuccess: true,
        isError: false,
        error: null,
      },
      userInfo: action.data,
    }
  }
  
  const logout = (state, action) => {
    return {
      ...state,
      stateInfo: {
        isLoading: false,
        isSuccess: true,
        isError: false,
        error: null,
      },
      userInfo: null,
    }
  }
  
  export default userReducer