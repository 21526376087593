import React from "react";
import DashboardCardConponent from "./DashboardWebsiteCard";
import utilService from "../../../services/general/util.service";

export const DashboardWebsiteListConponent = ({
  websiteList,
  showWebsiteUpdatesClicked,
  handleShowWebsiteClicked,
  ...props
}) => {
  return (
    <div className="Websites_updated_card_box">
      {websiteList?.map((websiteDetails) => (
        <DashboardCardConponent
          websiteDetails={websiteDetails}
          key={utilService.makeId()}
          showWebsiteUpdatesClicked={showWebsiteUpdatesClicked}
          handleShowWebsiteClicked={handleShowWebsiteClicked}
        />
      ))}
    </div>
  );
};

export default DashboardWebsiteListConponent;
