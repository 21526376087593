import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoaderComponent from "../../component/LoaderComponent";
import { ALERT_ICON, alertService } from "../../services/general/alert.service";

const ArticlesListPage = ({ isMobileScreen, ...props }) => {
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchArticlesList = async () => {
      try {
        const response = await fetch(`/seedData/articles/articlesList.json`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const articleDataList = await response.json();

        if (articleDataList) {
          const articleTitelsList = articleDataList.map((article) => {
            return { image: article.image, name: article.name };
          });
          setArticles(articleTitelsList);
          setIsLoading(false);
        } else {
          throw new Error("Network response was not ok");
        }
      } catch (error) {
        console.error("Error fetching articles list:", error);

        alertService.showMessage(ALERT_ICON.error, "מאמר לא נמצא");
        // Set a timeout to navigate back after 5 seconds
        const timer = setTimeout(() => {
          navigate(-1);
        }, 1000);
      }
    };

    fetchArticlesList();
  }, []);

  if (isLoading) return <LoaderComponent isMobileScreen={isMobileScreen} />;

  return (
    <div className="articles-gallery-page-container">
      <h2 className="gallery-title">מאמרים</h2>
      <div className="gallery-container">
        {articles.map((article, index) => (
          <div key={index} className="article-card">
            <Link to={`/articles/${article.name}`}>
              <img src={article.image} alt={article.name} className="article-image" />
              <h3 className="article-title">{article.name}</h3>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArticlesListPage;
