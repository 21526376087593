import { MoonLoader, GridLoader } from 'react-spinners'

const LoaderComponent = ({isMobileScreen}) => {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <GridLoader
        color="#b47415"
        size={isMobileScreen ? 25 : 50}
        speedMultiplier={0.6}
      />
    </div>
  )
}

export default LoaderComponent
