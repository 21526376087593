import mainBannerBackground from "../../../assets/Images/main_banner.png";
import bannerfreame from "../../../assets/Images/bannerfreame.svg";

export const SalesHomePageBannerComponent = ({ ...props }) => {
  return (
    <section className="sales-home-page-banner-container">
      <div className="container">
        <div className="banner-container">
          <div className="banner-content-left-container">
            <h2 className="main_f_heading">"זיכרונות חיים"</h2>
            <p>מערכת אינטרנטית המאפשרת לכם להדליק נרות זיכרון, להוסיף זיכרונות, תמונות, סרטונים, מכתבים ותכנים נוספים, הכל במטרה לאפשר לכם ליצור מקום שמרכז ומשמר את זכר אהובכם.</p>
            <a href="/register" className="main_button_container">
              <button type="button">יצירת אתר הנצחה</button>
            </a>
          </div>
          <div className="banner_content_box_right">
            <img src={mainBannerBackground} alt="" />
          </div>
          <img src={bannerfreame} alt="" className="bannerframe" />
        </div>
      </div>
    </section>
  );
};

export default SalesHomePageBannerComponent;
