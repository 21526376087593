import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { forwardRef } from "react";
import {
  ALERT_ICON,
  alertService,
} from '../../../services/general/alert.service'
import contactActions from "../../../store/actions/contact.action";


const DEFAULT_CONTACT_DETAILS = {
  name: "",
  email: "",
  phone: "",
  message: "",
};

export const SalesContactUsFormComponent = forwardRef(({ contactActions, contactReducer, ...props }, ref) => {
  const [actionType, setActionType] = useState();
  const [contactDetails, setContactDetails] = useState(DEFAULT_CONTACT_DETAILS);

  useEffect(() => {
    if (actionType === "submitContact") {
      if (!contactReducer.stateInfo.isLoading && contactReducer.stateInfo.isSuccess) {
        alertService.showMessage(ALERT_ICON.success, "תודה על פנייתך", "נחזור אלייך בהקדם האפשרי");
        setContactDetails(DEFAULT_CONTACT_DETAILS);
        setActionType(null);
      }

      if (!contactReducer.stateInfo.isLoading && contactReducer.stateInfo.isError) {
        alertService.showMessage(ALERT_ICON.error, "שגיאה ביצירת קשר", "אנא נסה שנית מאוחר יותר");
        setActionType(null);
      }
    }
  }, [contactReducer.stateInfo]);

  const handleInputChanged = (event) => {
    const { name, value } = event.target;
    setContactDetails({ ...contactDetails, [name]: value });
  };

  const handleSubmitContact = (event) => {
    event.preventDefault();
    setActionType("submitContact");
    contactActions.submitContactDetails(contactDetails);
  };

  return (
    <section id="contactUs" ref={ref} className="leads_from_container">
      <div className="container">
        <div className="leads_from_box">
          <h2 className="leads_from_main_heading">לפרטים נוספים ויצירת קשר</h2>
          <form id="" className="form_leads_box">
            <div className="leads_from_input_box">
              <div className="leads_input_box">
                <input type="text" name="name" id="name" value={contactDetails.name} placeholder="שם פרטי שם משפחה" required onChange={handleInputChanged} />
              </div>
            </div>
            <div className="leads_from_input_box">
              <div className="leads_input_box">
                <input type="email" name="email" id="email" value={contactDetails.email} placeholder="דואר אלקטרוני" required onChange={handleInputChanged} />
              </div>
            </div>
            <div className="leads_from_input_box">
              <div className="leads_input_box">
                <input type="tel" name="phone" id="phone" value={contactDetails.phone} placeholder="נייד" required onChange={handleInputChanged} />
              </div>
            </div>
            <div className="leads_from_input_box">
              <div className="leads_input_box">
                <textarea name="message" value={contactDetails.message} placeholder="תוכן ההודעה" required onChange={handleInputChanged} />
              </div>
            </div>
            <a className="main_button_container">
              <button type="submit" onClick={handleSubmitContact}>
                שלח
              </button>
            </a>
          </form>
        </div>
      </div>
    </section>
  );
});

const mapStateToProps = (state, ownProps) => ({
    contactReducer: state.contactReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    contactActions: bindActionCreators(contactActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesContactUsFormComponent);
