import { Link } from "react-router-dom";

export const MemorialHomePgaeModuleHeaderComponent = ({
  baseRoute,
  title,
  boldTitle,
  route,
  linkTitle,
  ...props
}) => {
  return (
    <div className="home-module-header-container">
      <h2 className="home-module-main-heading">
        {title} <span>{boldTitle}</span>
      </h2>
      <Link
        to={`/memorial/${baseRoute}/${route}`}
        className="home-module-header-button"
      >
        {linkTitle}
      </Link>
    </div>
  );
};

export default MemorialHomePgaeModuleHeaderComponent;
