import userService from "../../services/user.service";
import { userActionTypes } from "../actionTypes/userActionTypes";

export const login = (data) => async (dispatch) => {
  try {
    dispatch({ type: userActionTypes.START_LOGIN });

    const response = await userService.login(data);

    dispatch({
      type: userActionTypes.LOGIN_SUCCESS,
      data: response?.data?.payload,
    });
    
    sessionStorage.setItem("token", response.data.payload.token);
    sessionStorage.setItem("LoggedInUser", JSON.stringify(response?.data?.payload));
  } catch (ex) {
    console.log("Login error: " + ex.message);
    dispatch({ type: userActionTypes.LOGIN_FAILURE, data: ex.response?.data });
  }
};

export const registerUser = (data) => async (dispatch) => {
  try {
    dispatch({ type: userActionTypes.START_REGISTER_USER });

    const response = await userService.registerUser(data);
    dispatch({
      type: userActionTypes.REGISTER_USER_SUCCESS,
      data: response.data.payload,
    });

    sessionStorage.setItem("token", response.data.payload.token);
    sessionStorage.setItem("LoggedInUser", JSON.stringify(response.data.payload));
  } catch (ex) {
    console.log("Register User error: " + ex.message);
    dispatch({ type: userActionTypes.REGISTER_USER_FAILURE, data: ex.response.data });
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch({ type: userActionTypes.LOGOUT });
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("LoggedInUser");
  } catch (ex) {
    console.log("Logout error: " + ex.message);
    dispatch({ type: userActionTypes.LOGIN_FAILURE });
  }
};

export const setUser = (data) => async (dispatch) => {
  try {
    dispatch({
      type: userActionTypes.LOGIN_SUCCESS,
      data,
    });
  } catch (ex) {
    console.log("Set user error: " + ex.message);
    dispatch({ type: userActionTypes.LOGIN_FAILURE });
  }
};

const userActions = {
  login,
  logout,
  setUser,
  registerUser,
};

export default userActions;
