import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import MemorialHomePage from "../../../pages/memorialWebsite/MemorialHomePage";
import { createWebsiteLevels } from "../../../pages/dashboard/createMemotialWebsite/CreateMemorialWebsitePage";
import MemorialGalleryPage from "../../../pages/memorialWebsite/MemorialGalleryPage";
import MemorialGraveInfoPage from "../../../pages/memorialWebsite/MemorialGraveInfoPage";
import MemorialPlaylistPage from "../../../pages/memorialWebsite/MemorialPlaylistPage";
import LiveShowHeaderComponent from "./LiveShowHeaderComponent";

export const CreateMemorialWebsiteLiveShowComponent = ({ websiteInfo, createWebsiteLevel, setCreateWebsiteLevel, isUpdateAction, ...props }) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetHeight);
  }, []);

  const getLiveShowComponent = () => {
    switch (createWebsiteLevel) {
      case createWebsiteLevels.route:
      case createWebsiteLevels.deceasedInfo:
      case createWebsiteLevels.storyInfo:
      case createWebsiteLevels.homePageGallery:
        return <MemorialHomePage editableWebsiteInfo={websiteInfo} />;
      case createWebsiteLevels.deceasedGallery:
        return <MemorialGalleryPage editableWebsiteInfo={websiteInfo} />;
      case createWebsiteLevels.graveInfo:
        return <MemorialGraveInfoPage editableWebsiteInfo={websiteInfo} />;
      case createWebsiteLevels.playlistSongs:
        return <MemorialPlaylistPage editableWebsiteInfo={websiteInfo} />;

      default:
        break;
    }
  };

  return (
    <div className="create-website-live-show-container" ref={ref}>
      <LiveShowHeaderComponent websiteInfo={websiteInfo} setCreateWebsiteLevel={setCreateWebsiteLevel} isUpdateAction={isUpdateAction} />
      {getLiveShowComponent()}
      {/* <a href="#" className="check_live_button">
        בדוק בשידור חי
      </a>      */}
    </div>
  );
};

export default CreateMemorialWebsiteLiveShowComponent;
