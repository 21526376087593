import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const RouteHandler = ({ setWebsiteInfo, websiteReducer, websiteActions }) => {
  const location = useLocation();

  useEffect(() => {
    // Extract baseRoute from the location's pathname
    const pathSegments = location.pathname.split("/");
    const baseRouteIndex = pathSegments.findIndex((segment) => segment === "memorial") + 1;
    const baseRoute = pathSegments[baseRouteIndex];

    if (baseRoute) {
      if (websiteReducer?.websiteInfo) {
        // const website = websiteReducer.websiteListInfo.find((info) => info.baseRoute === baseRoute);
        setWebsiteInfo(websiteReducer.websiteInfo);
      } else {
        getWebsiteInfoByBaseRoute(baseRoute);
      }

      // getWebsiteInfoByBaseRoute(baseRoute);

      //   if (websiteReducer?.websiteListInfo?.length > 0) {
      //     const website = websiteReducer.websiteListInfo.find((info) => info.baseRoute === baseRoute);
      //     setWebsiteInfo(website);
      //   } else {
      //     getWebsiteInfoByBaseRoute(baseRoute);
      //   }
    }
  }, [location]);

  const getWebsiteInfoByBaseRoute = async (baseRoute) => {
    var response = await websiteActions.getWebsiteDetailsByRoute(baseRoute);
    initMemorialWebsiteData(response);
  };

  const initMemorialWebsiteData = (websiteInfo) => {
    if (websiteInfo) {
      websiteActions.getMemorialCandles(websiteInfo.id);
      websiteActions.getDeceasedGalleryFiles(websiteInfo.deceasedInfo.id);
      websiteActions.getWebsiteSahredMemorials(websiteInfo.id);
      websiteActions.getPlaylistSongs(websiteInfo.id);
      websiteActions.getDeceasedPrayer(websiteInfo.deceasedInfo.id);
    }
  };

  return null; // This component doesn't render anything
};

export default RouteHandler;
