import http from "./general/axios.service";

export const getDeceasedPrayer = (data) => {
  return http.get(`Prayer/GetDeceasedPrayer/${data}`);
};

const prayerService = {
  getDeceasedPrayer,
};

export default prayerService;
