import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import candleIcon from "../assets/Images/servicesIcons/memorialCandleIcon.svg";

export const FooterComponent = ({ isMobileScreen, websiteInfo, lightCandleCliked, addSahredMemorialCliked, ...props }) => {
  const location = useLocation();
  const [showMemorialWebsiteFloatButtons, setShowMemorialWebsiteFloatButtons] = useState(false);

  useEffect(() => {
    const { pathname } = location;

    if (pathname.includes("/memorial/")) {
      setShowMemorialWebsiteFloatButtons(true);
    } else {
      setShowMemorialWebsiteFloatButtons(false);
    }
  }, [location]);

  const showHideLightCandleButton = () => {
    if (websiteInfo?.websiteLicense?.subscriptionType && websiteInfo?.websiteLicense?.subscriptionType !== "Free") return true;
    else return false;
  };

  const showHideAddSharedMemorialButton = () => {
    if (websiteInfo?.websiteLicense?.subscriptionType && websiteInfo?.websiteLicense?.subscriptionType.includes("Advance", "Premium")) return true;
    else return false;
  };

  return (
    <footer dir="rtl">
      <div className="container">
        <div className="footer_box">
          {showMemorialWebsiteFloatButtons && (
            <div className="button_box">
              {showHideLightCandleButton() && (
                <button className="footer_1_btn" id="candle_btn" onClick={lightCandleCliked}>
                  <img src={candleIcon} alt="" />
                  הדלקת נר
                </button>
              )}
              {showHideAddSharedMemorialButton() && (
                <button className="footer_2_btn" id="add_memories_btn" onClick={addSahredMemorialCliked}>
                  הוספת זיכרון
                </button>
              )}
            </div>
          )}

          <div className="footer_copyright_box">
            <div className="terms-container">
              <Link to={`/termsAndPrivacy`} className="link">
                תנאי שימוש ומדיניות פרטיות
              </Link>
              <Link to={`/accessibilityStatement`} className="link">
                הצהרת נגישות
              </Link>
            </div>
            <span>&copy; כל הזכויות שמורות "זיכרונות חיים"</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
