import { Link } from "react-router-dom";

export const SalesWebsiteNavMenuComponent = ({ handleCloseMenu, loggedInUser, handleLogoutUserClicked, ...props }) => {
  return (
    <ul>
      <span className="cross_icon" onClick={handleCloseMenu}>
        <i className="fa-solid fa-xmark"></i>
      </span>
      <a href="/#ourPackages" onClick={handleCloseMenu}>
        <li>החבילות שלנו</li>
      </a>
      <a href="#contactUs" onClick={handleCloseMenu}>
        <li>צור קשר</li>
      </a>
      <a href="#ourStory" onClick={handleCloseMenu}>
        <li>הסיפור שלנו</li>
      </a>
      <Link to="/articles" className="link" onClick={handleCloseMenu}>
        מאמרים
      </Link>
      {!loggedInUser && (
        <Link to="/login" className="link" onClick={handleCloseMenu}>
          התחבר
        </Link>
      )}
      {loggedInUser && (
        <Link to="/" className="link" onClick={handleLogoutUserClicked}>
          התנתק
        </Link>
      )}
      {loggedInUser && (
        <Link to="/dashboard" className="link" onClick={handleCloseMenu}>
          איזור ניהול
        </Link>
      )}
    </ul>
  );
};

export default SalesWebsiteNavMenuComponent;
