import http from "./general/axios.service";

export const addSharedMemorial = (data) => {
    return http.post("SharedMemorial/Add", data);
};

export const approveSahredMemorial = (data) => {
    return http.patch("SharedMemorial/Approved", data);
};

export const getWebsiteSahredMemorials = (data) => {
    return http.get(`SharedMemorial/${data}` );
};

const sharedMemorialService = {
  addSharedMemorial,
  approveSahredMemorial,
  getWebsiteSahredMemorials
};

export default sharedMemorialService;
