import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import websiteActions from "../../store/actions/website.action";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import utilService from "../../services/general/util.service";
import LoaderComponent from "../../component/LoaderComponent";

export const MemorialPrayerPage = ({
  websiteActions,
  websiteReducer,
  isMobileScreen,
  ...props
}) => {
  const [websiteInfo, setWebsiteInfo] = useState();
  const [prayerDetails, setPrayerDetails] = useState([]);
  const [selectedPrayerType, setSelectedPrayerType] = useState(-1);

  useEffect(() => {
    if (websiteReducer?.websiteInfo) {
      setWebsiteInfo(websiteReducer.websiteInfo);
    }
  }, [websiteReducer.websiteInfo]);

  useEffect(() => {
    const prayer = getPrayerDetails();
    if (prayer) {
      setPrayerDetails(prayer);
    }
  }, [websiteInfo]);

  useEffect(() => {
    const prayer = getPrayerDetails();
    if (prayer) {
      setPrayerDetails(prayer);
    }
  }, [selectedPrayerType]);

  const getPrayerDetails = () => {
    const prayer = websiteInfo?.prayerInfo?.find(
      (prayer) => prayer.prayerTypeId === selectedPrayerType
    );
    return prayer;
  };

  const handlePrayerTypeChange = (prayerType) => {
    setSelectedPrayerType(prayerType);
  };

  return websiteInfo ? (
    <section className="prayer_maon_content_container" dir="rtl">
        <div className="prayer_maon_content_box">
          <div className="prayer_main_content_inner_box">
            <div className="prayer_main_content_inner_box_box">
              <div className="prayer_maon_content_box_tabs">
                <ul className="tab-links">
                  <li className={`${selectedPrayerType === -1 ? 'active':''}`}>
                    <a
                      onClick={() => {
                        handlePrayerTypeChange(-1);
                      }}
                    >
                      קדיש
                    </a>
                  </li>
                  <li className={`${selectedPrayerType === 1 ? 'active':''}`}>
                    <a
                      onClick={() => {
                        handlePrayerTypeChange(1);
                      }}
                    >
                      תהילים
                    </a>
                  </li>
                  <li className={`${selectedPrayerType === 2 ? 'active':''}`}>
                    <a
                      onClick={() => {
                        handlePrayerTypeChange(2);
                      }}
                    >
                      משניות
                    </a>
                  </li>
                </ul>

                {selectedPrayerType === -1 && (
                  <div className="tab-content">
                    <div id="tab1" className="tab active">
                      <div className="prayer_maon_content_box_tabs_conatent_box prayer_maon_content_box_tabs_conatent_box_1">
                        {KADISH.map((prayer) => (
                          <p key={utilService.makeId()}>
                            {prayer}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                {selectedPrayerType !== -1 && (
                  <div className="tab-content">
                    <div id="tab1" className="tab active">
                      <div className="prayer_maon_content_box_tabs_conatent_box prayer_maon_content_box_tabs_conatent_box_1">
                        <h2>תפילה לפי שם {`${websiteInfo?.deceasedInfo.gender === "male" ? "המנוח" : "המנוחה"}`}</h2>
                        {prayerDetails?.namePrayer?.map((prayer) => (
                          <p key={utilService.makeId()}>
                            <span>{prayer.value.charAt(0)}</span>
                            {prayer.value.slice(1)}
                          </p>
                        ))}
                      </div>
                      <div className="prayer_maon_content_box_tabs_conatent_box prayer_maon_content_box_tabs_conatent_box_1">
                        <h2>תפילה לפי אותיות נשמה</h2>
                        {prayerDetails?.neshamaPrayer?.map((prayer) => (
                          <p key={utilService.makeId()}>
                            <span>{prayer.value.charAt(0)}</span>
                            {prayer.value.slice(1)}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      {/* </div> */}
    </section>
  ) : (
    <LoaderComponent isMobileScreen={isMobileScreen} />
  );
};

const mapStateToProps = (state, ownProps) => ({
  websiteReducer: state.websiteReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    websiteActions: bindActionCreators(websiteActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemorialPrayerPage);

export const KADISH = [
  "יִתְגַּדַּל וְיִתְקַדַּשׁ שְׁמֵהּ רַבָּא. (אָמֵן)",
  "בְּעָלְמָא דִּי בְרָא כִּרְעוּתֵה, וְיַמְלִיךְ מַלְכוּתֵה, וְיַצְמַח פֻּרְקָנֵה, וִיקָרֵב מְשִׁיחֵהּ. (אָמֵן)",
  "יְהֵא שְׁמֵהּ רַבָּא מְבָרַךְ לְעָלַם וּלְעָלְמֵי עָלְמַיָּא יִתְבָּרַךְ. וְיִשְׁתַּבַּח. וְיִתְפָּאַר. וְיִתְרוֹמַם. וְיִתְנַשֵּׂא. וְיִתְהַדָּר. וְיִתְעַלֶּה. וְיִתְהַלָּל שְׁמֵהּ דְּקֻדְשָׁא, בְּרִיךְ הוּא. (אָמֵן)",
  "לְעֵלָּא מִן כָּל בִּרְכָתָא. שִׁירָתָא. תֻּשְׁבְּחָתָא וְנֶחֱמָתָא. דַּאֲמִירָן בְּעָלְמָא וְאִמְרוּ אָמֵן. (אָמֵן)",
  "יְהֵא שְׁלָמָא רַבָּא מִן שְׁמַיָּא. חַיִּים וְשָׂבָע וִישׁוּעָה וְנֶחָמָה וְשֵׁיזָבָא וּרְפוּאָה וּגְאֻלָּה וּסְלִיחָה וְכַפָּרָה וְרֵיוַח וְהַצָּלָה. לָנוּ וּלְכָל עַמּוֹ יִשְׂרָאֵל וְאִמְרוּ אָמֵן. (אָמֵן)",
  "עוֹשֶׂה שָׁלוֹם בִמְרוֹמָיו, הוּא בְּרַחֲמָיו יַעֲשֶׂה שָׁלוֹם עָלֵינוּ וְעַל כָּל עַמּוֹ יִשְׂרָאֵל, וְאִמְרוּ אָמֵן. (אָמֵן)",
];
