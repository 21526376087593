import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ALERT_ICON, alertService } from "../../services/general/alert.service";
import LoaderComponent from "../../component/LoaderComponent";

export const ArticlePage = ({ isMobileScreen, ...props }) => {
  const { articleName } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchArticleData = async () => {
      try {
        const response = await fetch(`/seedData/articles/articlesList.json`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const articleDataList = await response.json();
        const articleData = articleDataList.find((article) => article.name === articleName);

        if (articleData) {
          setData(articleData);
          setIsLoading(false);
        } else {
          throw new Error("Network response was not ok");
        }
      } catch (error) {
        console.error("Error fetching article data:", error);
        alertService.showMessage(ALERT_ICON.error, "מאמר לא נמצא");
        // Set a timeout to navigate back after 5 seconds
        const timer = setTimeout(() => {
          navigate(-1);
        }, 1000);
      }
    };

    fetchArticleData();
  }, [articleName]);

  const getContentDetailsElement = (contentDetails, index) => {
    if (contentDetails.elementType === elementTypes.mainTitle) return <h1 key={index}>{contentDetails.elementContent}</h1>;
    if (contentDetails.elementType === elementTypes.subTitle1) return <h2 key={index}>{contentDetails.elementContent}</h2>;
    if (contentDetails.elementType === elementTypes.subTitle2) return <h3 key={index}>{contentDetails.elementContent}</h3>;
    if (contentDetails.elementType === elementTypes.subTitle3) return <h4 key={index}>{contentDetails.elementContent}</h4>;
    if (contentDetails.elementType === elementTypes.subTitle4) return <h5 key={index}>{contentDetails.elementContent}</h5>;
    if (contentDetails.elementType === elementTypes.paragraph) return <p key={index}>{contentDetails.elementContent}</p>;
    if (contentDetails.elementType === elementTypes.space) return <br key={index} />;
  };

  if (isLoading || !data) return <LoaderComponent isMobileScreen={isMobileScreen} />;
  return (
    <div className="article-page-container">
      {data.image && <img src={data.image} alt="Banner" className="banner-image" />}
      {data.content.map((contentDetails, index) => getContentDetailsElement(contentDetails, index))}
    </div>
  );
};

const elementTypes = {
  mainTitle: "main-title",
  subTitle1: "sub-title-1",
  subTitle2: "sub-title-2",
  subTitle3: "sub-title-3",
  subTitle4: "sub-title-4",
  paragraph: "paragraph",
  list: "list",
  listChiled: "list-chiled",
  space: "space",
  bannerImage: "banner-image",
};

export default ArticlePage;
