import candleImage from "../../assets/Images/candle.gif";
import utilService from "../../services/general/util.service";
export const MemorialCandleCardComponent = ({
  memorialCandleInfo,
  gender,
  ...props
}) => {
  return (
    <div className="profile_client_card">
      <h3 className="profile_name_heading">{memorialCandleInfo?.name}</h3>
      <h4 className="profile_date_heading">
        {utilService.formatDate(memorialCandleInfo?.createDate)}
      </h4>
      <p className="profile_text_data">
        הדליק/ה נר {`${gender === "male" ? "לזכרו" : "לזכרה"}`}
      </p>
      <img src={candleImage} alt="" className="profile_candle_img" />
    </div>
  );
};

export default MemorialCandleCardComponent;
