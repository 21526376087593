import http from "./general/axios.service";

export const submitContactDetails = (data) => {
  return http.post("/Contact/SubmitContactUs", data);
};

const contactService = {
  submitContactDetails,
};

export default contactService;
