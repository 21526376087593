import React, { useEffect, useState } from "react";

export const CreateWebsiteGraveInfoLevelComponent = ({
  handleInputChange,
  burialLocation,
  graveImage,
  handleSetGraveLocation,
  ...props
}) => {

  return (
    <div className="form_step_box">
      <div className="form_input_main_box" style={{display:'flex', gap:'10px'}}>
        <div className="form_input_box">
          <label htmlFor="latitude">קו רוחב (Latitude)</label>
          <input
            type="number"
            name="latitude"
            placeholder="קו רוחב"
            id="latitude"
            required
            value={burialLocation?.latitude}
            onChange={handleInputChange}
          />
        </div>

        <div className="form_input_box">
          <label htmlFor="longitude">קו אורך (Longitude)</label>
          <input
            type="longitude"
            name="longitude"
            placeholder="קו אורך"
            id="longitude"
            required
            value={burialLocation?.longitude}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="create-website-form-button">
        <button type="button" id="nextbtn" onClick={handleSetGraveLocation}>
          קבע כמיקום נוכחי
        </button>
      </div>

      <div className="form_input_main_box">
        <div className="form_input_box">
          <label htmlFor="graveImage">תמונת הקבר</label>
          <input
            type="file"
            name="graveImage"
            id="graveImage"
            required
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="form_input_main_box">
        <div className="form_input_box">
          <label htmlFor="city">בית עלמין</label>
          <input
            type="text"
            name="city"
            placeholder="בית עלמין"
            id="city"
            required
            value={burialLocation?.city}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="form_input_main_box">
        <div className="form_input_box">
          <label htmlFor="block">גוש</label>
          <input
            type="text"
            name="block"
            placeholder="גוש"
            id="block"
            required
            value={burialLocation?.block}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="form_input_main_box">
        <div className="form_input_box">
          <label htmlFor="burialPlot">חלקה</label>
          <input
            type="text"
            name="burialPlot"
            placeholder="חלקה"
            id="burialPlot"
            required
            value={burialLocation?.burialPlot}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="form_input_main_box">
        <div className="form_input_box">
          <label htmlFor="row">שורה</label>
          <input
            type="text"
            name="row"
            placeholder="שורה"
            id="row"
            required
            value={burialLocation?.row}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="form_input_main_box">
        <div className="form_input_box">
          <label htmlFor="number">מספר</label>
          <input
            type="number"
            name="number"
            placeholder="מספר"
            id="number"
            required
            value={burialLocation?.number}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateWebsiteGraveInfoLevelComponent;
