import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getClientBaseUrl } from "../../../../services/general/axios.service";
import { ALERT_ICON, alertService } from "../../../../services/general/alert.service";

export const CreateWebsiteRouteLevelComponent = ({ handleInputChange, websiteInfo, ...props }) => {
  
  const handleHelpClicked = ()=>{
    alertService.showMessage(ALERT_ICON.question, 'כתובת האתר שתבחרו מצטרפת לכתובת הבסיסית המוצגת. אנו ממליצים לרשום את שמו של הנפטר (שם ושם משפחה מופרדים בסימן "-"). יש לרשום את כתובת האתר באנגלית בלבד!'
    );
  }

  return (
    <div className="form_step_box">
      <div className="form_input_main_box">
        <div className="form_input_box">
          <div className="website-route-input-label-container">
            <label htmlFor="websiteurl">
              <span onClick={handleHelpClicked}>
                <i className="fa-solid fa-circle-info"></i>
              </span>{" "}
              כתובת האתר
            </label>
          </div>
          <input type="text" className="websiteurl" name="baseRoute" id="websiteurl" placeholder="כתובת אתר" required onChange={handleInputChange} />
        </div>
      </div>
      <div className="website-route-container">
        <label>{`${getClientBaseUrl()}/memorial/${websiteInfo?.baseRoute ? websiteInfo?.baseRoute : "example"}`}</label>
      </div>
    </div>
  );
};

export default CreateWebsiteRouteLevelComponent;
