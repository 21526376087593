import logoRight from "../../../assets/Images/logo/icon-right-logo-350-png.png";
import { createWebsiteLevels } from "../../../pages/dashboard/createMemotialWebsite/CreateMemorialWebsitePage";

export const LiveShowHeaderComponent = ({ websiteInfo, setCreateWebsiteLevel, isUpdateAction, ...props }) => {
  const showHideAdvencedServices = () => {
    let showNavMenu = false;
    if (websiteInfo?.websiteLicense?.subscriptionType === "Advance") {
      showNavMenu = true;
    }
    return showNavMenu;
  };

  return (
    <div className="live-show-header-container">
      <div className="live-show-header-nav-container">
        {isUpdateAction && (
          <nav>
            <ul>
              <span className="cross_icon">
                <i className="fa-solid fa-xmark"></i>
              </span>
              <button
                onClick={() => {
                  setCreateWebsiteLevel(createWebsiteLevels.graveInfo);
                }}
              >
                פרטי קבורה
              </button>
              <button
                onClick={() => {
                  setCreateWebsiteLevel(createWebsiteLevels.deceasedGallery);
                }}
              >
                זכרונות בתמונות
              </button>
              {showHideAdvencedServices() && (
                <button
                  onClick={() => {
                    setCreateWebsiteLevel(createWebsiteLevels.playlistSongs);
                  }}
                >
                  שירים שאהב
                </button>
              )}
            </ul>
          </nav>
        )}
      </div>
      <a
        href="#"
        className="logo_header"
        onClick={() => {
          setCreateWebsiteLevel(createWebsiteLevels.deceasedInfo);
        }}
      >
        <img src={logoRight} alt="Logo" />
      </a>
    </div>
  );
};

export default LiveShowHeaderComponent;
