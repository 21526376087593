import http from './general/axios.service'

export const registerWebsite = (data) => {
  return http.post('Management/RegisterWebsite', data)
}

export const registerWebsiteSubscription = (data) => {
  return http.post('Management/RegisterWebsiteSubscription', data)
}

export const registerFreeWebsiteSubscription = (data) => {
  return http.post('Management/RegisterFreeWebsiteSubscription', data)
}

export const getWebsiteDetails = (data) => {
  return http.get(`Management/GetWebsiteDetails/${data}`)
}

export const getWebsiteDetailsByRoute = (data) => {
  return http.get(`Management/GetWebsiteDetailsByBaseRoute/${data}`)
}

export const getManagerWebsiteList = (data) => {
  return http.get(`Management/GetManagerUserWebsiteList/${data}`)
}

export const registerWebsitePayment = (data) => {
  return http.post(`Management/RegisterWebsitePayment`, data)
}

export const getWebsiteStorageSize = (data) => {
  return http.get(`Management/GetWebsiteStorageSize/${data}`)
}

const managementService = {
  registerWebsite,
  registerWebsiteSubscription,
  getWebsiteDetails,
  getWebsiteDetailsByRoute,
  getManagerWebsiteList,
  registerWebsitePayment,
  registerFreeWebsiteSubscription,
  getWebsiteStorageSize
}

export default managementService
