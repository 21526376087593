function makeId(length = 6) {
  var txt = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < length; i++) {
    txt += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return txt;
}

function makeLorem(size = 100) {
  var words = ["The sky", "above", "the port", "was", "the color of television", "tuned", "to", "a dead channel", ".", "All", "this happened", "more or less", ".", "I", "had", "the story", "bit by bit", "from various people", "and", "as generally", "happens", "in such cases", "each time", "it", "was", "a different story", ".", "It", "was", "a pleasure", "to", "burn"];
  var txt = "";
  while (size > 0) {
    size--;
    txt += words[Math.floor(Math.random() * words.length)] + " ";
  }
  return txt;
}

function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive
}

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

function keysToLowerCase(obj) {
  if (typeof obj !== "object") {
    return obj;
  }

  const result = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = key.charAt(0).toLowerCase() + key.slice(1);
      result[newKey] = obj[key];
    }
  }
  return result;
}

function keysToUpperCase(obj) {
  if (typeof obj !== "object") {
    return obj;
  }

  const result = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = key.charAt(0).toUpperCase() + key.slice(1);
      result[newKey] = obj[key];
    }
  }
  return result;
}

function firstLetterToUpperCase(inputString) {
  if (inputString.length === 0) {
    return inputString; // Handle empty strings if needed
  }

  // Convert the first character to uppercase and concatenate with the rest of the string
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

function firstLetterToLowerCase(inputString) {
  if (inputString.length === 0) {
    return inputString; // Handle empty strings if needed
  }

  // Convert the first character to lowercase and concatenate with the rest of the string
  return inputString.charAt(0).toLowerCase() + inputString.slice(1);
}

// ********** Date methods **********
function formatDateForInput(dateString) {
  if (!dateString) {
    return "";
  }

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}

function formateDate(dateString) {
  if (!dateString) {
    return "";
  }

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${day}/${month}/${year}`;
}

function getYearFromDate(dateValue) {
  if (!dateValue) {
    return "";
  }
  const date = new Date(dateValue);
  const year = date.getFullYear();
  return `${year}`;
}

// ********** ********** **********

// ********** Date methods **********

function ValidateEmail(value) {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return regex.test(value);
}

function validateAspNetIdentityPassword(password) {
  if (password.length < 6) {
    return false; // Password too short
  }

  if (!/[A-Z]/.test(password)) {
    return false; // No uppercase letter
  }

  if (!/[a-z]/.test(password)) {
    return false; // No lowercase letter
  }

  if (!/[0-9]/.test(password)) {
    return false; // No digit
  }

  return true; // Password is valid
}

function validateIsraeliCellularNumber(phoneNumber) {
  const regex = /^05\d{8}$/;
  return regex.test(phoneNumber);
}

// ********** ********** **********

export const utilService = {
  makeId,
  makeLorem,
  getRandomIntInclusive,
  formatDate,
  keysToLowerCase,
  keysToUpperCase,
  firstLetterToUpperCase,
  firstLetterToLowerCase,
  formatDateForInput,
  getYearFromDate,
  formateDate,
  ValidateEmail,
  validateAspNetIdentityPassword,
  validateIsraeliCellularNumber,
};

export default utilService;
