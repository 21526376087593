import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import websiteActions from "../../store/actions/website.action";
import playIcon from "../../assets/Images/play_songs_icon.svg";
import deleteIcon from "../../assets/Images/delete-button-svgrepo-com.svg";
import YouTube from "react-youtube";

export const MemorialPlaylistPage = ({
  websiteActions,
  websiteReducer,
  isMobileScreen,
  editableWebsiteInfo,
  ...props
}) => {
  const { baseRoute } = useParams();

  const [websiteInfo, setWebsiteInfo] = useState();
  const [selectedPlaylistSong, setSelectedPlaylistSong] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const [actionType, setActionType] = useState();

  const opts = {
    height: isMobileScreen ? "200" : "400",
    width: isMobileScreen ? "350" : "650",
    playerVars: {
      autoplay: 1,
    },
  };

  useEffect(() => {
    if (!editableWebsiteInfo && websiteReducer?.websiteInfo) {
      setWebsiteInfo(websiteReducer.websiteInfo);
    }
  }, [websiteReducer.websiteInfo]);

  useEffect(() => {
    if (editableWebsiteInfo) {
      setWebsiteInfo(editableWebsiteInfo);
      setIsEditMode(true);
    }
  }, [editableWebsiteInfo]);

  useEffect(() => {
    if (
      websiteReducer.websiteInfo?.playlistSongs &&
      websiteReducer.websiteInfo?.playlistSongs.length > 0
    ) {
      setSelectedPlaylistSong(websiteReducer.websiteInfo.playlistSongs[0]);
    }
  }, [websiteReducer.websiteInfo?.playlistSongs]);

  const handlePlaySongClicked = (playlistSong) => {
    setSelectedPlaylistSong(playlistSong);
  };

  const handleDeletePlaylistSong = (playlistSong) => {
    setActionType("deletePlaylistSong");
    websiteActions.deletePlaylistSong(playlistSong.id, websiteInfo.id);
  };

  const getVideoId = (url) => {
    if (url) {
      const regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#&?]*).*/;
      const match = url.match(regExp);

      if (match && match[2].length === 11) {
        return match[2];
      } else {
        // Handle invalid URL or ID
        console.error("Invalid YouTube URL");
        return null;
      }
    }
  };

  return (
    <section className="playlist-page-container">
      <div className="container">
        <section className="main_songs_content_container">
          <div className="main_songs_content_iframe">
            {selectedPlaylistSong && (
              <YouTube
                videoId={getVideoId(selectedPlaylistSong.youtubeUrl)}
                opts={opts}
              />
            )}
          </div>
        </section>

        <section className="songs_list_container">
          {websiteReducer.websiteInfo?.playlistSongs?.map(
            (playlistSong, index) => (
              <div
                className={`songs_main_list_box_card ${
                  playlistSong.id === selectedPlaylistSong?.id ? "active" : ""
                }`}
                key={index}
              >
                {isEditMode ? (
                  <img
                    className="delete-btn-icon"
                    src={deleteIcon}
                    onClick={() => {
                      handleDeletePlaylistSong(playlistSong);
                    }}
                  />
                ) : (
                  ""
                )}
                {playlistSong.author} - <span>{playlistSong.name}</span>
                <img
                  src={playIcon}
                  className="play_btn_icon"
                  onClick={() => {
                    handlePlaySongClicked(playlistSong);
                  }}
                />
              </div>
            )
          )}
        </section>
      </div>
    </section>
  );
};

const mapStateToProps = (state, ownProps) => ({
  websiteReducer: state.websiteReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    websiteActions: bindActionCreators(websiteActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemorialPlaylistPage);
