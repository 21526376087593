import { useEffect, useState } from "react";
import ProgressBarComponent from "../elements/ProgressBarComponent";

export const WebsiteStorageUsageComponent = ({ storageSize, ...props }) => {
  
    const [storageUsagePercent, setStorageUsagePercent]= useState(0);

    useEffect(() => {
        if (storageSize) {
            setStorageUsagePercent(calculateUsagePercent(storageSize));
        }
      }, [storageSize]);

    const calculateUsagePercent = (storageSize) => {
    return parseFloat(((storageSize * 100) / 1)?.toFixed(2));
  };
  
  return (
    <div>
      <h2>איחסון אתר</h2>
      <ProgressBarComponent percentage={storageUsagePercent} />
    </div>
  );
};

export default WebsiteStorageUsageComponent;
