import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import ReduxThunk from "redux-thunk";
import userReducer from "./reducers/user.reducer";
import websiteReducer from "./reducers/website.reducer";
import paymentReducer from "./reducers/payment.reducer";
import contactReducer from "./reducers/contact.reducer";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  userReducer: userReducer,
  websiteReducer: websiteReducer,
  paymentReducer: paymentReducer,
  contactReducer: contactReducer,
});

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(ReduxThunk))
);