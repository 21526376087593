import http from "./general/axios.service";

export const createPaymentPage = (data) => {
  return http.post(`Payment/CreatePaymentPage`, data);
};

export const getPaymentTokenResult = (data) => {
  return http.get(`Payment/GetPaymentTokenResult/${data}`);
};

const paymentService = {
  createPaymentPage,
  getPaymentTokenResult,
  
};

export default paymentService;
