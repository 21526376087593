import React, { useEffect, useState } from "react";

export const CreateWebsiteDeceasedGalleryLevelComponent = ({ handleInputChange, ...props }) => {
  return (
    <div className="form_step_box">
      <div className="form_input_main_box">
        <div className="form_input_box">
          <label htmlFor="deceasedGalleryImages">זיכרונות בתמונות</label>
          <input type="file" name="deceasedGalleryImages" id="deceasedGalleryImages" required multiple onChange={handleInputChange} />
        </div>
      </div>
    </div>
  );
};

export default CreateWebsiteDeceasedGalleryLevelComponent;
