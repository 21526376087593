import { websiteActionTypes } from "../actionTypes/websiteActionTypes";

const initialState = {
  stateInfo: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null,
  },
  websiteInfo: null,
  websiteListInfo: [],
};

const websiteReducer = (state = initialState, action) => {
  switch (action.type) {
    case websiteActionTypes.START_REGISTER_WEBSITE:
    case websiteActionTypes.START_GET_WEBSITE:
    case websiteActionTypes.START_ADD_OR_UPDATE_DECEASED_INFO:
    case websiteActionTypes.START_ADD_DECEASED_FILE:
    case websiteActionTypes.START_ADD_DECEASED_FILES:
    case websiteActionTypes.START_ADD_DECEASED_STORY:
    case websiteActionTypes.START_GET_MANAGER_WEBSITE_LIST:
    case websiteActionTypes.START_REGISTER_WEBSITE_SUBSCRIPTION:
    case websiteActionTypes.START_GET_WEBSITE_BY_ROUTE:
    case websiteActionTypes.START_ADD_MEMORIAL_CANDLE:
    case websiteActionTypes.START_GET_MEMORIAL_CANDLE:
    case websiteActionTypes.START_ADD_SHARED_MEMORIAL:
    case websiteActionTypes.START_ADD_DECEASED_BURIAL_LOCATION:
    case websiteActionTypes.START_ADD_PLAYLIST_SONG:
    case websiteActionTypes.START_GET_PLAYLIST_SONGS:
    case websiteActionTypes.START_DELETE_PLAYLIST_SONG:
    case websiteActionTypes.START_GET_SHARED_MEMORIALS:
    case websiteActionTypes.START_ADD_SHARED_MEMORIAL:
    case websiteActionTypes.START_GET_DECEASED_GALLERY_FILES:
    case websiteActionTypes.START_GET_DECEASED_PRAYER:
    case websiteActionTypes.START_GET_WEBSITE_STORAGE:
    case websiteActionTypes.START_DELETE_DECEASED_FILE:
      return setStateLoading(state, action);

    case websiteActionTypes.ADD_OR_UPDATE_DECEASED_INFO_SUCCESS:
    case websiteActionTypes.ADD_DECEASED_STORY_SUCCESS:
    case websiteActionTypes.ADD_DECEASED_FILE_SUCCESS:
    case websiteActionTypes.ADD_DECEASED_FILES_SUCCESS:
    case websiteActionTypes.ADD_MEMORIAL_CANDLE_SUCCESS:
    case websiteActionTypes.ADD_SHARED_MEMORIAL_SUCCESS:
    case websiteActionTypes.ADD_DECEASED_BURIAL_LOCATION_SUCCESS:
    case websiteActionTypes.ADD_PLAYLIST_SONG_SUCCESS:
    case websiteActionTypes.DELETE_PLAYLIST_SONG_SUCCESS:
    case websiteActionTypes.ADD_SHARED_MEMORIAL_SUCCESS:
    case websiteActionTypes.DELETE_DECEASED_FILE_SUCCESS:
      return setStateSuccess(state, action);

    case websiteActionTypes.REGISTER_WEBSITE_FAILURE:
    case websiteActionTypes.GET_WEBSITE_FAILURE:
    case websiteActionTypes.ADD_OR_UPDATE_DECEASED_INFO_FAILURE:
    case websiteActionTypes.ADD_DECEASED_STORY_FAILURE:
    case websiteActionTypes.GET_MANAGER_WEBSITE_LIST_FAILURE:
    case websiteActionTypes.REGISTER_WEBSITE_SUBSCRIPTION_FAILURE:
    case websiteActionTypes.GET_WEBSITE_BY_ROUTE_FAILURE:
    case websiteActionTypes.ADD_DECEASED_FILE_FAILURE:
    case websiteActionTypes.ADD_DECEASED_FILES_FAILURE:
    case websiteActionTypes.GET_MEMORIAL_CANDLE_FAILURE:
    case websiteActionTypes.ADD_MEMORIAL_CANDLE_FAILURE:
    case websiteActionTypes.ADD_SHARED_MEMORIAL_FAILURE:
    case websiteActionTypes.ADD_DECEASED_BURIAL_LOCATION_FAILURE:
    case websiteActionTypes.ADD_PLAYLIST_SONG_FAILURE:
    case websiteActionTypes.GET_PLAYLIST_SONGS_FAILURE:
    case websiteActionTypes.DELETE_PLAYLIST_SONG_FAILURE:
    case websiteActionTypes.GET_SHARED_MEMORIALS_FAILURE:
    case websiteActionTypes.APPROVE_SHARED_MEMORIAL_SUCCESS:
    case websiteActionTypes.GET_DECEASED_GALLERY_FILES_FAILURE:
    case websiteActionTypes.GET_WEBSITE_STORAGE_FAILURE:
    case websiteActionTypes.DELETE_DECEASED_FILE_FAILURE:
      return setStateFailure(state, action);

    // ********** website actions **********
    case websiteActionTypes.REGISTER_WEBSITE_SUBSCRIPTION_SUCCESS:
      return setWebsiteLicensePaymentDetails(state, action);

    case websiteActionTypes.REGISTER_WEBSITE_SUCCESS:
    case websiteActionTypes.GET_WEBSITE_SUCCESS:
    case websiteActionTypes.GET_WEBSITE_BY_ROUTE_SUCCESS:
    case websiteActionTypes.RESET_WEBSITE_INFO:
    case websiteActionTypes.REGISTER_WEBSITE_PAYMENT_SUCCESS:
    case websiteActionTypes.SET_WEBSITE_INFO:
      return setWebsite(state, action);

    case websiteActionTypes.GET_MANAGER_WEBSITE_LIST_SUCCESS:
      return setWebsiteList(state, action);

    case websiteActionTypes.GET_WEBSITE_STORAGE_SUCCESS:
      return setWebsiteStorage(state, action);
    // ********** ********** **********

    // ********** deceased info actions **********
    case websiteActionTypes.GET_DECEASED_GALLERY_FILES_SUCCESS:
      return setDeceasedGallary(state, action);
    // ********** ********** **********

    // ********** deceased prayer actions **********
    case websiteActionTypes.GET_DECEASED_PRAYER_SUCCESS:
      return setDeceasedPrayer(state, action);
    // ********** ********** **********

    // ********** memorial candles actions **********
    case websiteActionTypes.GET_MEMORIAL_CANDLE_SUCCESS:
      return setMemorialCandleList(state, action);
    // ********** ********** **********

    // ********** playlist actions **********
    case websiteActionTypes.GET_PLAYLIST_SONGS_SUCCESS:
      return setPlaylistInfo(state, action);
    // ********** ********** **********

    // ********** shared memorials actions **********
    case websiteActionTypes.GET_SHARED_MEMORIALS_SUCCESS:
      return setSharedMemorials(state, action);
    // ********** ********** **********

    case websiteActionTypes.UPDATE_WEBSITE_STORAGE_SIZE_SUCCESS:
      return setWebsiteStorageSize(state, action);

    default:
      return state;
  }
};

const setStateLoading = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: true,
      isSuccess: false,
      isError: false,
      error: null,
    },
  };
};

const setStateFailure = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: false,
      isError: true,
      error: action?.data?.error,
    },
  };
};

const setStateSuccess = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
  };
};

const setWebsite = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
    websiteInfo: action.data
      ? {
          ...state.websiteInfo, // Keep the existing websiteInfo
          ...action.data, // Add changes from action.data
        }
      : action.data,
  };
};

const setWebsiteLicensePaymentDetails = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: action.data.error,
    },
    websiteInfo: {
      ...state.websiteInfo,
      websiteLicensePayment: action.data.payload,
    },
  };
};

const setWebsiteList = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
    websiteListInfo: action.data,
  };
};

const setWebsiteStorage = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
    // websiteListInfo: action.data,
  };
};

// ********** deceased ingo actions **********
const setDeceasedGallary = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
    websiteInfo: {
      ...state.websiteInfo,
      deceasedInfo: { ...state.websiteInfo.deceasedInfo, galleryImages: action.data },
    },
  };
};
// ********** ********** **********

// ********** deceased prayer actions **********
const setDeceasedPrayer = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
    websiteInfo: {
      ...state.websiteInfo,
      prayerInfo: action.data,
    },
  };
};
// ********** ********** **********

// ********** memorial candles actions **********
const setMemorialCandleList = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
    websiteInfo: {
      ...state.websiteInfo,
      memorialCandles: action.data,
    },
  };
};
// ********** ********** **********

// ********** palylist actions **********
const setPlaylistInfo = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
    websiteInfo: {
      ...state.websiteInfo,
      playlistSongs: action.data,
    },
  };
};
// ********** ********** **********

// ********** shared memeials actions **********
const setSharedMemorials = (state, action) => {
  const websiteList = state.websiteListInfo.map((website) => {
    if (website.id === action.data.websiteId) {
      return {
        ...website,
        approvedSharedMemorials: action.data.approvedSharedMemorials,
        waitingListSharedMemrials: action.data.waitingListSharedMemrials,
      };
    }
    return website;
  });

  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
    websiteInfo: {
      ...state.websiteInfo,
      approvedSharedMemorials: action.data.approvedSharedMemorials,
      waitingListSharedMemrials: action.data.waitingListSharedMemrials,
    },
    websiteListInfo: websiteList,
  };
};

// ********** ********** **********

const setWebsiteStorageSize = (state, action) => {
  const updatedWebsiteList = state.websiteListInfo.map((website) => {
    if (website.id === action.data.WebsiteId) {
      return { ...website, websiteStrageSize: action.data.StorageSize };
    } else {
      return website;
    }
  });

  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
    websiteListInfo: [...updatedWebsiteList],
  };
};

export default websiteReducer;
