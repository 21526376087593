import galleryIcon from "../../../assets/Images/servicesIcons/galleryIcon.svg";
import prayIcon from "../../../assets/Images/servicesIcons/prayIcon.svg";
import digitalCandleIcon from "../../../assets/Images/servicesIcons/digitalCandleIcon.svg";
import graveLocationIcon from "../../../assets/Images/servicesIcons/graveLocationIcon.svg";
import playlistIcon from "../../../assets/Images/servicesIcons/PlaylistIcon.svg";
import sharedMemorialIcon from "../../../assets/Images/servicesIcons/sharedMemorialIcon.svg";
import horizentalTimelineIcon from "../../../assets/Images/servicesIcons/horizentalTimelineIcon.svg";
import lifeStoryIcon from "../../../assets/Images/servicesIcons/lifeStoryIcon.svg";

export const SalesOurServicesComponent = ({ ...props }) => {
  return (
    <div className="services-container">
      {servicesDetails.map((service, index) => (
        <div key={index} className="service-card-container">
          <div className="icon-container">
            <img src={service.icon} />
          </div>
          <h2>{service.name}</h2>
          <p>{service.content}</p>
        </div>
      ))}
    </div>
  );
};

export default SalesOurServicesComponent;

const servicesDetails = [
  { name: "זיכרונות בתמונות", content: "אנו מציעים גלריית תמונות מיוחדת שמאפשרת לשמור ולהנציח את הזיכרונות של אהוביכם. תמונות הן החלון לנשמה ולחיים, וכאן אתם יכולים לשתף את הרגעים החשובים והמשמעותיים בחייו/ה", icon: galleryIcon },
  { name: "סיפור חייו/ה", content: "אנו מאפשרים לתעד ולספר על סיפור החיים הייחודי של יקירכם. תוכלו לספר על האדם שהיה, להנציחו ולספר את סיפורו ", icon: lifeStoryIcon },
  { name: "תפילה", content: "יצרנו איזור עם תפילה לעילוי נשמת יקירכם, שתוכלו להתפלל מכל מקום ובכל זמן", icon: prayIcon },
  { name: "נר לזכרו/ה", content: "אנו מציעים שירות הדלקת נר דיגיטלי לזכר יקירכם. נר זיכרון אינו רק סמל, אלא חוויה שמחברת אותנו לעבר ומזכירה לנו את הנפשות שהשאירו חותם על חיינו", icon: digitalCandleIcon },
  { name: "פרטי קבורה", content: "אנו מספקים מידע על מיקום הקבורה של יקירכם, כולל תמונות וניווט, כדי לאפשר למשתמשים למצוא ולבקר במקום הקבורה בקלות", icon: graveLocationIcon },
  { name: "פלייליסט שירים שאהב/ה", content: "מוזיקה היא שפה אוניברסלית שמחברת בין אנשים. אנו מזמינים אתכם ליצור פלייליסט של שירים אהובים על יקירכם", icon: playlistIcon },
  { name: "זיכרונות משותפים", content: "שירות 'זיכרונות משותפים' מאפשר לכל אדם לתרום פיסת זיכרון שמרכיבה יחדיו את סיפור חיי יקירכם", icon: sharedMemorialIcon },
  { name: "ציר זמן אינטרקטיבי", content: "אנו יוצרים ציר זמן אינטרקטיבי שמסביר ומציין את הנקודות המשמעותיות בחייו של יקירכם", icon: horizentalTimelineIcon },
];
