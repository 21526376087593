import React, { useEffect, useState } from "react";

export const CreateWebsiteHomeGalleryLevelComponent = ({
  handleInputChange,
  ...props
}) => {
  return (
    <div className="form_step_box">
      <div className="form_input_main_box">
        <div className="form_input_box">
          <label htmlFor="main_image_banner">תמונות עמוד הבית</label>
          <input
            type="file"
            name="homeGalleryImages"
            id="main_image_banner"
            required
            multiple
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateWebsiteHomeGalleryLevelComponent;
