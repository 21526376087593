import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import websiteActions from "../../../store/actions/website.action";
import FileVeiwerComponent from "../../FileVeiwerComponent";

export const DashboardUpdatedScreenComponent = ({
  websiteActions,
  selectedWebsiteUpdates,
  resetSelectedWebsiteUpdates,
  ...props
}) => {

  const handleApproveSharedMemorialClicked = (sharedMemrialId, approved )=>{
    const request = {
      id: sharedMemrialId,
      approved
    }
    websiteActions.approveSahredMemorial(request, selectedWebsiteUpdates.websiteId);
  }

  return (
    <div className="dashboard_main_update_box">
      <a
        href="#"
        className="backtodahboard"
        onClick={resetSelectedWebsiteUpdates}
      >
        חזרה לאיזור ניהול <i className="fa-solid fa-left"></i>
      </a>
      <div className="dashboard_main_update_box_heading">
        יש לך {selectedWebsiteUpdates.sharedMemorials.length} עידכונים
      </div>

      <div className="dashboard_main_update_box_card">
        {selectedWebsiteUpdates.sharedMemorials.map((sharedMemorial) => (
          <div className="dash_board_updated_card_box">
            <h3 className="dash_board_updated_card_box_heading">
              זיכרון חדש נוסף על ידי {sharedMemorial.fullName}
            </h3>
            <p>{sharedMemorial.content}</p>
            {sharedMemorial.sharedMemorialFile && (
              <div className="memorial-image-container">
                <FileVeiwerComponent
                  fileType={sharedMemorial.sharedMemorialFile.type}
                  url={sharedMemorial.sharedMemorialFile.url}
                />
              </div>
            )}
            <div className="dash_board_updated_btn_cta">
              <a onClick={()=>{handleApproveSharedMemorialClicked(sharedMemorial.id, true)}}>אשר</a>
              <a onClick={()=>{handleApproveSharedMemorialClicked(sharedMemorial.id, false)}}>דחה</a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};


const mapStateToProps = (state, ownProps) => ({
  websiteReducer: state.websiteReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    websiteActions: bindActionCreators(websiteActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardUpdatedScreenComponent);
