export const deceasedInfoErrorEnum = {
  // *** Deceased Info ***
  GeneralFailedAddOrUpdateDeceasedInfo: "GeneralFailedAddOrUpdateDeceasedInfo",
  FailedSaveDeceasedMainImage: "FailedSaveDeceasedMainImage",
  // *** Deceased Info ***
  FailedAddOrUpdateDeceasedStory: "FailedAddOrUpdateDeceasedStory",
  FailedSaveDeceasedStoryImage: "FailedSaveDeceasedStoryImage",
  // *** Deceased Files ***
  FailedSaveDeceasedFiles: "FailedSaveDeceasedFiles",
};
