import { paymentActionTypes } from "../actionTypes/paymentActionTypes";

const initialState = {
    stateInfo: {
      isLoading: false,
      isSuccess: false,
      isError: false,
      error: null,
    },
  };

  const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
      case paymentActionTypes.START_CREATE_PAYMENT_PAGE:
      case paymentActionTypes.START_GET_PAYMENT_TOKEN:
        return setStateLoading(state, action);
  
        case paymentActionTypes.CREATE_PAYMENT_PAGE_SUCCESS:
        case paymentActionTypes.GET_PAYMENT_TOKEN_SUCCESS:
        return setStateSuccess(state, action);
      
        case paymentActionTypes.CREATE_PAYMENT_PAGE_FAILURE:
        case paymentActionTypes.GET_PAYMENT_TOKEN_FAILURE:
        return setStateFailure(state, action);
      default:
        return state;
    }
  };
  
  const setStateLoading = (state, action) => {
    return {
      ...state,
      stateInfo: {
        isLoading: true,
        isSuccess: false,
        isError: false,
        error: null,
      },
    };
  };
  
  const setStateFailure = (state, action) => {
    return {
      ...state,
      stateInfo: {
        isLoading: false,
        isSuccess: false,
        isError: true,
        error: null,
      },
    };
  };
  
  const setStateSuccess = (state, action) => {
    return {
      ...state,
      stateInfo: {
        isLoading: false,
        isSuccess: true,
        isError: false,
        error: null,
      },
    };
  };

  export default paymentReducer;