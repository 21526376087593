import React from "react";

export const FileVeiwerComponent = ({ fileType, url, ...props }) => {
  const getFileElement = (type, url) => {
    switch (type) {
      case "image":
        return <img src={url} />;
      case "video":
        return (
          <video controls>
            <source src={url} />
          </video>
        );
      case "audio":
        return (
          <audio controls>
            <source src={url} />
          </audio>
        );
      default:
        return <div></div>;
        break;
    }
  };

  return getFileElement(fileType, url);
};

export default FileVeiwerComponent;
