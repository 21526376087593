import { contactActionTypes } from "../actionTypes/contactActionTypes";

const initialState = {
  stateInfo: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null,
  },
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case contactActionTypes.START_SUBMIT_CONTACT:
      return setStateLoading(state, action);
    case contactActionTypes.SUBMIT_CONTACT_SUCCESS:
      return setStateSuccess(state, action);
    case contactActionTypes.SUBMIT_CONTACT_FAILURE:
      return setStateFailure(state, action);

    default:
      return state;
  }
};

const setStateLoading = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: true,
      isSuccess: false,
      isError: false,
      error: null,
    },
  };
};

const setStateFailure = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: false,
      isError: true,
      error: null,
    },
  };
};

const setStateSuccess = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
  };
};

export default contactReducer;
